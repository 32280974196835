
import _ from "lodash";
import { computed, defineComponent, ref } from "vue";
export default defineComponent({
  props: {
    url: {
      type: String,
      default: "",
    },
    width: {
      type: Number,
      default: 1920,
    },
    height: {
      type: Number,
      default: 1080,
    },
    backVisible: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["update:visible"],
  setup(props, ctx) {
    const webUrl = ref(props.url);
    const webWidth = computed(() => props.width);
    const webHeight = computed(() => props.height);

    const backBtnVisible = computed(() => props.backVisible);

    const handleGoBackToLogin = _.debounce((e: Event) => {
      e.stopImmediatePropagation();
      ctx.emit("update:visible", false);
    }, 150);
    return {
      webUrl,
      webWidth,
      webHeight,
      backBtnVisible,
      handleGoBackToLogin,
    };
  },
});
