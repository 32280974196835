const LoginTypeMap = {
  ACCOUNT: "account",
  WE_CHAT: "weChat",
  DING_DING: "dingDing",
  ZDY: "zdy",
};
const AccountMode = {
  LOGIN: "login",
  ENV: "env",
  LOG: "log",
};

const Envs = [
  { label: "测试", value: "test" },
  { label: "预发", value: "pre" },
  { label: "正式", value: "prod" },
];

const LogTypes = [
  { label: "debug", value: "debug" },
  { label: "info", value: "info" },
  { label: "warm", value: "warm" },
  { label: "error", value: "error" },
];

const loginTypeNameMap: any = {
  account: "account",
  wechat: "weChat",
  dingtalk: "dingDing",
  zdy: "zdy",
};

const LoginTypes = [
  { type: "account", name: "账号密码" },
  { type: "weChat", name: "微信登录" },
  { type: "dingDing", name: "钉钉登录" },
  { type: "zdy", name: "学之路" },
];

export {
  LoginTypeMap,
  AccountMode,
  Envs,
  LogTypes,
  LoginTypes,
  loginTypeNameMap,
};
