import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-4f66857c")
const _hoisted_1 = { class: "container-attend" }
const _hoisted_2 = ["src"]
const _hoisted_3 = {
  key: 0,
  class: "try-await"
}
const _hoisted_4 = ["onClick"]
const _hoisted_5 = ["data-status"]
const _hoisted_6 = ["data-status", "src"]
const _hoisted_7 = ["data-status"]
const _hoisted_8 = {
  key: 0,
  class: "no-find-stu"
}
_popScopeId()

import StudentCard from "../base/StudentCard.vue";
import {
  ref,
  inject,
  watch,
  toRaw,
  onMounted,
} from "vue";
import { setStudentAttendanceRecords } from "@/services/classroomInteraction";
import { AttendanceStatusEnum, OrderValueEnum } from "../../config";
import { ElMessage } from "element-plus";
import { ClassroomInterationNS } from "../../types";
import logger from "@evideo/logger";
import _, { divide } from "lodash";

// 考勤记录信息

export default _defineComponent({
  emits: ["cancelAttendanceAction"],
  setup(__props, { expose, emit }) {

const attendanceList = inject<any>("attendanceData");
// 学生信息
const studentList: any = inject("studentList");

const lessonId: any = inject("lessonId");

const updateAttendanceData = inject<() => Promise<void>>("getAttendanceData");

// 通过indexValue控制下拉框的显示
let indexValue = ref(-1);

const listBody = ref();

const currentListHeight = ref();

// 用于渲染的列表
const list = ref<any>([]);

const selectAttendList = [
  {
    img: require("@/assets/img/classroomInteraction/studentAttend/1.png"),
    label: "出勤",
    status: AttendanceStatusEnum.ATTENDANCE,
  },
  {
    img: require("@/assets/img/classroomInteraction/studentAttend/0.png"),
    label: "缺勤",
    status: AttendanceStatusEnum.ABSENCE,
  },
  {
    img: require("@/assets/img/classroomInteraction/studentAttend/2.png"),
    label: "迟到",
    status: AttendanceStatusEnum.LATE,
  },
  {
    img: require("@/assets/img/classroomInteraction/studentAttend/3.png"),
    label: "请假",
    status: AttendanceStatusEnum.LEAVE,
  },
];

const searchContent = inject<any>("searchContent");
const orderFunc = inject<ClassroomInterationNS.SortListByOrderTypeFunc>(
  "sortListByOrderType"
);

// 生成随机头像
const getRandomAvatar = () => {
  return `stu_avatar_${Math.floor(Math.random() * (10 + 1)) + 1}.jpg`;
};

const getStatusImageByStatus = (status: number) => {
  return require(`@/assets/img/classroomInteraction/studentAttend/${status}.png`);
};

// 生成本地的考勤记录
const generateLocalAttandanceRecord = (
  values: {
    student_id: number;
    status: number;
  }[]
) => {
  let info: any[] = [];
  values.forEach((i) => {
    info.push({
      id: -Date.now(),
      status: i.status,
      student_id: i.student_id,
    });
  });
  attendanceList.value = [...attendanceList.value, ...info];
};

// 总的生成列表渲染方法
const generateList = (students: any[], attendances: any[], keyword = "") => {
  if (students.length === 0) {
    return [];
  }
  const attendanceMap = new Map<number, any>();
  // 聚类为Map，方便检索
  attendances.forEach((att: any) => {
    attendanceMap.set(att.student_id, att);
  });

  let array = students.map((stu: any) => {
    const record = attendanceMap.get(stu.id);
    if (record) {
      record.status_img = getStatusImageByStatus(record?.status);
    }
    return {
      name: stu.name,
      py_name: stu.py_name,
      id: stu.id,
      avatar: stu.avatar || getRandomAvatar(),
      attendance: record,
    };
  });

  let searchKey = keyword;
  if (searchContent.value !== "") {
    searchKey = searchContent.value;
  }
  if (searchKey && searchKey !== "") {
    array = array.filter(
      (i) =>
        i.py_name?.indexOf(searchKey) > -1 || i.name?.indexOf(searchKey) > -1
    );
  }

  // 排序
  // 排序模式处理
  if (orderFunc) {
    array = orderFunc(array, OrderValueEnum.FIRST_LETTER, false);
  }

  return array;
};

const handleSearch = (keyword: string) => {
  list.value = generateList(studentList.value, attendanceList.value, keyword);
};

onMounted(() => {
  list.value = generateList(studentList.value, attendanceList.value);
});

watch(
  () => studentList.value,
  (newVal) => {
    if (newVal) {
      list.value = generateList(newVal, toRaw(attendanceList.value));
      const params: any = {
        lesson_record_id: lessonId.value,
        student_attendance_status_list: [],
      };
      list.value.forEach((item: any) => {
        if (!toRaw(item).attendance) {
          // console.log("新添加没考勤", item);
          params.student_attendance_status_list.push({
            student_id: toRaw(item).id,
            status: 1,
          });
        }
      });
      if (params.student_attendance_status_list.length < 1) {
        return;
      }
      setStudentAttendanceRecords(params);
    }
  }
);

watch(
  () => attendanceList.value,
  (newVal) => {
    list.value = generateList(toRaw(studentList.value), newVal);
  },
  {
    immediate: true,
  }
);

watch(
  () => searchContent.value,
  (val) => {
    if (val === "") {
      list.value = generateList(studentList.value, attendanceList.value);
    }
  }
);

// 收起菜单
const shouqi = () => {
  indexValue.value = -1;
};
// 展示状态选择框
const showSelectlist = (index: number, event: MouseEvent) => {
  if (indexValue.value === index) {
    indexValue.value = -1;
  } else {
    indexValue.value = index;
    const y =
      (event.target as any).getBoundingClientRect().top +
      125 -
      listBody.value.getBoundingClientRect().top;
    if (y + 185 > listBody.value.clientHeight) {
      let s = listBody.value.scrollTop;
      setTimeout(() => {
        listBody.value.scrollTop = s + (y + 185 - listBody.value.clientHeight);
      }, 100);
    }
  }
  event.stopPropagation();
};

// 最终向服务器发送的数据
let finStuList = ref<{ student_id: number; status: number }[]>([]);

// 请求接口设置考勤信息
const handleSetAttendance = async (
  stuList: { student_id: number; status: number }[]
) => {
  if (stuList.length === 0) {
    return;
  }
  const stuItem = stuList[0];
  // console.log("stuItem", stuItem);

  // 是否已存在该学生
  let isExistStu = finStuList.value.find(
    (item) => item.student_id === stuItem.student_id
  );
  if (!isExistStu) {
    finStuList.value.push(stuItem);
  } else {
    finStuList.value.map((item) => {
      if (item.student_id == stuItem.student_id) {
        item.status = stuItem.status;
      }
    });
  }
  console.log("finStuList", finStuList);

  const keyMap = new Map();
  stuList.forEach((i) => {
    keyMap.set(i.student_id, i);
  });

  // let params = {
  //   lesson_record_id: lessonId.value,
  //   student_attendance_status_list: finStuList.value,
  // };

  // await setStudentAttendanceRecords(params);
  // 数据伪更新,不走云端,临时方案，后续会修改交互模式，走云端数据方案，保证数据更新的一致性
  generateLocalAttandanceRecord(stuList);
};

// 考勤状态事件委托 卡片点击事件
const handleChangeStuState = async (item: any, event: any) => {
  const statusNum = parseInt(event.target.getAttribute("data-status") || "-1");
  if (statusNum < 0) {
    return;
  }
  try {
    const stuList = [
      {
        student_id: item.id,
        status: statusNum,
      },
    ];
    await handleSetAttendance(stuList);
    shouqi();
  } catch (err) {
    logger.error("【attandance】: 设置考勤状态失败", err);
    ElMessage.error("设置考勤状态失败，请重试");
  }
};

async function changeAllAttend(flag: boolean) {
  let status =
    flag == true
      ? AttendanceStatusEnum.ATTENDANCE
      : AttendanceStatusEnum.ABSENCE;

  const stus = list.value.map((i: { id: number }) => ({
    student_id: i.id,
    status,
  }));
  // console.log(stus);
  finStuList.value = stus;
  // 数据伪更新,不走云端,临时方案，后续会修改交互模式，走云端数据方案，保证数据更新的一致性
  generateLocalAttandanceRecord(finStuList.value);
  // try {
  //   await handleSetAttendance(finStuList.value);
  // } catch (err) {
  //   ElMessage.error("设置错误，请重试");
  // }
}

// 保存按钮 向服务器发送的数据
const handleSubmitAllAttendance = async () => {
  if (finStuList.value.length == 0) {
    ElMessage.warning({
      message: "未设置学生考勤状态",
    });
    return;
  }
  let params = {
    lesson_record_id: lessonId.value,
    student_attendance_status_list: finStuList.value,
  };
  const res: any = await setStudentAttendanceRecords(params);
  if (res.message == "success") {
    console.log("考勤设置成功");
    finStuList.value.length = 0;
    ElMessage.success({
      message: "保存成功",
    });
    emit("cancelAttendanceAction");
  }
};

expose({
  changeAllAttend,
  onSearch: handleSearch,
  submitAllAttendance: handleSubmitAllAttendance,
});



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    ref: listBody,
    class: "attandance-list",
    onClick: shouqi
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(list.value, (item, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: item.id,
          class: "sticked"
        }, [
          _createVNode(StudentCard, {
            onClick: ($event: any) => (showSelectlist(index, $event)),
            info: item
          }, {
            "foot-subscript": _withCtx(() => [
              _withDirectives(_createElementVNode("img", {
                src: item?.attendance?.status_img
              }, null, 8, _hoisted_2), [
                [_vShow, item?.attendance?.status !== undefined]
              ])
            ]),
            _: 2
          }, 1032, ["onClick", "info"]),
          (index == _unref(indexValue))
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("div", {
                  class: "select-attend",
                  onClick: ($event) => handleChangeStuState(item, $event)
                }, [
                  (_openBlock(), _createElementBlock(_Fragment, null, _renderList(selectAttendList, (list) => {
                    return _createElementVNode("div", {
                      "data-status": list.status,
                      key: list.label,
                      class: _normalizeClass(item?.attendance?.status == list.status ? 'active' : '')
                    }, [
                      _createElementVNode("img", {
                        "data-status": list.status,
                        src: list.img,
                        alt: ""
                      }, null, 8, _hoisted_6),
                      _createElementVNode("span", {
                        "data-status": list.status
                      }, _toDisplayString(list.label), 9, _hoisted_7)
                    ], 10, _hoisted_5)
                  }), 64))
                ], 8, _hoisted_4)
              ]))
            : _createCommentVNode("", true)
        ]))
      }), 128)),
      (list.value.length === 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_8, "未找到相关学生"))
        : _createCommentVNode("", true)
    ])
  ], 512))
}
}

})