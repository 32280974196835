import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-0ec280e8")
const _hoisted_1 = { class: "volume-slider-body" }
const _hoisted_2 = { class: "volume-slider" }
const _hoisted_3 = { class: "volume-slider-wrapper" }
const _hoisted_4 = { class: "volume-slider-text" }
_popScopeId()

import { computed, onMounted, onUnmounted, ref, watch } from "vue";
interface VolumeNoTextSliderProps {
  value: number;
  scale: number;
}


export default _defineComponent({
  props: {
    value: { type: Number, required: true, default: 1 },
    scale: { type: Number, required: true, default: 2 }
  } as unknown as undefined,
  emits: ["onChange"] as unknown as undefined,
  setup(__props: {
  value: number;
  scale: number;
}, { emit }: { emit: ({
  (event: "onChange", val: number): void;
}), expose: any, slots: any, attrs: any }) {

const props = __props




const isDrop = ref<boolean>(false);
const volume = ref(props.value);

const scale = computed(() => props.scale);

watch(
  () => props.value,
  (val) => {
    volume.value = val;
  }
);

const sliderLineRef = ref<any>();

const sliderLineWidth = computed(() => sliderLineRef.value.clientWidth);

const sliderLineOffset = computed(
  () => sliderLineRef.value?.getBoundingClientRect()?.left
);

const updateVolume = (newVal: number) => {
  emit("onChange", newVal);
};

const handleClickSlider = (e: MouseEvent) => {
  const offset = e.offsetX;
  const width = sliderLineRef.value.clientWidth;
  if (width === undefined || width === 0) {
    return;
  }

  let per = Math.ceil((offset * 100) / width);
  if (per < 0) {
    per = 0;
  }
  if (per > 100) {
    per = 100;
  }
  const num = parseFloat(((per / 100) * scale.value).toFixed(1));
  volume.value = num;
  updateVolume(num);
};

// 开始滑动
const handleStartDrop = () => {
  isDrop.value = true;
};

// touch滑动的事件，要对事件做兼容
const handleTouchDrop = (e: any) => {
  if (e.targetTouches && e.targetTouches[0]) {
    handleDrop(e.targetTouches[0]);
  }
};

// 滑动过程中
const handleDrop = (e: any) => {
  if (isDrop.value) {
    const offset = e.pageX - sliderLineOffset.value;
    const width = sliderLineWidth.value;
    if (width === undefined || width === 0) {
      return;
    }
    let per = Math.ceil((offset * 100) / width);
    if (per < 0) {
      per = 0;
    }
    if (per > 100) {
      per = 100;
    }
    const num = parseFloat(((per / 100) * scale.value).toFixed(1));
    volume.value = num;
    updateVolume(num);
  }
};

// 滑动结束
const handleDropEnd = () => {
  if (isDrop.value) {
    isDrop.value = false;
    updateVolume(volume.value);
  }
};

onMounted(() => {
  // 注册滑动监听的事件
  document.body.addEventListener("mouseup", handleDropEnd);
  document.body.addEventListener("mousemove", handleDrop);
  document.body.addEventListener("touchend", handleDropEnd);
  document.body.addEventListener("touchmove", handleTouchDrop);
});

onUnmounted(() => {
  document.body.removeEventListener("mouseup", handleDropEnd);
  document.body.removeEventListener("mousemove", handleDrop);
  document.body.removeEventListener("touchend", handleDropEnd);
  document.body.removeEventListener("touchmove", handleTouchDrop);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", {
          class: "volume-slider-line-wrapper",
          onClick: handleClickSlider
        }, [
          _createElementVNode("div", {
            ref: sliderLineRef,
            style: _normalizeStyle({
              background: `linear-gradient(
              to right,
              #71C212 0%,
              #71C212 ${Math.ceil((volume.value / _unref(scale)) * 100)}%,
              #E3A14C ${Math.ceil((volume.value / _unref(scale)) * 100)}%,
              #E3A14C 100%
            )`,
            }),
            class: "volume-slider-line"
          }, null, 4)
        ]),
        _createElementVNode("div", {
          class: "volume-slider-btn",
          style: _normalizeStyle({ left: Math.ceil((volume.value / _unref(scale)) * 100) + '%' }),
          onMousedown: handleStartDrop,
          onTouchstart: handleStartDrop
        }, null, 36)
      ])
    ]),
    _createElementVNode("div", _hoisted_4, _toDisplayString(volume.value.toFixed(1)), 1)
  ]))
}
}

})