
import { queryClass, queryClassStudent } from "@/services";
import { grades } from "./config";
import store, { MutationNames } from "@/store/index";
import _ from "lodash";
import logger from "@evideo/logger";

const typeIncludeAllClass = 1; // 请求全部的班级
const papawTimeInterval = 500; // 随机点名时，泡泡之间的时间间隔
const stopDelay = 500; // 结束 dialog 出现的延迟
const maxPapawCount = 100; // 允许记录的最大泡泡数
const papawClearTime = 8000;
let papawId = 1;

export default {
  name: "DrawRandow",
  data() {
    return {
      bgvVisible: false,
      grades: grades,
      papawList: [],
      gradeAndClassList: [], // 后端返回的结果
      classList: [], // 当前年级的班级列表
      studentList: [], // 当前班级的学生姓名列表
      loading: false, // 加载后端数据时的 loading 状态
      startVisible: true, // 开始 dialog 的可见性
      endVisible: false, // 结束 dialog 的可见性
      currentClass: undefined, // 当前班级
      currentGrade: undefined, // 当前年级
      selectCount: 1, // 点名结果的人数
      selectedStudents: [], // 当前选中的学生
      interval: "",
    };
  },
  methods: {
    // 机顶盒关闭
    handleStbClose() {
      try {
        window.jsInterface.jsCallAndroid2Finish();
      } catch {
        this.$alert("触发 Bridge 失败");
      }
    },

    // 随机点名过程
    processing() {
      const positionIndex = _.random(1, 4);
      let positon = {};

      switch (positionIndex) {
        case 1:
          positon = {
            top: _.random(0, 800),
            left: _.random(385, 790),
          };
          break;
        case 2:
          positon = {
            top: _.random(0, 800),
            left: _.random(1130, 1535),
          };
          break;
        case 3:
          positon = {
            top: _.random(0, 800),
            left: _.random(385, 790),
          };
          break;
        case 4:
          positon = {
            top: _.random(0, 800),
            left: _.random(1130, 1535),
          };
          break;
      }

      const papawItem = {
        id: papawId++,
        student: this.studentList[_.random(0, this.studentList.length - 1)],
        theme: _.random(1, 5),
        ...positon,
      };

      if (this.papawList.length > maxPapawCount) {
        setTimeout(() => {
          this.papawList.shift();
        }, papawClearTime);
      }

      logger.info("papawItem", papawItem);
      this.papawList.push(papawItem);
    },

    // 开始随机点名
    handleBegin() {
      if (this.studentList.length === 0) {
        this.$message("当前班级暂无学生");
        return;
      }
      this.startVisible = false;

      this.interval = setInterval(async () => {
        this.processing();
      }, papawTimeInterval);
      logger.info("interval", this.interval);
      store.commit(MutationNames.CHANGE_RANDOMSETINTERVALID, this.interval);
    },

    // 关闭点名过程的 bgm 和 bgv
    stopBgmAndBgv() {
      const changeStep = 0.1;
      const stepInterval = stopDelay / 10;

      // const initVolume = this.$refs.bgm.volume;
      // let volume = initVolume;
      // const t = setInterval(() => {
      //   volume -= changeStep;
      //   if (volume > 0) {
      //     this.$refs.bgm.volume = volume;
      //   } else {
      //     clearInterval(t);
      //     this.$refs.bgm.pause();
      //     this.$refs.bgm.volume = initVolume; // 重置回初始音量
      //     this.$refs.bgm.currentTime = 0; // 重置时间，让每次点名都从头开始播放 bgm

      //     this.$refs.bgv.pause();
      //   }
      // }, stepInterval);
    },

    // 停止随机点名
    handleStop() {
      // 开始和结束状态中点击页面空白处时不做操作
      if (this.startVisible || this.endVisible) {
        return;
      }
      clearInterval(this.interval);
      this.stopBgmAndBgv();
      setTimeout(() => {
        this.endVisible = true;
        this.selectedStudents = this.randomSelect();
        this.$refs.selectEnd.play();
      }, stopDelay);
    },

    // 随机选择指定数量的学生
    randomSelect() {
      const { selectCount, studentList } = this;
      // 选择班内全部人数时，直接返回所有学生的列表
      if (selectCount >= studentList.length) {
        return studentList;
      }

      // 根据泡泡出现的先后来选择
      const latestStudents = this.papawList
        .map((papaw) => papaw.student)
        .reverse(); // reverse 一下，后出现的在前
      const students = Array.from(new Set(latestStudents));
      const selected = students.splice(0, this.selectCount);
      // 数量足够，直接返回
      if (students.length >= this.selectCount) {
        return selected;
      }
      // 数量不够，再单独选
      const restStudents = studentList.filter(
        (item) => !selected.includes(item)
      );
      for (let i = selected.length; i < selectCount; i++) {
        const random = _.random(0, students.length); // 向下取整
        const item = restStudents.splice(random, 1)[0]; // 取值，以保证不会随到相同的人
        selected.push(item); // 添加
      }

      return selected;
    },

    // 重新开始，回到选择窗口
    handleRestart() {
      this.startVisible = true;
      this.endVisible = false;
    },

    // 获取学生列表
    getClassStudent(classId) {
      logger.info("getClassId", classId);
      this.loading = true;
      queryClassStudent({ school_class_id: classId })
        .then((data) => {
          this.studentList = data.map(({ name }) => name);
          // 个人版随机点名人数调整
          if (store.state.teacherBenefits && store.state.personalStudentNum) {
            // this.studentList = [];
            this.studentList = new Array(store.state.personalStudentNum)
              .fill(null)
              .map((i, idx) => `${idx + 1}号`);
          }
          // 根据获取到的班级人数来重置 selectCount 默认值
          this.selectCount = data.length === 0 ? 0 : 1;
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    // 重置班级信息
    resetClass(classId) {
      this.currentClass = classId;
      this.getClassStudent(classId);
    },

    // 切换年级
    handleGradeChange(grade) {
      this.classList = grade.class;
      this.currentGrade = grade.grade;
      // 重置班级
      this.resetClass(grade.class[0].school_class_id);
    },

    // 切换班级
    handleClassChange(classId) {
      this.resetClass(classId);
    },

    // 切换选取人数
    handleCountChange(count) {
      // 选取人数不能超过8人
      const newCount = this.selectCount + count;

      // 选择的人数不能小于 1，且不能大于班级总人数
      if (newCount < 1 || newCount > this.studentList.length || newCount > 8) {
        return;
      }

      this.selectCount = newCount;
    },
  },
  mounted() {
    // 从路由参数获取文件路径
    // 本地开发时请打开：http://localhost:8080/#/draw/random?bgv=%2Fresource%2Frandom-draw.mp4&bgm=%2Fresource%2Fbgm.mp3

    this.bgvVisible = true;
    this.loading = true;
    const classId = store.state.schoolClassId;
    logger.info("classId", classId);
    this.getClassStudent(classId);
    // queryClass({ type: typeIncludeAllClass })
    //   .then((data) => {
    //     this.gradeAndClassList = data;
    //     if (data[0]) {
    //       this.classList = data[0].class;
    //       this.currentGrade = data[0].grade;
    //       this.currentClass = data[0].class[0].school_class_id;
    //       this.getClassStudent(data[0].class[0].school_class_id);
    //     }
    //   })
    //   .catch((error) => {
    //     console.error(error);
    //   })
    //   .finally(() => {
    //     this.loading = false;
    //   });
  },
  beforeUnmount() {
    clearInterval(this.interval);
  },
};
