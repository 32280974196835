import { MutationTree } from "vuex";

const MutationNames = {
  CHANGE_TOKEN: "changeToken",
  CHANGE_NICKNAME: "changeNickName",
  CHANGE_SCHOOLNAME: "changeSchoolName",
  CHANGE_FIRSTLOADPPT: "changeFirstLoadPPT",
  CHANGE_RELOADTEACHMATER: "changeReloadTeachMater",
  CHANGE_ANDROIDUSERTYPE: "changeAndroidUserType",
  CHANGE_CARRIER_APPLICATION_REGISTED_EVENTS:
    "changeCarrierApplicationRegistedEvents",
  CHANGE_GRADENUMBER: "changeGradeNumber",
  CHANGE_CLASSNUMBER: "changeClassNumber",
  CHANGE_FIRSTLOADHOME: "changeFirstLoadHome",
  CHANGE_SCHOOLCLASSID: "changeSchoolClassId",
  CHANGE_GRADENAME: "changeGradeName",
  CHANGE_CLASSNAME: "changeClassName",
  CHANGE_MAC: "changeMac",
  CHANGE_VERSION: "changeVersion",
  CHANGE_HARDWAREMODEL: "changeHardwareModel",
  CHANGE_RANDOMSETINTERVALID: "changeRandomSetIntervalId",
  CHANGE_ONLINESTATE: "changeOnlineState",
  CHANGE_ANDROIDUSERIDAES: "changeAndroidUserIdAES",
  CHANGE_PCUSERIDAES: "changePcUserIdAES",
  CHANGE_PCREQUESTEMPTYQRCODEURL: "changePcRequestEmptyQrCodeUrl",
  CHANGE_LESSONSTART: "changeLessonStart",
  CHANGE_LESSONRECORDID: "changeLessonRecordId",
  CHANGE_LESSONCLASSID: "changeLessonClassId",
  CHANGE_NETWORKSTATE: "changeNetWorkState",
  CHANGE_DEVICETOKEN: "changeDeviceToken",
  CHANGE_MONITORCAMERAIP: "changeMonitorCameraIp",
  CHANGE_SCREENSHOTTIMER: "changeScreenshotTimer",
  CHANGE_SCHOOL_ID: "changeSchoolId",
  CHANGE_SCHOOL_LOGO: "changeSchoolLogo",
  CHANGE_TEACHER_ID: "changeTeacherId",
  CHANGE_MINIMIZEBTNSTATE: "changeMinimizeBtnState",
  CHANGE_MINIMIZEURL: "changeMinimizeUrl",
  CHANGE_MINIMIZEPPTINFO: "changeMinimizePPTInfo",
  CHANGE_PPTTREEID: "changePptTreeId",
  CHANGE_MORERESOURCEDATA: "changeMoreResourceData",
  CHANGE_MORERESOURCECLICKDATA: "changeMoreResourceClickData",
  CHANGE_MOREPPTINFO: "changeMorePPTInfo",
  CHANGE_MOREPPTSHOW: "changeMorePptShow",
  CHANGE_ISML: "changeIsML",
  CHANGE_MINIMIZEMODULENAME: "changeMinimizeModuleName",
  CHANGE_MORERESOURCETABTYPE: "changeMoreResourceTabType",
  CHANGE_MORERESOURCETABPAGE: "changeMoreResourceTabPage",
  CHANGE_DIARYTYPELIST: "changeDiaryTypeList",
  CHANGE_LOGINBTNTXT: "changeLoginBtnTxt",
  CHANGE_LOCALRESOURCEPORT: "changeLocalResourcePort",
  CHANGE_LOCALRESOURCESTATE: "changeLocalResourceState",
  CHANGE_MODALCACHEERRSTATE: "changeModalCacheErrState",
  CHANGE_TEACHMEMORY: "changeTeachMemory",
  CHANGE_ISLOGINBYDINGTALK: "changeIsLoginByDingtalk",
  CHANGE_DINGTALKLOGINSTATE: "changeDingtalkLoginState",
  CHANGE_ISLOGIN: "changeIsLogin",
  CHANGE_VOICEDELAYTIME: "changeVoiceDelayTime",
  STORE_SUBJECTDATA: "storeSubjectData",
  CHANGE_LOGINTYPE: "changeLoginType",
  CHANGE_EVALUATERECORD: "changeEvaluateRecord",
  CHANGE_DEVICE_CONTROL_PORT: "changeDeviceControlPort",
  CHANGE_TEACHER_BENEFITS: "changeTeacherBenefits",
  CHANGE_PERSONAL_STUDENT_NUM: "changePersonalStudentNum",
  CHANGE_TEACHER_INFO: "changeTeacherInfo",
  CHANGE_QUERY_MY_MELODY_INFO: "changeQueryMyMelodyInfo",
  CHANGE_QUERY_MELODY_INFO: "changeQueryMelodyInfo",
};

const mutations: MutationTree<State> = {
  changeToken(state, token) {
    localStorage.setItem("token", token);
    state.token = token;
  },
  changeFirstLoadPPT(state, LoadPptType) {
    state.firstLoadPPT = LoadPptType;
  },
  changeNickName(state, nickName) {
    state.nickName = nickName;
  },
  changeReloadTeachMater(state, ReloadTeachMaterType) {
    state.reloadTeachMater = ReloadTeachMaterType;
  },
  changeSchoolName(state, schoolName) {
    state.schoolName = schoolName;
  },
  changeAndroidUserType(state, androidUserType) {
    state.androidUserType = androidUserType;
  },
  changeCarrierApplicationRegistedEvents(state, registedEvents) {
    state.registedEvents = registedEvents;
  },
  changeGradeNumber(state, gradeNumber) {
    state.gradeNumber = gradeNumber;
  },
  changeClassNumber(state, classNumber) {
    state.classNumber = classNumber;
  },
  changeGradeName(state, gradeName) {
    state.gradeName = gradeName;
  },
  changeClassName(state, className) {
    state.className = className;
  },
  changeFirstLoadHome(state, firstLoadHome) {
    state.firstLoadHome = firstLoadHome;
  },
  changeSchoolClassId(state, schoolClassId) {
    state.schoolClassId = schoolClassId;
  },
  changeMac(state, mac) {
    state.mac = mac;
  },
  changeVersion(state, version) {
    state.version = version;
  },
  changeHardwareModel(state, hardwareModel) {
    state.hardwareModel = hardwareModel;
  },
  changeRandomSetIntervalId(state, randomSetIntervalId) {
    state.randomSetIntervalId = randomSetIntervalId;
  },
  changeOnlineState(state, onlineState) {
    state.onlineState = onlineState;
  },
  changeAndroidUserIdAES(state, androidUserIdAES) {
    state.androidUserIdAES = androidUserIdAES;
  },
  changePcUserIdAES(state, pcUserIdAES) {
    state.pcUserIdAES = pcUserIdAES;
  },
  changeLessonStart(state, lessonStart) {
    state.lessonStart = lessonStart;
  },
  changeLessonRecordId(state, lessonRecordId) {
    state.lessonRecordId = lessonRecordId;
  },
  changeLessonClassId(state, lessonClassId) {
    state.lessonClassId = lessonClassId;
  },
  changePcRequestEmptyQrCodeUrl(state, pcRequestEmptyQrCodeUrl) {
    state.pcRequestEmptyQrCodeUrl = pcRequestEmptyQrCodeUrl;
  },
  changeNetWorkState(state, netWorkState) {
    state.netWorkState = netWorkState;
  },
  changeDeviceToken(state, deviceToken) {
    state.deviceToken = deviceToken;
  },
  changeMonitorCameraIp(state, monitorCameraIp) {
    state.monitorCameraIp = monitorCameraIp;
  },
  changeScreenshotTimer(state, screenshotTimer) {
    state.screenshotTimer = screenshotTimer;
  },
  changeSchoolId(state, schoolId) {
    state.schoolId = schoolId;
  },
  changeSchoolLogo(state, schoolLogo) {
    state.schoolLogo = schoolLogo;
  },
  changeTeacherId(state, teacherId) {
    state.teacherId = teacherId;
  },
  changeMinimizeBtnState(state, btnState) {
    state.minimizeBtnState = btnState;
  },
  changeMinimizeUrl(state, url) {
    state.minimizeUrl = url;
  },
  changeMinimizePPTInfo(state, info) {
    state.minimizePPTInfo = info;
  },
  changePptTreeId(state, info) {
    state.pptTreeId = info;
  },
  changeMoreResourceData(state, data) {
    state.moreResourceData = data;
  },
  changeMoreResourceClickData(state, data) {
    state.moreResourceClickData = data;
  },
  changeMorePPTInfo(state, info) {
    state.morePPTInfo = info;
  },
  changeMorePptShow(state, data) {
    state.morePptShow = data;
  },
  changeIsML(state, isMLState) {
    state.isML = isMLState;
  },
  changeMinimizeModuleName(state, minimizeModuleName) {
    state.minimizeModuleName = minimizeModuleName;
  },
  changeMoreResourceTabType(state, type) {
    state.moreResourceTabType = type;
  },
  changeMoreResourceTabPage(state, Page) {
    state.moreResourceTabPage = Page;
  },
  changeDiaryTypeList(state, diaryTypeList) {
    state.diaryTypeList = diaryTypeList;
  },
  changeLoginBtnTxt(state, loginBtnTxt) {
    state.loginBtnTxt = loginBtnTxt;
  },
  changeLocalResourcePort(state, portNum) {
    state.localResourcePort = portNum;
  },
  changeLocalResourceState(state, localResourceState) {
    state.localResourceState = localResourceState;
  },
  changeModalCacheErrState(state, modalCacheErrState) {
    state.modalCacheErrState = modalCacheErrState;
  },
  changeTeachMemory(state, Memory) {
    state.teachMemory = Memory;
  },
  changeIsLoginByDingtalk(state, value) {
    state.isLoginByDingtalk = value;
  },
  changeDingtalkLoginState(state, value) {
    state.dingtalkLoginState = value;
  },
  changeIsLogin(state, value) {
    state.isLogin = value;
  },
  changeVoiceDelayTime(state, voiceDelayTime) {
    state.voiceDelayTime = voiceDelayTime;
  },
  storeSubjectData(state, subjectData) {
    state.subjectData = subjectData;
  },
  changeLoginType(state, value) {
    state.loginType = value;
  },
  changeEvaluateRecord(state, value) {
    state.evaluateRecord = value;
  },
  changeDeviceControlPort(state, value: number) {
    state.deviceControlPort = value;
  },
  changeTeacherBenefits(state, value) {
    state.teacherBenefits = value;
  },
  changePersonalStudentNum(state, value) {
    localStorage.setItem("personalStudentNum", value);
    state.personalStudentNum = value;
  },
  changeTeacherInfo(state, value) {
    state.teacherInfo = value;
  },
  changeQueryMyMelodyInfo(state, value) {
    state.queryMyMelodyInfo = value;
  },
  changeQueryMelodyInfo(state, value) {
    state.queryMelodyInfo = value;
  },
};

export { MutationNames };
export default mutations;
