import { request } from "@/utils/request";
import servers from "@/config/servers";

export async function getSupportLoginSchools(params?: any) {
  return request(
    "GET",
    "/dingtalk/supportLoginSchools",
    servers.common,
    params
  );
}

export async function getSchoolOAuthLoginConfig(schoolId: number) {
  return request(
    "GET",
    `/dingtalk/getSchoolOAuthLoginConfig/${schoolId}`,
    servers.common
  );
}

export const createTeacherTokenByAuthCode = (params: any) => {
  const { schoolId, ...param } = params;
  return request(
    "POST",
    `/dingtalk/createTeacherTokenByAuthCode/${schoolId}`,
    servers.common,
    param
  );
};
