
import _ from "lodash";
import { defineComponent, onMounted, ref, inject, watch } from "vue";
import { useStore } from "vuex";
import { ElMessage } from "element-plus";
import { MutationNames } from "@/store";
import { teachLogin } from "@/services";
import { clientReload } from "@/services/localResource";
import logger from "@evideo/logger";
import useLogin from "@/hooks/useLoginNextNext";

import { AccountMode, Envs, LogTypes } from "../dataMap";

export default defineComponent({
  props: {
    handleLoginIn: Function,
  },
  emits: ["update:disable"],
  setup(props, ctx) {
    const store = useStore();
    const currentMode = ref(AccountMode.LOGIN);
    const currentEnv = ref<any>();
    const currentLog = ref<any>();

    const initEnv: any = inject("envState");
    const initLogLevel: any = inject("diaryLevel");

    const account = ref("");
    const password = ref("");

    const LoadingTextMap = {
      NO_LOAD: "登 录",
      LOADING: "登录中",
    };
    const isLoading = ref(false);
    const btnLoginText = ref(LoadingTextMap.NO_LOAD);

    const { getTeacherMe } = useLogin();

    const login = _.debounce(
      () => {
        if (account.value === "#MHMYJX@" && !password.value) {
          initEnvLog();
          currentMode.value = AccountMode.ENV;
          return;
        }
        if (account.value === "#LOG@" && !password.value) {
          initEnvLog();
          currentMode.value = AccountMode.LOG;
          return;
        }
        isLoading.value = true;
        btnLoginText.value = LoadingTextMap.LOADING;
        teachLogin({
          account: account.value,
          password: password.value,
        })
          .then(async (data: any) => {
            logger.info("登录的data==>", data);
            const Token = data.access_token;
            // 检查权限是否过期
            if (props?.handleLoginIn) {
              props.handleLoginIn(Token).finally(() => {
                isLoading.value = false;
                btnLoginText.value = LoadingTextMap.NO_LOAD;
              });
            }
          })
          .catch(() => {
            isLoading.value = false;
            btnLoginText.value = LoadingTextMap.NO_LOAD;
          });
      },
      200,
      { leading: true, trailing: false }
    );

    const handleChangeAppEnv = _.debounce(
      () => {
        logger.info("change env to", currentEnv.value?.value);
        if (window.require) {
          const { ipcRenderer } = window.require("electron");
          ipcRenderer.sendToHost("nodeEnv", currentEnv.value); // 向原生发送升级信息
          ElMessage({
            offset: 200,
            message:
              "已切换到" +
              currentEnv.value +
              "环境。若需要使用缓存功能请重新启动",
            center: true,
          });
          // 通知缓存服务重启
          clientReload();
        }
        currentMode.value = AccountMode.LOGIN;
        account.value = "";
        password.value = "";
        return;
      },
      200,
      { leading: true, trailing: false }
    );

    const handleChangeDiaryLevel = _.debounce(
      () => {
        if (window.require) {
          const { ipcRenderer } = window.require("electron");
          ipcRenderer.sendToHost("diaryType", currentLog.value); // 向原生发送要切换的日志类型信息
          (window as any).webviewLoggerLevel = currentLog.value;
          ElMessage({
            offset: 200,
            message: "日志等级切换到" + currentLog.value,
            center: true,
          });
        }
        store.commit(MutationNames.CHANGE_DIARYTYPELIST, currentLog.value); // 存入store方便写入调试日志时判断类型
        currentMode.value = AccountMode.LOGIN;
        account.value = "";
        password.value = "";
      },
      200,
      { leading: true, trailing: false }
    );

    const initEnvLog = () => {
      currentEnv.value = initEnv?.value || "prod";
      currentLog.value = initLogLevel?.value || "info";
    };
    watch(
      () => isLoading.value,
      () => {
        ctx.emit("update:disable", isLoading.value);
      }
    );
    onMounted(() => {
      currentMode.value = AccountMode.LOGIN;
    });
    return {
      AccountMode,
      currentMode,
      currentEnv,
      currentLog,
      account,
      password,
      isLoading,
      btnLoginText,
      login,
      Envs,
      LogTypes,
      handleChangeAppEnv,
      handleChangeDiaryLevel,
    };
  },
});
