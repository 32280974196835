export const ALL_STUDENT_ID = "ALL_STUDENT";
export const ALL_STUDENT_ICON = "stu_avatar_class.jpg";

export const OrderValueEnum = {
  FIRST_LETTER: 1,
  TOTAL_SCORE: 2,
  PRAISE_SCORE: 3,
  TO_BE_IMPROV: 4,
};

export const AttendanceStatusEnum = {
  // 缺勤
  ABSENCE: 0,
  // 道勤
  ATTENDANCE: 1,
  // 迟到
  LATE: 2,
  // 请假
  LEAVE: 3,
};

export const EventMap = {
  SELECT_ALL_IN_MULTI: "selectAllInMulti",
  MULTI_SELECT_STATUS_CHANGE: "multiSelectStatusChange",
};

export const ClassroomInterationEnum = {
  // 全局存储方式
  SET_INTO_STORE: "setIntoStore",
  // 全局获取数据
  GET_FROM_STORE: "getFromStore",
  // 分组计划id,对应已保存的分组中的id
  GROUP_PLAN_ID: "groupPlanId",
  // 多选功能，实现组件切换
  MULTI_SELECT_ACTION: "multiSelectAction",
  // 随机选择
  RANDOM_SELECT_ACTION: "randomSelectAction",
  // 考勤选择
  ATTENDANCE_ACTION: "attendanceAction",
  // 当前学生排序
  ORDER_STUDENT: "orderStudent",
  // 当前小组排序
  ORDER_GROUP: "orderGroup",
  // 搜索框中的值
  SEARCH_CONTENT: "searchContent",
  // 点击搜索按钮后固定值
  SEARCH_SUBMIT_CONTENT: "searchSubmitContent",
  // 是否是小组模式
  IS_GROUP_MODE: "isGroupMode",
  // 学生/小组模式切换
  TOGGLE_MODE: "toggleMode",
  // 关闭随机选择功能
  CLOSE_RANDOM_SELECT_ACTION: "closeRandomSelectAction",
  // 分组信息
  GROUP_LIST: "groupList",
  // 所有学生信息
  STUDENT_LIST: "studentList",
  // 全班得分信息
  TOTAL_CLASS_SCORE: "totalClassScore",
  // 快速分组窗口显示
  CREATEGROUP_VISIBLE: "createGroupVisible",
  // 评价弹窗可见性
  APPRAISEMODAL_VISIBLE: "appraiseModalVisible",
  // 显示评价弹窗
  HANDLE_SHOW_APPRAISEMODAL: "handleShowAppraiseModal",
  // 关闭评价弹窗
  HANDLE_CLOSE_APPRAISEMODAL: "handleCloseAppraiseModal",
  // 上课的班级号
  SCHOOL_CLASSID: "schoolClassId",
  // 某堂课的学生数据
  GET_CLASSDATA: "getClassData",
  // 考勤数据
  STUDENT_ATTENDANCE_DATA: "studentAttendanceData",
  // 更新考勤数据
  GET_ATTENDANCE_DATA: "getAttendanceData",
  // 上课id记录
  LESSONID: "lessonId",
  // 所属学科
  CURSUBJECT: "curSubject",
  // 更新学生信息列表
  HANDLE_UPDATE_STUDENTLIST: "handleUpdateStudentList",
  // 具体分组详情
  CURRENT_GROUPINFO: "currentGroupInfo",
  // 小组详情
  GROUP_EVALUATE_DATA: "groupEvaluateData",
  // 更新学生和小组点评数据
  HANDLE_UPDATE_STUDENT_AND_GROUP_DATA: "handleUpdateStudentAndGroupData",
  // 更新学生和小组点评数据
  HANDLE_CREATE_GROUP: "handleCreateGroup",
  // 全局排序功能
  SORTLIST_BY_ORDERTYPE: "sortListByOrderType",
  // 考勤原始数据
  ATTENDANCE_DATA: "attendanceData",
  // 打在body元素上，让组件挂载到body
  CIBODY_REF: "CIBodyRef",
  // 获取小组数
  GROUP_LENTH: "groupLenth",
  // 改变全选勾的状态
  CHANGE_ISALL_SELECTED: "changeIsAllSelected",
  // 控制蒙层显示
  HANDLESHOWMASK: "handleShowMask",
};
