import { request } from "@/utils/request";
import servers from "@/config/servers";
import store, { MutationNames } from "@/store";
import moment from "moment";

// 获取班级学生列表
export async function getClassStudent(classId: number): Promise<any> {
  return request("GET", "/teacher/classStudents", servers.device, {
    school_class_id: classId,
  });
}

// 获取班级分组
export const getClassGroupPlans = (id: number): Promise<any> => {
  return request(
    "GET",
    `/teacher/schoolClassGroup/classGroupPlans`,
    servers.user,
    { school_class_id: id }
  );
};

// 获取分组详情
export const getGroupPlanDetail = (id: number): Promise<any> => {
  return request(
    "GET",
    `/teacher/schoolClassGroup/groupPlanDetail`,
    servers.user,
    { group_plan_id: id }
  );
};

// 获取评价规则
export const getEvaluationNorms = (params?: any): Promise<any> =>
  request(
    "GET",
    "/teacher/lessonEvaluate/getEvaluateNorms",
    servers.user,
    params
  );

// 创建分组
export const createGroupPlan = (params: any) => {
  return request(
    "POST",
    `/teacher/schoolClassGroup/createGroupPlan`,
    servers.user,
    params
  );
};
// 编辑分组
export const editGroupPlan = (params: any) => {
  return request(
    "POST",
    `/teacher/schoolClassGroup/updateGroupPlan`,
    servers.user,
    params
  );
};
// 获取上课考勤数据
export const getStudentAttendanceRecords = (
  lessonRecordId: number
): Promise<any> => {
  return request(
    "GET",
    `/teacher/lessonRecord/${lessonRecordId}/studentAttendanceRecords`,
    servers.user
  );
};
// 设置上课考勤
export const setStudentAttendanceRecords = (params: any) => {
  return request(
    "POST",
    `/teacher/lessonRecord/setStudentAttendanceRecords`,
    servers.user,
    params
  );
};

// 获取班级学生评价记录
export const getClassStudentEvaluationRecord = (id: number): Promise<any> =>
  request(
    "GET",
    "/teacher/lessonEvaluate/getLessonStudentEvaluateRecords",
    servers.user,
    { lesson_id: id }
  );
export const getStudentEvaluateRecordsByTime = (params: any): Promise<any> => {
  console.log("params", params);
  if (params.start_time) {
    params.start_time = moment(params.start_time).format("YYYY-MM-DD 00:00:00");
  }
  if (params.end_time) {
    params.end_time = moment(params.end_time).format("YYYY-MM-DD 23:59:59");
  }
  return request(
    "GET",
    "/teacher/lessonEvaluate/getStudentEvaluateRecordsByTime",
    servers.user,
    params
  );
};

export const addStudentEvaluateRecord = (params: any) => {
  store.commit(MutationNames.CHANGE_EVALUATERECORD, params);
  return request(
    "POST",
    "/teacher/lessonEvaluate/addStudentEvaluateRecord",
    servers.user,
    params
  );
};

// 增加小组评价
export const addGroupEvaluateRecord = (params: any): Promise<any> =>
  request(
    "POST",
    "/teacher/lessonEvaluate/addGroupEvaluateRecord",
    servers.user,
    params
  );
// 获取小组评价
export const getLessonGroupEvaluateRecords = (params: any): Promise<any> =>
  request(
    "GET",
    "/teacher/lessonEvaluate/getLessonGroupEvaluateRecords",
    servers.user,
    params
  );

// 修改小组积分规则
export const setGroupPlanScoreType = (params: any): Promise<any> =>
  request(
    "POST",
    "/teacher/schoolClassGroup/setGroupPlanScoreType",
    servers.user,
    params
  );

// 用于在课堂上切换小组计分方式时，由小组分值=全组学生分数之和改为小组、学生单独计分这种方式时调用，清空之前该方案下的所有小组计分数据。
export const clearGroupEvaluateNormScore = (params: any): Promise<any> =>
  request(
    "POST",
    "/teacher/lessonEvaluate/clearGroupEvaluateNormScore",
    servers.user,
    params
  );

// 1. 用于在课堂上切换小组计分方式时，由小组、学生单独计分这种方式改为小组分值=全组学生分数之和改为时调用，
// 2. 当小组的计分方式为小组分值=全组学生分数之和时，如果修改了该方案的分组数据，则需要调用该接口重新计算每个小组的分值
export const setGroupEvaluateNormScoreByStudentSum = (
  params: any
): Promise<any> =>
  request(
    "POST",
    "/teacher/lessonEvaluate/setGroupEvaluateNormScoreByStudentSum",
    servers.user,
    params
  );
