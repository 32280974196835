export const StateNames = {
  token: "token",
  firstLoadPPT: "firstLoadPPT",
  // android端进入教材页面重新获取页面数据
  reloadTeachMater: "reloadTeachMater",
  nickName: "nickName",
  androidUserType: "androidUserType",
  // 载体应用支持的调用事件
  registedEvents: [],
  gradeNumber: "gradeNumber",
  classNumber: "classNumber",
  gradeName: "gradeName",
  className: "className",
  firstLoadHome: "firstLoadHome",
  schoolClassId: "schoolClassId",
  mac: "mac",
  version: "version",
  hardwareModel: "hardwareModel",
  randomSetIntervalId: "randomSetIntervalId",
  onlineState: "onlineState",
  androidUserIdAES: "androidUserType",
  pcUserIdAES: "pcUserType",
  pcRequestEmptyQrCodeUrl: "pcRequestEmptyQrCodeUrl",
  // 上课状态
  lessonStart: "lessonStart",
  // 上课记录ID
  lessonRecordId: "lessonRecordId",
  // 上课班级ID
  lessonClassId: "lessonClassId",
  // 登录页面二维码请求结果
  netWorkState: "netWorkState",
  // 设备登录token
  deviceToken: "deviceToken",
  // 网络摄像头地址
  monitorCameraIp: "monitorCameraIp",
  // 截图定时器对象
  screenshotTimer: "screenshotTimer",
  // 最小化课件按钮状态
  minimizeBtnState: "minimizeBtnState",
  // 最小化路由地址
  minimizeUrl: "minimizeUrl",
  // 最小化ppt信息
  minimizePPTInfo: "minimizePPTInfo",
  // ppt树id
  pptTreeId: "pptTreeId",
  // 更多资源的节点数据
  moreResourceData: "moreResourceData",
  // 更多资源的点击事件的数据
  moreResourceClickData: "moreResourceClickData",
  // 更多资源的ppt信息
  morePPTInfo: "morePPTInfo",
  // 是否有打开更多的ppt
  morePptShow: "morePptShow",
  isML: "isML",
  // 最小化是从哪个模块返回的标志位
  minimizeModuleName: "minimizeModuleName",
  // 更多资源的tab类型
  moreResourceTabType: "moreResourceTabType",
  // 更多资源的tab页数
  moreResourceTabPage: "moreResourceTabPage",
  // 保存在日志文件中的类型
  diaryTypeList: "diaryTypeList",
  // 登录按钮上的字
  loginBtnTxt: "loginBtnTxt",
  // 本地缓存服务端口号
  localResourcePort: "localResourcePort",
  // 本地缓存服务是否可用
  localResourceState: "localResourceState",
  // 本地缓存服务不可用弹框是否显示
  modalCacheErrState: "modalCacheErrState",
  // 是否钉钉登录进入
  isLoginByDingtalk: "isLoginByDingtalk",
  dingtalkLoginState: "dingtalkLoginState",
  isLogin: "isLogin",
  voiceDelayTime: "voiceDelayTime",
  // 当前老师的课程信息
  subjectData: "subjectData",
  loginType: "loginType",
  evaluateRecord: "evaluateRecord",
  deviceControlPort: "deviceControlPort",
  teacherBenefits: "teacherBenefits",
  personalStudentNum: "personalStudentNum",
  teacherInfo: "teacherInfo",
  // 我的律动查询条件
  queryMyMelodyInfo: "queryMyMelodyInfo",
  // 律动广场查询条件
  queryMelodyInfo: "queryMelodyInfo",
};

export const state: State = {
  token: "",
  firstLoadPPT: true,
  reloadTeachMater: false,
  nickName: "",
  schoolName: "",
  androidUserType: "teacher",
  registedEvents: [],
  gradeNumber: "",
  classNumber: "",
  gradeName: "",
  className: "",
  firstLoadHome: true,
  schoolClassId: "",
  mac: "0:0:0:0",
  version: "1.0.0",
  hardwareModel: "",
  randomSetIntervalId: 0,
  onlineState: true,
  androidUserIdAES: "",
  pcUserIdAES: "",
  pcRequestEmptyQrCodeUrl: "",
  lessonStart: true,
  lessonRecordId: 0,
  lessonClassId: 0,
  netWorkState: false,
  deviceToken: "",
  monitorCameraIp: "",
  screenshotTimer: "",
  // 当前老师id
  teacherId: undefined,
  // 当前的school_id
  schoolId: undefined,
  schoolLogo: "",
  // 最小化按钮是否显示false｜不显示; /materialPpt｜教材，特色课堂ppt; /courseWarePpt | 课件广场ppt
  minimizeBtnState: "false",
  // 最小化路由地址
  minimizeUrl: "",
  // 最小化ppt信息
  minimizePPTInfo: "",
  // ppt树id
  pptTreeId: "",
  // 更多资源的节点数据
  moreResourceData: "",
  // 更多资源的点击事件的数据
  moreResourceClickData: "",
  // 更多资源的ppt信息
  morePPTInfo: "",
  // 是否有打开更多的ppt
  morePptShow: false,
  // 记录教学进度
  teachMemory: {
    period: [],
    nextPeriod: [],
    material: [],
    courseWare: [],
    courseWareNext: [],
  },
  isML: false,
  minimizeModuleName: "",
  moreResourceTabType: "platform",
  moreResourceTabPage: 1,
  diaryTypeList: [],
  loginBtnTxt: true,
  localResourcePort: 8099,
  localResourceState: false,
  modalCacheErrState: false,
  isLoginByDingtalk: false,
  dingtalkLoginState: null,
  isLogin: false,
  voiceDelayTime: 0,
  subjectData: [],
  loginType: "",
  evaluateRecord: null,
  deviceControlPort: undefined,
  teacherBenefits: null,
  personalStudentNum: localStorage.getItem("personalStudentNum") || 0,
  teacherInfo: null,
  queryMyMelodyInfo: "",
  queryMelodyInfo: "",
};

// try {
//   token = localStorage.getItem("token"); // userToken
// } catch {
//   token = null;
// }
// export default createStore({
//   token,
// });
