/**
 * ！由于钉钉登录的页面的‘message’在组件完成，并未销毁，结果导致回调回多次被调用，因此这边讲原有文件搬移到了这里,并增加了删除回调的操作
 * 搬移js地址：https://g.alicdn.com/dingding/h5-dingtalk-login/0.21.0/ddlogin.js
 * 官方文档地址：https://open.dingtalk.com/document/orgapp/tutorial-obtaining-user-personal-information#title-90z-pr3-4gj
 */

// ********************************************************************************
// DOM包裹容器相关参数
// ********************************************************************************
// 注意！width与height参数只用于设置二维码iframe元素的尺寸，并不会影响包裹容器尺寸。
// 包裹容器的尺寸与样式需要接入方自己使用css设置
interface IDTLoginFrameParams {
  id: string; // 必传，包裹容器元素ID，不带'#'
  width?: number; // 选传，二维码iframe元素宽度，最小280，默认300
  height?: number; // 选传，二维码iframe元素高度，最小280，默认300
}

// ********************************************************************************
// 统一登录参数
// ********************************************************************************
// 参数意义与“拼接链接发起登录授权”的接入方式完全相同（缺少部分参数）
// 增加了isPre参数来设定运行环境
interface IDTLoginLoginParams {
  redirect_uri: string; // 必传，注意url需要encode
  response_type: string; // 必传，值固定为code
  client_id: string; // 必传
  scope: string; // 必传，如果值为openid+corpid，则下面的org_type和corpId参数必传，否则无法成功登录
  prompt: string; // 必传，值为consent。
  state?: string; // 选传
  org_type?: string; // 选传，当scope值为openid+corpid时必传
  corpId?: string; // 选传，当scope值为openid+corpid时必传
  exclusiveLogin?: string; // 选传，如需生成专属组织专用二维码时，可指定为true，可以限制非组织帐号的扫码
  exclusiveCorpId?: string; // 选传，当exclusiveLogin为true时必传，指定专属组织的corpId
  isPre?: boolean; // 选传，是否使用预发环境，默认为false
}

// ********************************************************************************
// 登录成功后返回的登录结果
// ********************************************************************************
interface IDTLoginSuccess {
  redirectUrl: string; // 登录成功后的重定向地址，接入方可以直接使用该地址进行重定向
  authCode: string; // 登录成功后获取到的authCode，接入方可直接进行认证，无需跳转页面
  state?: string; // 登录成功后获取到的state
}

export default () => {
  const DTFrameLogin = (
    frameParams: IDTLoginFrameParams, // DOM包裹容器相关参数
    loginParams: IDTLoginLoginParams, // 统一登录参数
    successCbk: (result: IDTLoginSuccess) => void, // 登录成功后的回调函数
    errorCbk?: (errorMsg: string) => void // 登录失败后的回调函数
  ) => {
    const container =
      (frameParams.id && document.getElementById(frameParams.id)) || null;
    if (!container) {
      errorCbk && errorCbk("Element not found");
      return;
    }
    const iframe = document.createElement("iframe");
    if (
      loginParams.client_id &&
      loginParams.redirect_uri &&
      loginParams.response_type &&
      loginParams.scope &&
      container
    ) {
      container.innerHTML = "";
      container.appendChild(iframe);
    } else {
      errorCbk && errorCbk("Missing parameters");
      return;
    }
    if (iframe && iframe.contentWindow) {
      iframe.src =
        "https://" +
        (loginParams?.isPre ? "pre-login" : "login") +
        ".dingtalk.com/oauth2/auth?iframe=true&redirect_uri=" +
        loginParams.redirect_uri +
        "&response_type=" +
        loginParams.response_type +
        "&client_id=" +
        loginParams.client_id +
        "&scope=" +
        loginParams.scope +
        (loginParams.prompt ? "&prompt=" + loginParams.prompt : "") +
        (loginParams.state ? "&state=" + loginParams.state : "") +
        (loginParams.org_type ? "&org_type=" + loginParams.org_type : "") +
        (loginParams.corpId ? "&corpId=" + loginParams.corpId : "") +
        (loginParams.exclusiveLogin
          ? "&exclusiveLogin=" + loginParams.exclusiveLogin
          : "") +
        (loginParams.exclusiveCorpId
          ? "&exclusiveCorpId=" + loginParams.exclusiveCorpId
          : "");
      iframe.width = "" + (frameParams.width || 300);
      iframe.height = "" + (frameParams.height || 300);
      iframe.frameBorder = "0";
      iframe.scrolling = "no";
    }
    const onMessage = (e: MessageEvent<any>) => {
      const origin = e.origin;
      const data = e.data;
      if (/login\.dingtalk\.com/.test(origin) && data) {
        if (data.success && data.redirectUrl) {
          const redirectUrl = data.redirectUrl;
          const urlObj = new URL(redirectUrl);
          // 创建URLSearchParams对象，并传入查询字符串
          const params = new URLSearchParams(urlObj.search || "");
          const authCode = params.get("authCode") || "";
          const state = params.get("state") || "";
          const error = params.get("error") || "";
          if (authCode && successCbk) {
            successCbk({
              redirectUrl,
              authCode,
              state: state || "",
            });
          } else if (errorCbk) {
            errorCbk(error);
          }
        } else {
          if (errorCbk) {
            errorCbk(data.errorMsg);
          }
        }
      }
    };
    window.addEventListener("message", onMessage);
    return () => {
      window.removeEventListener("message", onMessage);
    };
  };

  return {
    DTFrameLogin,
  };
};
