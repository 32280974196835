import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-92432a82")
const _hoisted_1 = { class: "gc-point" }
_popScopeId()

import { ref } from "vue";
import type { CSSProperties, PropType } from "vue";

export default _defineComponent({
  props: {
  pointStyle: {
    type: Object as PropType<CSSProperties>,
    default: () => ({
      "min-width": "45px",
      height: "26px",
    }),
  },
  pointInfo: {
    type: Object,
    default: () => ({}),
  },
},
  setup(__props) {

const props = __props

const haveAllPoint = ref(
  !!props.pointInfo.addPoint && !!props.pointInfo.dockPoint
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (__props.pointInfo.addPoint)
      ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          style: _normalizeStyle(__props.pointStyle ? __props.pointStyle : {}),
          class: _normalizeClass(["point-plus", { onlyOne: !haveAllPoint.value }])
        }, " +" + _toDisplayString(__props.pointInfo.addPoint), 7))
      : _createCommentVNode("", true),
    (__props.pointInfo.dockPoint)
      ? (_openBlock(), _createElementBlock("span", {
          key: 1,
          style: _normalizeStyle(__props.pointStyle ? __props.pointStyle : {}),
          class: _normalizeClass(["point-minus", { onlyOne: !haveAllPoint.value }])
        }, " -" + _toDisplayString(__props.pointInfo.dockPoint), 7))
      : _createCommentVNode("", true)
  ]))
}
}

})