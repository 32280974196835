import servers from "./servers";

export default {
  [servers.user]: "https://user-api.ecoach.evideo.tech",
  [servers.device]: "https://api.ecoach.evideo.tech",
  [servers.platform]: "https://platform-api.ecoach.evideo.tech",
  [servers.pptist]: "https://ppt.ecoach.evideo.tech",
  [servers.ecoach]: "https://music-score.ecoach.evideo.tech",
  [servers.resource]: "https://resource-center.ecoach.evideo.tech",
  [servers.ec]: "https://ec.ecoach.evideo.tech/stat",
  [servers.qrcode]: "https://gateway.ecoach.evideo.tech/api/common-service/",
  [servers.kefuQrcode]:
    "https://gateway.ecoach.evideo.tech/api/common-service/",
  [servers.authorization]: "https://api.ecoach.evideo.tech",
  [servers.musicGame]: "https://interactive-teach.ecoach.evideo.tech",
  [servers.common]: "https://gateway.ecoach.evideo.tech/api/common-service/",
  [servers.tools]: "https://h5-tools.ecoach.evideo.tech",
  [servers.exam]: "https://exam-center-service.ecoach.evideo.tech",
  [servers.localResource]: "http://127.0.0.1",
  [servers.dingtalkCallback]:
    "https://multi-platform.teaching.evideo.tech/login",
  [servers.school]: "https://school.ecoach.evideo.tech",
  [servers.deviceControlService]: "http://127.0.0.1",
  [servers.cloud]: "https://cloud-pre-render-service.ecoach.evideo.tech",
  [servers.app]: "https://multi-platform.teaching.evideo.tech",
  [servers.api]: "https://api.ecoach.evideo.tech/",
};
