import { createStore } from "vuex";
import { state } from "./state";
import getters from "./getters";
import mutations, { MutationNames } from "./mutations";

export default createStore<State>({
  state,
  getters,
  mutations,
});
export { MutationNames };
