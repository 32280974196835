
interface QrCodeConfig {
  event_key: string;
  qrcode_url: string;
}

const ErrorMessageMap = {
  NET_ERROR: "加载失败,请检查网络连接",
  QRCODE_OUTDATED: "二维码已过期,请点击上方刷新",
};

import { defineComponent, onBeforeUnmount, onMounted, ref, watch } from "vue";
import QRCode from "qrcodejs2";

import { getTeacherQRLoginCode, queryTeacherLoginQrResult } from "@/services";
import logger from "@evideo/logger";
import _ from "lodash";

export default defineComponent({
  props: {
    disable: {
      type: Boolean,
      default: false,
    },
    handleLoginIn: Function,
  },
  emits: ["update:disable"],
  setup(props, ctx) {
    const qrCodeConfig = ref<QrCodeConfig>();
    const weChatQrCode = ref<any>();
    const weChatTimer = ref<any>("");
    const timeOutCount = ref<number>(0);
    const showLoading = ref(false);
    const showError = ref(false);
    const errorMessage = ref("");

    const weChatToken = ref("");

    const getQrLoginCodeConfig = () => {
      logger.info("初始化微信配置中...");
      showLoading.value = true;
      return new Promise((resolve, reject) => {
        if (navigator.onLine) {
          getTeacherQRLoginCode()
            .then((res: any) => {
              if (res?.message !== "success") {
                showLoading.value = false;
                showError.value = true;
                errorMessage.value = ErrorMessageMap.NET_ERROR;
                logger.error("初始化微信配置失败，获取二维码失败或网络错误");
                reject("获取二维码失败或网络错误");
                return;
              }
              logger.info("初始化微信配置成功获取");
              showError.value = false;
              qrCodeConfig.value = res?.data;
              showLoading.value = false;
              resolve("");
            })
            .catch((err: any) => {
              showLoading.value = false;
              showError.value = true;
              errorMessage.value = ErrorMessageMap.NET_ERROR;
              logger.error("初始化微信配置失败，获取二维码失败或网络错误", err);
              reject("获取二维码失败或网络错误");
            });
        } else {
          showLoading.value = false;
          showError.value = true;
          errorMessage.value = ErrorMessageMap.NET_ERROR;
          logger.error("初始化微信配置失败，获取二维码失败或网络错误");
          reject("获取二维码失败或网络错误");
        }
      });
    };

    const resetErrorMessage = () => {
      showError.value = false;
      errorMessage.value = "";
    };

    const resetTimer = () => {
      if (weChatTimer.value) {
        clearInterval(weChatTimer.value);
        weChatTimer.value = undefined;
        timeOutCount.value = 0;
      }
    };

    const reLoadWeChatQrCode = () => {
      logger.info("我点击重新加载二维码了");
      weChatToken.value = "";
      document.getElementById("weChatQrCode")!.innerHTML = "";
      showError.value = false;
      showLoading.value = true;
      setTimeout(async () => {
        try {
          await getQrLoginCodeConfig();
          initWeChatQrCode();
          resetErrorMessage();
        } catch (err: any) {
          console.error(err);
        }
      }, 1000);
      return;
    };

    const listenLoginState = (eventKey: string) => {
      resetTimer();
      weChatTimer.value = setInterval(
        _.throttle(async () => {
          timeOutCount.value++;
          if (timeOutCount.value >= 300) {
            resetTimer();
            document.getElementById("weChatQrCode")!.innerHTML = "";
            showError.value = true;
            errorMessage.value = ErrorMessageMap.QRCODE_OUTDATED;
            weChatToken.value = "";
            return;
          }
          if (timeOutCount.value !== 300) {
            if (navigator.onLine && !props.disable) {
              await queryTeacherLoginQrResult({
                event_key: eventKey,
              }).then((res: any) => {
                const { open_id, token } = res?.data || {};
                if (!res?.data) {
                  document.getElementById("weChatQrCode")!.innerHTML = "";
                  weChatQrCode.value.innerHTML = "";
                  resetTimer();
                  reLoadWeChatQrCode();
                  weChatToken.value = "";
                }
                if (open_id && token) {
                  resetTimer();
                  weChatToken.value = token?.access_token;
                  // if (props?.handleLoginIn && !props.disable) {
                  //   ctx.emit("update:disable", true);
                  //   props.handleLoginIn(token?.access_token).finally(() => {
                  //     ctx.emit("update:disable", false);
                  //   });
                  // }
                }
              });
            }
          }
        }, 1000),
        1000
      );
    };

    const initWeChatQrCode = () => {
      if (!qrCodeConfig.value?.qrcode_url) {
        return;
      }
      if (document.getElementById("weChatQrCode")) {
        document.getElementById("weChatQrCode")!.innerHTML = "";
        weChatQrCode.value = new QRCode("weChatQrCode", {
          text: qrCodeConfig.value.qrcode_url,
          width: 200,
          height: 200,
        });

        resetTimer();
        listenLoginState(qrCodeConfig.value.event_key);
      }
    };

    watch(
      () => weChatToken.value,
      () => {
        if (props?.handleLoginIn && !props.disable && weChatToken.value) {
          ctx.emit("update:disable", true);
          props.handleLoginIn(weChatToken.value).finally(() => {
            ctx.emit("update:disable", false);
          });
        }
      }
    );
    onBeforeUnmount(() => {
      resetTimer();
    });

    onMounted(async () => {
      try {
        await getQrLoginCodeConfig();
        initWeChatQrCode();
      } catch (err: any) {
        console.error(err);
      }
    });
    return {
      showLoading,
      showError,
      errorMessage,
      reLoadWeChatQrCode,
    };
  },
});
