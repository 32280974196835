import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-80f28c14")
const _hoisted_1 = { class: "volume-btn" }
const _hoisted_2 = { class: "slider-wrapper" }
_popScopeId()

import { computed, onMounted, onUnmounted, ref, watch } from "vue";
interface VolumeSliderProps {
  value: number;
  mute: boolean;
}


export default _defineComponent({
  props: {
    value: { type: Number, required: true, default: 60 },
    mute: { type: Boolean, required: true, default: false }
  } as unknown as undefined,
  emits: ["updateValue", "updateMute"] as unknown as undefined,
  setup(__props: {
  value: number;
  mute: boolean;
}, { emit }: { emit: ({
  (event: "updateValue", newVal: number): void;
  (event: "updateMute", newVal: boolean): void;
}), expose: any, slots: any, attrs: any }) {

const props = __props




const value = ref<number>(props.value);

// 是否静音
const isMute = computed<boolean>(() => props.mute);

const sliderLineRef = ref<any>();

const sliderLineWidth = computed(() => sliderLineRef.value.clientWidth);

const sliderLineOffset = computed(
  () => sliderLineRef.value?.getBoundingClientRect()?.left
);

const isDrop = ref<boolean>(false);

watch(
  () => props.value,
  (newVal) => {
    if (newVal !== 0) {
      value.value = newVal;
    }
  }
);

const handleClickIcon = (mute: boolean) => {
  updateVolume(mute);
  emit("updateMute", mute);
};

const updateVolume = (mute = false) => {
  if (mute) {
    emit("updateValue", 0);
  } else {
    emit("updateValue", value.value);
  }
};

const handleClickSlider = (e: MouseEvent) => {
  const offset = e.offsetX;
  const width = sliderLineRef.value.clientWidth;
  if (width === undefined || width === 0) {
    return;
  }
  let per = Math.ceil((offset * 100) / width);
  if (per < 0) {
    per = 0;
  }
  if (per > 100) {
    per = 100;
  }
  // 解触静音
  if (per !== 0 && isMute.value === true) {
    emit("updateMute", false);
  }
  value.value = per;
  updateVolume();
};

// 开始滑动
const handleStartDrop = () => {
  isDrop.value = true;
};

// touch滑动的事件，要对事件做兼容
const handleTouchDrop = (e: any) => {
  if (e.targetTouches && e.targetTouches[0]) {
    handleDrop(e.targetTouches[0]);
  }
};

// 滑动过程中
const handleDrop = (e: any) => {
  if (isDrop.value) {
    const offset = e.pageX - sliderLineOffset.value;
    const width = sliderLineWidth.value;
    if (width === undefined || width === 0) {
      return;
    }
    let per = Math.ceil((offset * 100) / width);
    if (per < 0) {
      per = 0;
    }
    if (per > 100) {
      per = 100;
    }
    value.value = per;
  }
};

// 滑动结束
const handleDropEnd = () => {
  if (isDrop.value) {
    isDrop.value = false;
    if (value.value > 0 && isMute.value === true) {
      emit("updateMute", false);
    }
    updateVolume();
  }
};

onMounted(() => {
  // 注册滑动监听的事件
  document.body.addEventListener("mouseup", handleDropEnd);
  document.body.addEventListener("mousemove", handleDrop);
  document.body.addEventListener("touchend", handleDropEnd);
  document.body.addEventListener("touchmove", handleTouchDrop);
});

onUnmounted(() => {
  document.body.removeEventListener("mouseup", handleDropEnd);
  document.body.removeEventListener("mousemove", handleDrop);
  document.body.removeEventListener("touchend", handleDropEnd);
  document.body.removeEventListener("touchmove", handleTouchDrop);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_unref(isMute) === false)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "volume-icon",
          onClick: _cache[0] || (_cache[0] = () => handleClickIcon(true))
        }))
      : (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: "no-volume-icon",
          onClick: _cache[1] || (_cache[1] = () => handleClickIcon(false))
        })),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: "slider-line-wrapper",
        onClick: handleClickSlider
      }, [
        _createElementVNode("div", {
          ref: sliderLineRef,
          class: "slider-line"
        }, null, 512)
      ]),
      _createElementVNode("div", {
        class: "slider-btn",
        style: _normalizeStyle({ left: value.value + '%' }),
        onMousedown: handleStartDrop,
        onTouchstart: handleStartDrop
      }, _toDisplayString(value.value), 37)
    ])
  ]))
}
}

})