import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-5cf8a6dd")
const _hoisted_1 = { class: "zdy-login" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_WebIframe = _resolveComponent("WebIframe")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        class: _normalizeClass(["zdy-login-btn", !_ctx.isLogin ? '' : 'no-allow-click']),
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.goToZdyLoginView && _ctx.goToZdyLoginView(...args)))
      }, null, 2)
    ]),
    (_ctx.zdyVisible)
      ? (_openBlock(), _createBlock(_component_WebIframe, {
          key: 0,
          visible: _ctx.zdyVisible,
          "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => (_ctx.zdyVisible = $event)),
          url: _ctx.zdyUrl,
          height: 800,
          backVisible: _ctx.iframeBackVisible
        }, null, 8, ["visible", "url", "backVisible"]))
      : _createCommentVNode("", true)
  ], 64))
}