import { computed } from "vue";
import store, { MutationNames } from "@/store/index";
import { useRoute, useRouter } from "vue-router";
import logger from "@evideo/logger";

const route = useRoute();

declare global {
  interface Window {
    WebViewJavascriptBridge: {
      registerHandler: (
        key: string,
        callBack: (data: any, responseCallback: (data: any) => void) => void
      ) => void;
      callHandler: (
        key: string,
        prams?: any,
        callBack?: (data: any) => void
      ) => void;
    };
  }
}
export interface JsBridgeObject {
  success: boolean;
  eventCode: number;
  data: any;
}

export default () => {
  const router = useRouter();
  const jsBridge = computed(() => window.WebViewJavascriptBridge);

  const checkJsBridge = computed(() => !!jsBridge.value);

  // 从Android获取token
  const registerH5GetToken = () => {
    return new Promise((reslove, reject) => {
      try {
        jsBridge.value.registerHandler("h5GetToken", (data) => {
          const parseData = JSON.parse(data);
          const androidToken = parseData?.data?.token;
          localStorage.setItem("token", androidToken);
          store.commit(MutationNames.CHANGE_TOKEN, androidToken);
          store.commit(MutationNames.CHANGE_RELOADTEACHMATER, true);
          reslove(data);
        });
      } catch (error) {
        reject(error);
      }
    });
  };
  // 根据路由发过来的消息进行跳转
  const registerH5GetURL = () => {
    // const currentTime = new Date().getTime();
    return new Promise((reslove, reject) => {
      try {
        jsBridge.value.registerHandler("h5GetURL", (data) => {
          const currentTime = new Date().getTime();
          const parseData = JSON.parse(data);
          logger.info("h5GetURL接收到android发过来的消息", parseData);
          const androidToken = parseData?.data?.token;
          const androidUserType = parseData?.data?.userType;
          localStorage.setItem("token", androidToken);
          store.commit(MutationNames.CHANGE_TOKEN, androidToken);
          store.commit(MutationNames.CHANGE_ANDROIDUSERTYPE, androidUserType);
          // skinType 0|卡通 1｜青春
          // routeType 0|教材 1｜我的课件 2｜课件中心 3｜特色课堂
          if (parseData?.data?.skinType === 0) {
            if (parseData?.data?.routeType === 0) {
              router.push({
                path: "/teachMaterialCartoon",
                query: { teachMaterialType: "official", _: currentTime },
                force: true,
              });
            }
            if (parseData?.data?.routeType === 1) {
              router.push({
                path: "/teachCourseWareCartoon",
                query: {
                  _: currentTime,
                },
                force: true,
              });
            }
            if (parseData?.data?.routeType === 2) {
              router.push({
                path: "/coursewareCenterCartoon",
                query: {
                  _: currentTime,
                },
                force: true,
              });
            }
            if (parseData?.data?.routeType === 3) {
              router.push({
                // path: "/login",
                // query: { time: DateTime },
                path: "/teachMaterialCartoon",
                query: {
                  teachMaterialType: "characteristicClassroom",
                  _: currentTime,
                },
                force: true,
              });
            }
          } else {
            if (parseData?.data?.routeType === 0) {
              router.push({
                path: "/teachMaterial",
                query: { teachMaterialType: "official", _: currentTime },
                force: true,
              });
            }
            if (parseData?.data?.routeType === 1) {
              router.push({
                path: "/courseWare",
                query: { _: currentTime },
                force: true,
              });
            }
            if (parseData?.data?.routeType === 2) {
              router.push({
                path: "/coursewareCenter",
                query: {
                  _: currentTime,
                },
                force: true,
              });
            }
            if (parseData?.data?.routeType === 3) {
              router.push({
                path: "/teachMaterial",
                query: {
                  teachMaterialType: "characteristicClassroom",
                  _: currentTime,
                },
                force: true,
              });
            }
          }
          reslove(data);
        });
      } catch (error) {
        reject(error);
      }
    });
  };

  const registeLoadPpt = () => {
    const currentTime = new Date().getTime();
    return new Promise((reslove, reject) => {
      try {
        jsBridge.value.registerHandler("loadppt", (dataStr) => {
          const parseData = JSON.parse(dataStr);
          const androidToken = parseData?.data?.token;
          const androidUserType = parseData?.data?.userType;
          localStorage.setItem("token", androidToken);
          logger.info("ppt-view-jstoken", androidToken);
          store.commit(MutationNames.CHANGE_TOKEN, androidToken);
          store.commit(MutationNames.CHANGE_ANDROIDUSERTYPE, androidUserType);

          if (parseData.data.url) {
            router.push({
              path: "/pptview",
              query: { pptUrl: parseData.data.url, _: currentTime },
              force: true,
            });
          } else {
            logger.info(" ppt 没有url");
          }
          reslove(parseData);
        });
      } catch (error) {
        reject(error);
      }
    });
  };

  const registerH5EventList = () => {
    return new Promise((reslove, reject) => {
      try {
        jsBridge.value.registerHandler("jsBridgeRegistedEvents", (dataStr) => {
          const parseData = JSON.parse(dataStr);
          store.commit(
            MutationNames.CHANGE_CARRIER_APPLICATION_REGISTED_EVENTS,
            parseData
          );
          reslove(parseData);
        });
      } catch (error) {
        reject(error);
      }
    });
  };

  // 从Android获取返回的url
  const registerGetGoBackUrl = () => {
    return new Promise((reslove, reject) => {
      try {
        jsBridge.value.registerHandler("goback", (data) => {
          logger.info("安卓触发返回的url", data);
          // if (data.includes("/materialPpt")) {
          //   // 教材的ppt页面点击返回，返回到对应的教材列表页面
          //   logger.info("教材的ppt页面点击返回，返回到对应的教材列表页面");
          //   const skinType = route.query.skinType;
          //   const bookType = route.query.teachMaterialType;
          //   router.push({
          //     path:
          //       skinType === "cartoon"
          //         ? "/teachMaterialCartoon"
          //         : "/teachMaterial",
          //     query: {
          //       teachMaterialType: bookType,
          //     },
          //   });
          // } else
          if (data.includes("/materialPpt")) {
            (window as any).MaterialPptGoBack();
          } else if (
            data.includes("/teachMaterial") ||
            data.includes("/teachMaterialCartoon") ||
            data.includes("/teachCourseWare") ||
            data.includes("/teachCourseWareCartoon") ||
            data.includes("/coursewareCenter") ||
            data.includes("/coursewareCenterCartoon")
          ) {
            // 教材列表页通知Android隐藏webview
            logger.info(
              "接收到Android发送的返回消息隐藏webview，先跳转到blank页面"
            );
            if (data.includes("Cartoon")) {
              router.replace({
                path: "/blank",
                query: {
                  skinType: 0,
                },
              });
            } else {
              router.replace({
                path: "/blank",
                query: {
                  skinType: 1,
                },
              });
            }
          } else {
            logger.info("课件ppt页面goback()");
            history.back();
          }
          // else if (data.includes("/courseWarePpt")) {
          //   // 课件ppt页面goback()
          //   logger.info("课件ppt页面goback()");
          //   history.back();
          // }
          // reslove(data);
        });
      } catch (error) {
        reject(error);
      }
    });
  };

  return {
    checkJsBridge,
    registerH5GetToken,
    registerH5GetURL,
    registeLoadPpt,
    registerH5EventList,
    registerGetGoBackUrl,
  };
};
