
import {
  defineComponent,
  ref,
  onMounted,
  inject,
  onBeforeUnmount,
  computed,
  watch,
} from "vue";
import logger from "@evideo/logger";
import {
  queryAllResource,
  clearLocalResource,
  clearLocalFailedResource,
} from "@/services/localResource.ts";
import { ElMessage } from "element-plus";
import { SocketEventMap } from "@/utils/socket-event";
import store from "@/store/index";
import _ from "lodash";

export default defineComponent({
  name: "clearCache",
  props: {
    canUseCacheFailedBtn: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["update:canUseCacheFailedBtn"],
  setup(props, ctx) {
    // const multipleTableRef = ref<InstanceType<typeof ElTable>>();
    const multipleTableRef = ref();
    let tableData = ref([]);
    // 本地缓存总资源数
    let totalNum = ref(0);
    // 当前页数
    let currentPage = ref(1);
    // 排序类型查询条件
    let typeValue = ref(undefined);
    // 文件名查询条件
    let fileName = ref(undefined);
    // 选中的文件
    const multipleSelection = ref([]);
    // 判断版本，>=1.4.3使用2.0版本缓存服务
    const canUseHightLocalResource = computed(() =>
      _.gte(store.state.version, "1.4.3")
    );
    const failedBtnVisible = computed(() => props.canUseCacheFailedBtn);

    const socket = inject("socket");
    // 排序类型
    const typeOptions = [
      {
        value: "popular_desc",
        label: "按热度降序",
      },
      {
        value: "popular_asc",
        label: "按热度升序",
      },
      {
        value: "time_desc",
        label: "按下载时间降序",
      },
      {
        value: "time_asc",
        label: "按下载时间升序",
      },
    ];

    const ResourceTypeConfig = {
      PPT: { label: "PPT", color: "#409EFF" },
      IMAGE: { label: "图片", color: "#67C23A" },
      VIDEO: { label: "视频", color: "#F56C6C" },
      AUDIO: { label: "音频", color: "#89B71C" },
      SWF: { label: "Flash课件", color: "#b56ce3 " },
      MUSIC_RHYTHM: { label: "音乐律动", color: "#C800C8" },
      SING_PLAY: { label: "歌曲", color: "#FFA500" },
    };

    // 对数据根据类型进行转换
    const exchangeItemType = (item) => {
      if (!item) {
        return item;
      }
      let typeLabel = "未知";
      let typeColor = "#C8C8C8";
      // 对旧版本兼容一下，旧版本是个中文
      if (!ResourceTypeConfig[item.type] && item.type !== undefined) {
        typeLabel = item.type;
      } else {
        const cfg = ResourceTypeConfig[item.type];
        if (cfg) {
          typeLabel = cfg.label;
          typeColor = cfg.color;
        }
      }
      return {
        ...item,
        type_label: typeLabel,
        type_color: typeColor,
      };
    };

    socket.on(SocketEventMap.BATCH_DELETE_RESULT, (e) => {
      if (e?.data?.result) {
        ElMessage.success({
          offset: 200,
          message: "清理成功",
        });
      } else {
        ElMessage.error({
          offset: 200,
          message: "清理失败",
        });
      }
    });
    const getBatchDeleteFailedResult = (data) => {
      const { result } = data?.data || {};
      if (result === "success") {
        ElMessage.success({
          offset: 200,
          message: "清理失败数据成功",
        });
      }
      ctx.emit("update:canUseCacheFailedBtn", true);
    };
    socket.on(
      SocketEventMap.BATCH_DELETE_FAILED_RESULT,
      getBatchDeleteFailedResult
    );
    const handleClear = async (eldate) => {
      console.log("click", eldate);
      const { id } = eldate;
      try {
        await clearLocalResource({ ids: [id] });
        ElMessage.info({
          offset: 200,
          message: "删除中，请稍后",
        });
        handleSearch(1);
      } catch (e) {
        console.log(e);
      }
    };

    // 清除选中的资源
    const handleBtachClear = async () => {
      if (multipleSelection.value.length < 1) {
        ElMessage.warning({
          offset: 200,
          message: "请先勾选需要清理的文件",
        });
        return;
      }
      try {
        await clearLocalResource({
          ids: multipleSelection.value.map((i) => i.id),
        });
        multipleSelection.value = [];
        ElMessage.info({
          offset: 200,
          message: "删除中，请稍后",
        });
        handleSearch(1);
      } catch (e) {
        console.log(e);
      }
    };
    // 清空失败数据
    const handleBatchClearFailed = async () => {
      try {
        ctx.emit("update:canUseCacheFailedBtn", false);
        await clearLocalFailedResource();
      } catch (err) {
        console.error(`清空下载失败文件失败：${err}`);
        ctx.emit("update:canUseCacheFailedBtn", true);
      }
    };
    const handleSearch = async (val = 1) => {
      currentPage.value = val;

      const params = {
        name: fileName.value,
        sort_by: typeValue.value,
        page: val,
        per_page: 7,
      };

      try {
        const data = await queryAllResource(params);
        const { items, meta } = data?.data;
        console.log(data);
        tableData.value = items || [];
        totalNum.value = meta.total || 0;
      } catch (e) {
        console.error(e);
        tableData.value = [];
        totalNum.value = 0;
      } finally {
        tableData.value = tableData.value.map((i) => exchangeItemType(i));
      }
    };
    const handleCurrentChange = (val) => {
      handleSearch(val);
    };
    const handleSelectionChange = (val) => {
      multipleSelection.value = val;
    };

    // 保持选中状态
    const getRowKey = (row) => {
      // 保证是唯一标识符即可
      return row.id;
    };
    // 保持选中状态
    const testaa = (row) => {
      console.log(row);
    };

    onMounted(() => {
      handleSearch(1);
    });
    onBeforeUnmount(() => {
      socket.off(SocketEventMap.BATCH_DELETE_RESULT);
      socket.off(
        SocketEventMap.BATCH_DELETE_FAILED_RESULT,
        getBatchDeleteFailedResult
      );
    });

    return {
      multipleTableRef,
      handleClear,
      tableData,
      totalNum,
      typeOptions,
      handleSearch,
      currentPage,
      handleCurrentChange,
      fileName,
      typeValue,
      handleSelectionChange,
      getRowKey,
      handleBtachClear,
      handleBatchClearFailed,
      testaa,
      canUseHightLocalResource,
      failedBtnVisible,
    };
  },
});
