import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, vShow as _vShow, withDirectives as _withDirectives, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, withScopeId as _withScopeId } from "vue"

import {
  PropType,
  computed,
  ref,
  provide,
} from "vue";
import Student from "../student/index.vue";
import Group from "../group/index.vue";
import EffectMask from "../base/EffectMask.vue";


export default _defineComponent({
  props: {
  isGroup: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
},
  setup(__props, { expose }) {

const props = __props


const studentRef = ref();
const groupRef = ref();

const isGroupCompu = computed(() => props.isGroup);

const showMask = ref<boolean>();

const maskInfo = ref();

const maskRef = ref();

const handleShowMask = (config: any) => {
  maskInfo.value = config;
  if (maskRef.value) {
    showMask.value = true;
    maskRef.value.handleShowMask();
  }
};

const handleMaskClose = () => {
  showMask.value = false;
};

const handleRandom = () => {
  if (isGroupCompu.value && groupRef.value && groupRef.value.onRandom) {
    groupRef.value.onRandom();
  } else if (
    !isGroupCompu.value &&
    studentRef.value &&
    studentRef.value.onRandom
  ) {
    studentRef.value.onRandom();
  }
};

const handleSearch = (keyword: string) => {
  if (!isGroupCompu.value && studentRef.value && studentRef.value.onSearch) {
    studentRef.value.onSearch(keyword);
  }
};

const cancelMultiEvaluation = () => {
  if (isGroupCompu.value) {
    groupRef.value.handleCancelMultiSelect();
  } else {
    // 多选模式 取消按钮 退出多选模式
    studentRef.value.onMultiSelectCancel();
  }
};

const handleSubmitMultiSelect = () => {
  if (isGroupCompu.value) {
    groupRef.value.handleSubmitMultiSelect();
  } else {
    // 多选模式 发送点评 开始点评
    studentRef.value.onMultiSelectStuOK();
  }
};

const handleSelectAllBtn = () => {
  if (isGroupCompu.value) {
    groupRef.value.handleSelectAllGroupBtn();
  } else {
    studentRef.value.Allselect();
  }
};

provide("handleShowMask", handleShowMask);

expose({
  onRandom: handleRandom,
  onSearch: handleSearch,
  onCancelMultiEvaluation: cancelMultiEvaluation,
  onSubmitMultiSelect: handleSubmitMultiSelect,
  onSelectAllBtn: handleSelectAllBtn,
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "evaluation-list",
    style: _normalizeStyle({ overflowY: showMask.value ? 'hidden' : 'auto', overflowX: 'hidden' })
  }, [
    _createElementVNode("div", null, [
      _createVNode(EffectMask, {
        onOnClose: handleMaskClose,
        ref: maskRef,
        maskInfo: maskInfo.value
      }, null, 8, ["maskInfo"])
    ]),
    _withDirectives(_createElementVNode("div", null, [
      _createVNode(Student, { ref: studentRef }, null, 512)
    ], 512), [
      [_vShow, !_unref(isGroupCompu)]
    ]),
    _withDirectives(_createElementVNode("div", null, [
      _createVNode(Group, { ref: groupRef }, null, 512)
    ], 512), [
      [_vShow, _unref(isGroupCompu)]
    ])
  ], 4))
}
}

})