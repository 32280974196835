export const DEVICE_EVENTS = {
  SEND_CONTROL_ORDER: "S_SEND_CONTROL_ORDER", // 转发指令给设备端
  BATCH_CONTROL_INFO: "M_BATCH_CONTROL_INFO", // 批量控制的信息
  UPDATE_DEVICES_INFO: "M_UPDATE_DEVICES_INFO", // 更新设备信息列表
  /** 受控设备接收的事件 */
  SET_DEVICE_ENABLED: "D_SET_DEVICE_ENABLED", // 启用/禁用设备
  LOGOUT_ACCOUNT: "D_LOGOUT_ACCOUNT", // 设备退出登录
  SET_VOLUME: "D_SET_VOLUME", // 设置设备音量
  SET_VOCAL_BALANCE: "D_SET_VOCAL_BALANCE", // 设置人生均衡的相关设置
};

export const sendOrderToDevice = (
  socket: any,
  order: string,
  revicever: "all" | string[],
  args?: any
) => {
  if (!socket || !socket.emit) {
    return;
  }
  if (!new Set(Object.values(DEVICE_EVENTS)).has(order)) {
    return;
  }
  const msg = {
    msg_type: DEVICE_EVENTS.SEND_CONTROL_ORDER,
    revicer: revicever,
    order: order,
    args: {
      ...args,
      msg_type: order,
    },
  };

  socket.emit(DEVICE_EVENTS.SEND_CONTROL_ORDER, msg);
};

export const updateDeviceEnabled = (
  socket: any,
  id: "all" | string[],
  enabled: boolean
) => {
  sendOrderToDevice(socket, DEVICE_EVENTS.SET_DEVICE_ENABLED, id, { enabled });
};

/**
 * @description 批量修改设备音量
 * @param socket socket对象
 * @param volume 音量
 */
export const batchSetDeviceVolume = (socket: any, volume: number) => {
  sendOrderToDevice(socket, DEVICE_EVENTS.SET_VOLUME, "all", { volume });
};

/**
 * @description 批量修设置设备登出
 * @param socket  socket对象
 * @param revicer 接收者
 */
export const batchSetDeviceLogout = (
  socket: any,
  revicer: "all" | string[]
) => {
  sendOrderToDevice(socket, DEVICE_EVENTS.LOGOUT_ACCOUNT, revicer);
};

export const batchSetVocalBalance = (socket: any, vocal: any) => {
  sendOrderToDevice(socket, DEVICE_EVENTS.SET_VOCAL_BALANCE, "all", {
    vocal_balance: vocal,
  });
};
