import axios from "axios";
import { ElMessageBox, ElMessage } from "element-plus";
import store, { MutationNames } from "@/store/index";
import config from "@/config/app.config";
import router from "@/router";
import { useRoute } from "vue-router";
import { message } from "ant-design-vue";
import logger from "@evideo/logger";
const device = navigator.userAgent;
const android = device.indexOf("Android") > -1 || device.indexOf("Adr") > -1;
// 报错返回登录页的锁reuqest
let requestLock = false;
// 报错返回登录页的锁devicerequest
let requestLockDevice = false;
const route = useRoute();
const getErrorMessage = (status: number) => {
  switch (status) {
    case 400:
      return "请求错误";
    case 401:
      return "未授权，请重新登录";
    case 403:
      return "拒绝访问";
    case 404:
      return "请求出错";
    case 408:
      return "请求超时";
    case 422:
      return "资源错误";
    case 500:
      return "服务器错误";
    case 501:
      return "功能正在路上";
    case 502:
      return "网络错误";
    case 503:
      return "服务不可用";
    case 504:
      return "网络超时";
    case 505:
      return "HTTP版本不受支持";
    default:
      return `连接出错(${status})!`;
  }
};
const request = (
  method: string,
  url: string,
  server: any,
  params?: any,
  needToken = true,
  retryCount = 0,
  needErrMes = true
) => {
  // location.reload();
  const Token = localStorage.getItem("token");
  const mac = store.state.mac;
  const headers = {
    "Content-Type": "application/json",
    Authorization: needToken ? `Bearer ${Token}` : "",
    mac: mac,
    // Authorization: needToken ? `Bearer ${store.state.token}` : "",
  };
  return new Promise((resolve, reject) => {
    let OnlineState = true;
    let isAndroid = true;
    const device = navigator.userAgent;
    isAndroid = device.indexOf("Android") > -1 || device.indexOf("Adr") > -1;
    if (isAndroid) {
      if (window.require) {
        const { ipcRenderer } = window.require("electron");
        OnlineState = ipcRenderer.sendSync("getIsOnlineState");
        logger.info("OnlineState", OnlineState);
      }
    }
    logger.info("requestUrl", url);
    logger.info("requestParams", JSON.stringify(params));
    if (OnlineState) {
      axios({
        method,
        headers,
        baseURL: config[server],
        url,
        timeout: 15000,
        params: method === "GET" ? params : null, // 是即将与请求一起发送的 URL 参数
        data: method === "POST" || method === "PUT" ? params : null, // 是作为请求主体被发送的数据
      } as any)
        .then((res: any) => {
          resolve(res.data);
        })
        .catch((error: any) => {
          let message = "";
          // 网络断开时的提示
          if (!error.response) {
            if (retryCount > 3) {
              if (android) {
                // 是android通知关闭
                window.WebViewJavascriptBridge.callHandler(
                  "closeTeachMaterial", // native中注册时的key值
                  null // native中注册方法中的传入的数据
                );
                router.push({
                  path: "/blank",
                });
              } else {
                // 是pc通知electron 有接口请求失败，当网络恢复需要刷新页面
                if (window.require) {
                  const { ipcRenderer } = window.require("electron");
                  logger.info("向electron发送接口请求失败");
                  // start-camera 必须在版本大于等于1.3.0的版本
                  const iscompare = compareVersion(
                    store.state.version,
                    "1.3.0"
                  );
                  if (iscompare >= 0) {
                    ipcRenderer.sendToHost("request-error"); // 向原生发送有接口请求失败信息
                  }
                }
              }
              if (
                ![
                  "/device/checkPcTeachAuthorization",
                  "/teacher/getTeacherBindSchools",
                  "/teacher/switchTeacherTokenSchool",
                ].includes(url)
              ) {
                reject(error);
                return;
              }
              ElMessageBox({
                title: "系统提示",
                message: "网络连接错误，点击确定返回登录页",
                showClose: false,
                confirmButtonText: "确定",
                callback() {
                  store.commit(MutationNames.CHANGE_NETWORKSTATE, true);
                  const device = navigator.userAgent;
                  const android =
                    device.indexOf("Android") > -1 ||
                    device.indexOf("Adr") > -1;
                  if (android) {
                    window.WebViewJavascriptBridge.callHandler(
                      "closeTeachMaterial", // native中注册时的key值
                      null // native中注册方法中的传入的数据
                    );
                    router.push({
                      path: "/blank",
                      // path: "/login",
                    });
                  } else {
                    store.commit(MutationNames.CHANGE_NETWORKSTATE, true);
                    toLoginUpdateData();
                    // if (route.path != "login") {
                    router.push({
                      // path: "/home",
                      path: "/login",
                    });
                    // }
                  }
                },
              });
              reject(error);
              return;
            } else {
              return request(
                method,
                url,
                server,
                params,
                needToken,
                retryCount + 1
              )
                .then((res) => resolve(res))
                .catch((err) => reject(err));
            }
          }
          if (error.response.data && error.response.data.message) {
            message = error.response.data.message;
          } else {
            message = getErrorMessage(error.response.status);
          }
          logger.info("needToken", needToken);
          logger.info("retryCount", retryCount);
          logger.info("needErrMes", needErrMes);
          logger.info("url", url);
          if (url == "/teacher/auth/login") {
            store.commit(MutationNames.CHANGE_LOGINBTNTXT, true);
          }
          if (!requestLock && needErrMes) {
            requestLock = true;
            logger.info("error", message);
            ElMessageBox({
              title: "系统提示",
              confirmButtonText: "确定",
              message,
              callback() {
                setTimeout(() => {
                  requestLock = false;
                }, 2000);
                if (error.response.status === 401 && needToken) {
                  sessionStorage.clear();
                  // const url = window.location.href;
                  const device = navigator.userAgent;
                  const isAndroid =
                    device.indexOf("Android") > -1 ||
                    device.indexOf("Adr") > -1;
                  if (isAndroid) {
                    logger.info("我是安卓，我要给Android发消息");
                    ElMessage.error({
                      offset: 200,
                      message:
                        "用户登录信息已过期，系统3秒后返回登陆页，请重新登录！",
                      type: "error",
                    });
                    setTimeout(() => {
                      window.WebViewJavascriptBridge.callHandler(
                        "tokenTimeout", // native中注册时的key值
                        null // native中注册方法中的传入的数据
                      );
                    }, 3000);
                  } else {
                    logger.info("我是pc，我直接回登陆页");
                    toLoginUpdateData();
                    router.push({
                      path: "/login",
                      // query: {
                      //   redirect: url.slice(url.indexOf("#") + 1),
                      // },
                    });
                  }
                }
              },
            });
          }
          reject(error);
        });
    } else {
      ElMessage.warning("网络异常，请稍后！");
    }
  });
};
// 返回登录页前做数据处理
const toLoginUpdateData = () => {
  clearInterval(store.state.screenshotTimer);
  store.commit(MutationNames.CHANGE_SCREENSHOTTIMER, "");
  // 重制上下课状态
  store.commit(MutationNames.CHANGE_LESSONSTART, true);
  store.commit(MutationNames.CHANGE_LESSONCLASSID, 0);
  store.commit(MutationNames.CHANGE_GRADENUMBER, "");
  store.commit(MutationNames.CHANGE_CLASSNUMBER, "");
  store.commit(MutationNames.CHANGE_SCHOOL_ID, undefined);
  // 向electron发送下课状态
  if (window.require) {
    const { ipcRenderer } = window.require("electron");
    logger.info(
      "向electron发送信息摄像头rtsp地址",
      store.state.monitorCameraIp
    );
    // start-camera 必须在版本大于等于1.3.0的版本
    const iscompare = compareVersion(store.state.version, "1.3.0");
    if (iscompare >= 0) {
      ipcRenderer.sendToHost("finish-lesson"); // 向原生发送token信息
    }
  }
};
const compareVersion = (v1: any, v2: any) => {
  // -1|v2大于v1；0|相同；1|v2小于v1
  v1 = v1.split(".");
  v2 = v2.split(".");
  const len = Math.max(v1.length, v2.length);

  while (v1.length < len) {
    v1.push("0");
  }
  while (v2.length < len) {
    v2.push("0");
  }

  for (let i = 0; i < len; i++) {
    const num1 = parseInt(v1[i]);
    const num2 = parseInt(v2[i]);

    if (num1 > num2) {
      return 1;
    } else if (num1 < num2) {
      return -1;
    }
  }

  return 0;
};
const commonGetRequest = (url: string, params: any) => {
  const mac = store.state.mac;
  return new Promise((resolve, reject) => {
    axios({
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        mac: mac,
      },
      baseURL: "",
      url,
      timeout: 60000,
      params: params,
      data: null,
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};
const fetchGetRequest = (url: string) => {
  logger.info("fetchGetRequestUrl", url);
  return new Promise((resolve, reject) => {
    fetch(url, {
      method: "GET",
      mode: "no-cors",
    })
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};
const requestDevice = (
  method: string,
  url: string,
  server: any,
  params?: any,
  showErrorMessage = true,
  needToken = true,
  retryCount = 0
) => {
  const Token = localStorage.getItem("deviceToken");
  logger.info("requestDeviceUrl", url);
  logger.info("requestDeviceParams", JSON.stringify(params));
  // mac
  const CHIPID = store.state.mac;
  // const CHIPID = "744CA1A5FE9D";
  // 固件版本号
  const FIRMWAREVERSION = store.state.hardwareModel;
  // const FIRMWAREVERSION = "win64";
  // 软件版本号
  const SOFTWAREVERSION = store.state.version;
  const mac = store.state.mac;
  // const SOFTWAREVERSION = "1.2.1";
  const headers = {
    "Content-Type": "application/json",
    "CHIP-ID": CHIPID,
    "FIRMWARE-VERSION": FIRMWAREVERSION,
    "SOFTWARE-VERSION": SOFTWAREVERSION,
    mac: mac,
    Authorization: needToken ? `Bearer ${Token}` : "",
  };
  return new Promise((resolve, reject) => {
    let OnlineState = true;
    let isAndroid = true;
    const device = navigator.userAgent;
    isAndroid = device.indexOf("Android") > -1 || device.indexOf("Adr") > -1;
    if (isAndroid) {
      if (window.require) {
        const { ipcRenderer } = window.require("electron");
        OnlineState = ipcRenderer.sendSync("getIsOnlineState");
        logger.info("OnlineState", OnlineState);
      }
    }

    if (OnlineState) {
      axios({
        method,
        headers,
        baseURL: config[server],
        url,
        timeout: 15000,
        params: method === "GET" ? params : null, // 是即将与请求一起发送的 URL 参数
        data: method === "POST" || method === "PUT" ? params : null, // 是作为请求主体被发送的数据
      } as any)
        .then((res: any) => {
          resolve(res.data);
        })
        .catch((error: any) => {
          let message = "";
          // 网络断开时的提示
          if (!error.response) {
            if (retryCount > 3) {
              if (android) {
                // 是android通知关闭
                window.WebViewJavascriptBridge.callHandler(
                  "closeTeachMaterial", // native中注册时的key值
                  null // native中注册方法中的传入的数据
                );
                router.push({
                  path: "/blank",
                });
              } else {
                // 是pc通知electron 有接口请求失败，当网络恢复需要刷新页面
                if (window.require) {
                  const { ipcRenderer } = window.require("electron");
                  logger.info("向electron发送接口请求失败");
                  // start-camera 必须在版本大于等于1.3.0的版本
                  const iscompare = compareVersion(
                    store.state.version,
                    "1.3.0"
                  );
                  if (iscompare >= 0) {
                    ipcRenderer.sendToHost("request-error"); // 向原生发送有接口请求失败信息
                  }
                }
              }
              reject(error);
              return;
            } else {
              return request(
                method,
                url,
                server,
                params,
                needToken,
                retryCount + 1
              );
            }
          }
          if (error.response.data && error.response.data.message) {
            message = error.response.data.message;
          } else {
            message = getErrorMessage(error.response.status);
          }
          if (!showErrorMessage) {
            reject(error);
            return;
          }
          if (!requestLockDevice) {
            requestLockDevice = true;
            logger.info("error", message);
            ElMessageBox({
              title: "系统提示",
              confirmButtonText: "确定",
              message,
              callback() {
                setTimeout(() => {
                  requestLockDevice = false;
                }, 3000);
                if (error.response.status === 401 && needToken) {
                  sessionStorage.clear();
                  // const url = window.location.href;
                  const device = navigator.userAgent;
                  const isAndroid =
                    device.indexOf("Android") > -1 ||
                    device.indexOf("Adr") > -1;
                  if (isAndroid) {
                    logger.info("我是安卓，我要给Android发消息");
                    ElMessage.error({
                      offset: 200,
                      message:
                        "用户登录信息已过期，系统3秒后返回登陆页，请重新登录！",
                      type: "error",
                    });
                    setTimeout(() => {
                      window.WebViewJavascriptBridge.callHandler(
                        "tokenTimeout", // native中注册时的key值
                        null // native中注册方法中的传入的数据
                      );
                    }, 3000);
                  } else {
                    logger.info("我是pc,我直接回登陆页");
                    toLoginUpdateData();
                    router.push({
                      path: "/login",
                      // query: {
                      //   redirect: url.slice(url.indexOf("#") + 1),
                      // },
                    });
                  }
                }
              },
            });
          }
        });
    } else {
      ElMessage.warning("网络异常，请稍后！");
    }
  });
};
const requestAppreciate = (
  method: string,
  url: string,
  params?: any,
  retryCount = 0
) => {
  // const SOFTWAREVERSION = "1.2.1";
  const headers = {
    "Content-Type": "application/json",
  };

  return new Promise((resolve, reject) => {
    axios({
      method,
      headers,
      url,
      baseURL: `${config["localResource"]}:${store.state.localResourcePort}`,
      timeout: 15000,
      params: method === "GET" ? params : null, // 是即将与请求一起发送的 URL 参数
      data:
        method === "POST" || method === "PUT" || method == "DELETE"
          ? params
          : null, // 是作为请求主体被发送的数据
    } as any)
      .then((res: any) => {
        resolve(res.data);
      })
      .catch((error: any) => {
        let message = "";
        logger.info("缓存服务请求失败", "次数:", retryCount, "错误", error);
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          // 重试3次打开缓存异常的弹窗
          message = error.response.data.message + error.response.data?.data;
        } else {
          if (retryCount > 3) {
            if (store.state.localResourceState) {
              // 重试3次打开缓存异常的弹窗
              store.commit(MutationNames.CHANGE_MODALCACHEERRSTATE, true);
            }
          } else {
            return requestAppreciate(method, url, params, retryCount + 1);
          }
          message = getErrorMessage(error?.response?.status);
        }
        reject(message ? new Error(message) : error);
      });
  });
};

const requestAppreciateWithoutRetry = (
  method: string,
  url: string,
  params?: any
) => {
  // const SOFTWAREVERSION = "1.2.1";
  const headers = {
    "Content-Type": "application/json",
  };

  return new Promise((resolve, reject) => {
    axios({
      method,
      headers,
      url,
      baseURL: `${config["localResource"]}:${store.state.localResourcePort}`,
      timeout: 15000,
      params: method === "GET" ? params : null, // 是即将与请求一起发送的 URL 参数
      data:
        method === "POST" || method === "PUT" || method == "DELETE"
          ? params
          : null, // 是作为请求主体被发送的数据
    } as any)
      .then((res: any) => {
        resolve(res.data);
      })
      .catch((error: any) => {
        let message = "";
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          message = error.response.data.message + error.response.data?.data;
        } else {
          message = getErrorMessage(error?.response?.status);
        }
        reject(message ? new Error(message) : error);
      });
  });
};

export {
  request,
  commonGetRequest,
  fetchGetRequest,
  requestDevice,
  requestAppreciate,
  requestAppreciateWithoutRetry,
};
