// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/images/logo/login-no-title-background.jpg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("@/assets/ml/mlLogin.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".login[data-v-4a38fc7c]{position:relative;width:100%;height:100vh}.login .login-top-logo[data-v-4a38fc7c]{position:absolute;top:150px;display:flex;align-items:center;justify-content:center;width:auto;width:100%;height:170px}.login .login-top-logo img[data-v-4a38fc7c],.login .school-logo[data-v-4a38fc7c]{-o-object-fit:contain;object-fit:contain}.login .school-logo[data-v-4a38fc7c]{max-height:170px}.login-bg[data-v-4a38fc7c]{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat 50%;background-size:100% 100%}.dvMLBJ[data-v-4a38fc7c]{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") no-repeat 50%;background-size:100% 100%}.app-info[data-v-4a38fc7c]{position:fixed;right:0;bottom:0;width:800px;height:80px;padding-right:10px;text-align:right;color:#fff;font-size:18px}.app-info .sp-set[data-v-4a38fc7c]{margin-right:8px;cursor:pointer;font-size:18px}.app-info .app-mac[data-v-4a38fc7c]{position:relative;display:flex;align-items:center;justify-content:flex-end}", ""]);
// Exports
module.exports = exports;
