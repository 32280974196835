import { defineComponent as _defineComponent } from 'vue'
import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-c87d66b6")
const _hoisted_1 = {
  key: 0,
  class: "mask-container"
}
const _hoisted_2 = { class: "mask-avatar" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "mask-info" }
const _hoisted_5 = { class: "mask-msg" }
const _hoisted_6 = ["src"]
_popScopeId()

import { ClassroomInterationNS } from "../../types";
import Point from "./PointDisplay.vue";
import {
  PropType,
  ref,
  computed,
  watch,
} from "vue";

export default _defineComponent({
  props: {
  maskInfo: {
    type: Object as PropType<ClassroomInterationNS.MaskInfoType>,
    default: () => ({}),
  },
},
  emits: ["onClose"] as unknown as undefined,
  setup(__props, { expose, emit }: { emit: ({
  (event: "onClose"): void;
}), expose: any, slots: any, attrs: any }) {

const props = __props
const audioRef = ref();
let audioUrlY = require("@/assets/mp3/addScore.mp3");
let audioUrlN = require("@/assets/mp3/decScore.wav");
// 得分样式
const pointStyle = {
  width: "57px",
  height: "36px",
  "line-height": "32px",
  "font-size": "25px",
};





const pointInfo = computed(() => {
  let point = props.maskInfo.isPraise
    ? {
        addPoint: props.maskInfo.point,
      }
    : {
        dockPoint: props.maskInfo.point,
      };
  return point;
});
const visible = ref<boolean>(false);
let audioUrl = ref();
// audioUrl.value = props.maskInfo.isPraise ? audioUrlY : audioUrlN;

// 显示蒙层并自动消失
const handleShowMask = (time = 2000) => {
  visible.value = true;
  // console.log("info", props.maskInfo);

  console.log("playAudioMask", audioUrl);
  console.log("audioRef", audioRef.value);

  if (!audioRef.value.paused) {
    audioRef.value.paused();
  }
  audioRef.value.load();
  audioRef.value.addEventListener("loadedmetadata", onLoaded);
  audioRef.value.addEventListener("canplay", onLoaded);
  function onLoaded() {
    audioRef.value.removeEventListener("loadedmetadata", onLoaded);
    audioRef.value.removeEventListener("canplay", onLoaded);

    // 播放音频
    audioRef.value.play();
    console.log("可以播放了");
    const key = setTimeout(() => {
      visible.value = false;
      emit("onClose");
      clearTimeout(key);
    }, time);
  }
};

expose({
  handleShowMask: handleShowMask,
});

// 兼容本地图片地址的url和网络地址的url
const getUrlInfo = (url: string): any => {
  const reg = /http[s]{0,1}:|data:*/gi;
  // 网络地址，直接用
  if (reg.test(url)) {
    return url;
  }
  if (url === "" || url.length < 1) {
    return;
  }
  const img = require("@/assets/img/classroomInteraction/stuAvatar/" + url);
  return img;
};

watch(
  () => props.maskInfo.isPraise,
  (newVal) => {
    console.log("检测到类型卡片变化", newVal);
    if (newVal) {
      audioUrl.value = audioUrlY;
      console.log("watch1", audioUrl);
    } else {
      audioUrl.value = audioUrlN;
      console.log("watch0", audioUrl);
    }
  },
  { immediate: true }
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (visible.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", {
            class: _normalizeClass(["mask-main", __props.maskInfo.isPraise ? 'praise' : 'no-praise'])
          }, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("img", {
                src: getUrlInfo(__props.maskInfo.avatar),
                alt: "",
                class: _normalizeClass(props.maskInfo.noBorder ? '' : 'imgborder')
              }, null, 10, _hoisted_3)
            ]),
            _createElementVNode("div", {
              class: _normalizeClass(["name-box", __props.maskInfo.isPraise ? 'praise-name' : 'no-praise-name'])
            }, [
              _createElementVNode("div", {
                class: _normalizeClass(["name-msg", __props.maskInfo.name.length >= 11 ? 'msgLong' : ''])
              }, _toDisplayString(__props.maskInfo.name), 3)
            ], 2)
          ], 2),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("span", _hoisted_5, _toDisplayString(__props.maskInfo.msg), 1),
            _createVNode(Point, {
              pointInfo: _unref(pointInfo),
              pointStyle: pointStyle
            }, null, 8, ["pointInfo"])
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("audio", {
      src: _unref(audioUrl),
      ref: audioRef,
      preload: "auto"
    }, null, 8, _hoisted_6)
  ], 64))
}
}

})