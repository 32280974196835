// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/img/padControlPanel/volume.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("@/assets/img/padControlPanel/mute.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".volume-btn[data-v-80f28c14]{display:flex;width:10.5rem;margin-left:2rem;justify-content:flex-start;align-items:center}.volume-btn .volume-icon[data-v-80f28c14]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}.volume-btn .no-volume-icon[data-v-80f28c14],.volume-btn .volume-icon[data-v-80f28c14]{background-size:2rem 2rem;width:3rem;height:2rem;margin-right:5px}.volume-btn .no-volume-icon[data-v-80f28c14]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")}.slider-wrapper[data-v-80f28c14]{position:relative;height:.3rem;width:12rem}.slider-wrapper .slider-line-wrapper[data-v-80f28c14]{position:absolute;top:0;width:100%;height:1.8rem;border-radius:.2rem;margin-left:.9rem;top:-.8rem;display:flex;justify-content:center;align-items:center}.slider-wrapper .slider-line-wrapper .slider-line[data-v-80f28c14]{width:100%;height:.3rem;background-color:#ffd298}.slider-wrapper .slider-btn[data-v-80f28c14]{border-radius:50%;width:1.8rem;height:1.8rem;background-color:#ff7f01;line-height:1.8rem;text-align:center;color:#fff;font-size:1.1rem;position:absolute;top:-.8rem}", ""]);
// Exports
module.exports = exports;
