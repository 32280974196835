import {
  requestAppreciate,
  requestAppreciateWithoutRetry,
} from "@/utils/request";
import logger from "@evideo/logger";
import store from "@/store";

// 测试缓存资源
console.log("store", store);

// const localResourceUrl = "http://127.0.0.1:" + store.state.localResourcePort;
const localResourceUrl = "http://localhost:" + store.state.localResourcePort;
console.log("localResourceUrl", localResourceUrl);

export async function getTest(params: any) {
  return requestAppreciate("POST", `/local-resource/cache-file/create`, params);
}
// 缓存资源健康检查
export async function localResourceHealth() {
  return requestAppreciate("GET", `/local-resource/health`);
}

// 健康检查，无自动重试版本
export async function localResourceHealthWithRetry() {
  return requestAppreciateWithoutRetry("GET", `/local-resource/health`);
}

// 获取缓存文件结果
export async function getCacheFile(params: any) {
  return requestAppreciate("POST", `/local-resource/cache-file`, params);
}
// 获取缓存文件结果
export async function uploadCacheFile(params: any) {
  return requestAppreciate("POST", `/local-resource/cache-file/create`, params);
}
// 创建缓存下载任务
export async function uploadCacheFileFormData(params: FormData) {
  return requestAppreciate("POST", `/local-resource/cache-file/create`, params);
}

export const queryAllResource = (params: any) =>
  requestAppreciate("GET", `/local-resource/cache-file/all`, params);

export const clearLocalResource = (params: any) =>
  requestAppreciate(
    "DELETE",
    `/local-resource/cache-file/batch-delete`,
    params
  );
// 清除下载失败文件
export const clearLocalFailedResource = () =>
  requestAppreciate(
    "DELETE",
    `/local-resource/cache-file/clean-error-download-file`
  );

export const changeLocalResourcePath = (params: any) =>
  requestAppreciate(
    "POST",
    `/local-resource/cache-file/change-cache-path`,
    params
  );
// 缓存服务切换环境
export const changeServerEnv = (params: any) =>
  requestAppreciate(
    "POST",
    `/local-resource/cache-file/change-server-env`,
    params
  );
// 重启缓存服务
export const clientReload = () =>
  requestAppreciate("POST", `/local-resource/cache-file/client-reload`);

// 获取所有需要更新的增量资源信息
export const queryAllIncrementalResource = async (params: any) => {
  return await requestAppreciate(
    "GET",
    `/local-resource/cache-file/get-all-incremental-resource`,
    params
  );
};

// 批量进行资源增量更新
export const updateIncrementalResource = async (params: any) => {
  return await requestAppreciate(
    "POST",
    `/local-resource/cache-file/batch-incremental-update`,
    params
  );
};

// 批量进行资源增量更新
export const setResourceSynchronousTypes = async (params: any) => {
  return await requestAppreciate(
    "POST",
    `/local-resource/cache-file/set-synchronous-types`,
    params
  );
};
