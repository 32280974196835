
import { computed, defineComponent, onMounted, ref, reactive } from "vue";
import { LoginType } from "../loginNext/type";
import { LoginTypes, LoginTypeMap, loginTypeNameMap } from "./dataMap";
import { useRouter } from "vue-router";
import _ from "lodash";
import useLogin from "@/hooks/useLoginNextNext";

import Account from "./components/Account.vue";
import WeChatLogin from "./components/WeChatLogin.vue";
import DingtalkLogin from "./components/DingtalkLogin.vue";
import ZdyLogin from "./components/ZdyLogin.vue";
import AuthorizedModal from "./components/AuthorizedModal.vue";

import { AuthorizedErrorObj } from "./type";
import logger from "@evideo/logger";
import PersonalProductBuyModal from "@/components/common/PersonalProductBuyModal.vue";
import { getTeacherBenefits } from "@/services/personalProduct";
import { useStore } from "vuex";
import { ElMessage } from "element-plus";
import { MutationNames } from "@/store";

export default defineComponent({
  props: {
    lastLoginType: { type: String, default: "" },
    changeSchoolLogo: Function,
  },
  components: {
    Account,
    ZdyLogin,
    WeChatLogin,
    DingtalkLogin,
    AuthorizedModal,
    PersonalProductBuyModal,
  },
  setup(props) {
    const router = useRouter();
    const {
      storeLoginType,
      getTeacherMe,
      beforeSignIn,
      jumpToHome,
      switchSchool,
      checkAuthorization,
      ErrorType,
    } = useLogin();

    const store = useStore();

    const personalPayState: any = reactive({
      showBuyPage: false,
      currentProduct: null,
      currentSchool: null,
    });

    const authorizeVisible = ref(false);
    const authorizeObj = ref<AuthorizedErrorObj>();
    const noRelateErrorObj = ref<AuthorizedErrorObj>();

    const otherLoginTypesVisible = ref(false);

    const currentLoginComponentRef = ref();
    const disable = ref(false);
    const currentLoginType = ref<LoginType>({
      type: "weChat",
      name: "微信登录",
    });
    const lastDingSchool =
      ref<{ school_id: number; school_name: string; school_logo: string }>();

    const otherLoginTypes = computed<LoginType[]>(() => {
      if (currentLoginType.value) {
        return LoginTypes.filter(
          (loginType: LoginType) =>
            loginType.type !== currentLoginType.value?.type
        );
      }
      return LoginTypes;
    });
    const currentLoginComponent = computed(() => {
      const elementTypeMap = {
        [LoginTypeMap.ACCOUNT]: Account,
        [LoginTypeMap.WE_CHAT]: WeChatLogin,
        [LoginTypeMap.DING_DING]: DingtalkLogin,
        [LoginTypeMap.ZDY]: ZdyLogin,
      };
      let _component: any = undefined;
      if (currentLoginType.value?.type) {
        _component = elementTypeMap[currentLoginType.value.type] || undefined;
      }
      logger.info("当前登录组件内容loginType:", !!_component);
      if (_component) {
        return _component;
      } else {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        currentLoginType.value = { type: "weChat", name: "微信登录" };
        return WeChatLogin;
      }
    });

    const handleChangeLoginType = (loginType: LoginType) => {
      if (disable.value) {
        return;
      }
      otherLoginTypesVisible.value = false;
      currentLoginType.value = loginType;
      storeLoginType(loginType);
      if (
        loginType.type === LoginTypeMap.DING_DING &&
        !lastDingSchool.value?.school_id
      ) {
        router.push("./chooseSchool");
      }

      props?.changeSchoolLogo && props.changeSchoolLogo(loginType?.type);
    };

    const handleReLoadLoginInfo = () => {
      switch (currentLoginType.value?.type) {
        case LoginTypeMap.WE_CHAT: {
          if (
            currentLoginComponentRef.value &&
            currentLoginComponentRef.value.reLoadWeChatQrCode
          ) {
            currentLoginComponentRef.value.reLoadWeChatQrCode();
          }
          return;
        }
        default:
          break;
      }
    };

    const closeLoginErrorModal = () => {
      noRelateErrorObj.value = { type: 0, message: "" };
      handleReLoadLoginInfo();
    };

    const showSwitchLoginError = (errObj: any) => {
      const { type, result } = errObj;
      switch (type) {
        case ErrorType.UNAUTHORIZED:
          authorizeVisible.value = true;
          authorizeObj.value = {
            type: result?.auth_result_type,
            message: result?.message || "",
          };
          break;
        case ErrorType.NO_RELATE_SCHOOL: {
          noRelateErrorObj.value = {
            type: 1,
            message: `您尚未关联学校，无法使用魔欢教学软件，请联系魔欢商务处理。`,
            teacherName: result?.teacherName || "",
          };
          break;
        }
        case ErrorType.NO_RELATE_SUBJECT: {
          noRelateErrorObj.value = {
            type: 1,
            message:
              "您尚未关联学科，无法使用魔欢教学软件，请联系魔欢商务处理。",
            teacherName: result?.teacherName || "",
          };
          break;
        }
        case ErrorType.EXPIRED: {
          noRelateErrorObj.value = {
            type: 3,
            message:
              "您在该学校的授权已过期，无法使用魔欢教学软件，请联系校方处理。",
            teacherName: result?.teacherName || "",
          };
          break;
        }
        case ErrorType.PERSONAL_PRODUCTION_EXPIRED: {
          noRelateErrorObj.value = {
            type: 2,
            message:
              "您在该学校的授权已过期，无法使用魔欢教学软件，请点击下方按钮续费。",
            teacherName: result?.teacherName || "",
          };
          break;
        }
        default:
          break;
      }
    };

    const initLoginCard = () => {
      store.commit(MutationNames.CHANGE_FIRSTLOADHOME, true);
      let lastLoginType = props.lastLoginType || "weChat";
      // 兼容1.5.1版本从接口获取的登录方式名称不是驼峰形式
      if (lastLoginType && loginTypeNameMap?.[lastLoginType]) {
        lastLoginType = loginTypeNameMap[lastLoginType];
      }

      logger.info("初始化登录方式loginType:", lastLoginType);
      if (lastLoginType) {
        const _lastLoginType = LoginTypes.find(
          (loginType: LoginType) => loginType.type === lastLoginType
        );
        if (_lastLoginType?.type) {
          currentLoginType.value = _lastLoginType;
        }
      }
      lastDingSchool.value = JSON.parse(
        localStorage.getItem("lastDingSchool") || "{}"
      );
      logger.info(
        "初始化当前登录方式loginType:",
        JSON.stringify(currentLoginType.value)
      );
      logger.info(
        "初始化钉钉登录记忆lastDingSchool:",
        JSON.stringify(lastDingSchool.value)
      );
    };
    const handleGotoChooseSchool = _.debounce(() => {
      if (disable.value) {
        return;
      }
      router.push("./chooseSchool");
    }, 100);
    const handleLogin = (token: string) => {
      return new Promise((resolve, reject) => {
        if (!token) {
          resolve("");
          return;
        }

        beforeSignIn({ token, loginType: currentLoginType.value });

        getTeacherMe({}).then((teacherInfo: any) => {
          const { schools, name, school_id } = teacherInfo;
          // 多校且不是钉钉登录
          if (
            schools &&
            schools.length > 1 &&
            currentLoginType.value?.type !== LoginTypeMap.DING_DING
          ) {
            router.push({
              path: "/schoolList",
              query: {
                schools: JSON.stringify(schools),
                _teacherName: name,
              },
            });
            resolve("");
            return;
          }
          // 钉钉登录 或者 仅关联一所学校
          if (
            (schools && schools.length === 1) ||
            currentLoginType.value?.type === LoginTypeMap.DING_DING
          ) {
            personalPayState.currentSchool = schools[0];

            switchSchool(school_id)
              .then(checkAuthorization)
              .then(() => getTeacherMe({ checkSubject: true, saveInfo: true }))
              .then((res: any) => {
                const { subjects } = res || {};
                jumpToHome(subjects, school_id);
                resolve("");
              })
              .catch((err: any) => {
                disable.value = false;
                otherLoginTypesVisible.value = false;
                if (err?.type) {
                  showSwitchLoginError(err);
                }
                reject(err);
              });
          }

          if (!schools || schools?.length === 0) {
            otherLoginTypesVisible.value = false;
            showSwitchLoginError({ type: ErrorType.NO_RELATE_SCHOOL });
            reject("");
          }
          disable.value = false;
        });
      });
    };
    const getTeacherBenefitsInfo = async () => {
      const res: any = await getTeacherBenefits();
      const schoolId = personalPayState?.currentSchool?.id || 0;
      personalPayState.currentProduct = res.data?.find(
        (i: any) => i.relation_school_id === schoolId
      );
    };
    const handlePay = async () => {
      await getTeacherBenefitsInfo();
      store.state.teacherBenefits = personalPayState.currentProduct ?? null;
      localStorage.setItem(
        "isPersonal",
        personalPayState.currentProduct ? "1" : "0"
      );
      personalPayState.showBuyPage = true;
      closeLoginErrorModal();
    };
    const handleJumpToHome = async () => {
      try {
        const { subjects } = await getTeacherMe({
          checkSubject: true,
          saveInfo: true,
        });
        await checkAuthorization(personalPayState.currentSchool?.id);
        jumpToHome(subjects, personalPayState.currentSchool?.id);
      } catch (e) {
        ElMessage.error("出现错误");
        console.error("handleJumpToHome", e);
      }
    };
    const handlePaySuccess = async () => {
      await getTeacherBenefitsInfo();
      ElMessage.success({
        type: "success",
        message: "正在跳转中，请稍后",
        offset: 100,
      });
      setTimeout(() => {
        // goToLogin();
        handleJumpToHome();
      }, 4000);
    };
    onMounted(() => {
      initLoginCard();
    });
    return {
      handlePaySuccess,
      personalPayState,
      handlePay,
      disable,
      LoginTypeMap,
      lastDingSchool,
      currentLoginType,
      otherLoginTypes,
      handleLogin,
      currentLoginComponent,
      currentLoginComponentRef,
      handleGotoChooseSchool,
      handleChangeLoginType,
      authorizeVisible,
      authorizeObj,
      noRelateErrorObj,
      otherLoginTypesVisible,
      closeLoginErrorModal,
    };
  },
});
