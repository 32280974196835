// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/img/login/reload.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".weChat-login[data-v-6c1e73c4]{width:222px;height:222px;display:flex;justify-content:center;align-items:center;box-shadow:0 0 20px rgba(0,0,0,.2);position:relative}.weChat-error[data-v-6c1e73c4]{position:absolute;left:0;top:0;width:100%;height:100%}.weChat-error img[data-v-6c1e73c4]{width:90px;border-radius:100%;border:10px solid #f5d9d9;background-color:#f5d9d9;margin-top:30px;margin-left:65px;margin-bottom:10px;cursor:pointer}.weChat-error div[data-v-6c1e73c4]{text-align:center;font-weight:700;color:#b77b35;font-size:20px;margin-top:5px}.weChat-loading[data-v-6c1e73c4]{position:absolute;left:0;top:0;width:100%;height:100%;flex-direction:column}.weChat-loading[data-v-6c1e73c4],.weChat-loading-icon-container[data-v-6c1e73c4]{display:flex;justify-content:center;align-items:center}.weChat-loading-icon-container[data-v-6c1e73c4]{width:90px;height:90px;border-radius:90px;border:10px solid #f5d9d9;background-color:#f5d9d9;transform:rotateY(180deg)}.weChat-loading-icon[data-v-6c1e73c4]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:100% 100%;width:70px;height:70px;display:block;-webkit-animation:goRotate-6c1e73c4 1s linear infinite;animation:goRotate-6c1e73c4 1s linear infinite}.weChat-loading-text[data-v-6c1e73c4]{text-align:center;font-weight:700;color:#b77b35;font-size:22px;margin-top:8px}@-webkit-keyframes goRotate-6c1e73c4{0%{transform:rotate(1turn)}to{transform:rotate(0deg)}}@keyframes goRotate-6c1e73c4{0%{transform:rotate(1turn)}to{transform:rotate(0deg)}}", ""]);
// Exports
module.exports = exports;
