import { message } from "ant-design-vue";
import { OrderValueEnum } from "./config";
import pinyin from "pinyin";

// 根据不同类型排序
export const handleSortStudentList = (list: any[], type: number) => {
  let result: any[] = [];
  switch (type) {
    case OrderValueEnum.FIRST_LETTER:
      result = sortStudentListByFirstLetter(list);
      break;
    case OrderValueEnum.TOTAL_SCORE:
      result = sortStudentListByTotalScore(list);
      break;
    case OrderValueEnum.PRAISE_SCORE:
      result = sortStudentListByPraiseScore(list);
      break;
    case OrderValueEnum.TO_BE_IMPROV:
      result = sortStudentListByToBeImprov(list);
      break;
    default:
      result = [...list];
  }
  return result;
};

// 分组根据不同类型排序
export const handleSortGroupList = (list: any[], type: number) => {
  let result: any[] = [];
  switch (type) {
    case OrderValueEnum.FIRST_LETTER:
      result = sortGroupListByFirstLetter(list);
      break;
    case OrderValueEnum.TOTAL_SCORE:
      result = sortGroupListByTotalScore(list);
      break;
    case OrderValueEnum.PRAISE_SCORE:
      result = sortGroupListByPraiseScore(list);
      break;
    case OrderValueEnum.TO_BE_IMPROV:
      result = sortGroupListByToBeImprov(list);
      break;
    default:
      result = [...list];
  }
  return result;
};

// 学生首字母排序
const sortStudentListByFirstLetter = (list: any[]) => {
  const tmp = [...list];
  const res: any[] = tmp.sort((a, b) => {
    return a?.py_name?.localeCompare(b?.py_name);
  });
  return res;
};

// 学生总分排序
const sortStudentListByTotalScore = (list: any[]) => {
  const tmp = [...list].map((i) => ({
    ...i,
    total_score: (i?.score?.add?.value || 0) - (i?.score?.reduce?.value || 0),
  }));
  const res = tmp.sort((a, b) => b.total_score - a.total_score);
  return res;
};

// 学生加分项排序
const sortStudentListByPraiseScore = (list: any[]) => {
  const tmp = [...list];
  const res = tmp.sort(
    (a, b) => (b?.score?.add?.value || 0) - (a?.score?.add?.value || 0)
  );
  return res;
};

// 学生扣分项排序
const sortStudentListByToBeImprov = (list: any[]) => {
  const tmp = [...list];
  const res = tmp.sort(
    (a, b) => (b?.score?.reduce?.value || 0) - (a?.score?.reduce?.value || 0)
  );
  return res;
};

// 分组首字母排序
const sortGroupListByFirstLetter = (list: any[]) => {
  list = list.map((i: any) => ({
    ...i,
    py_name: pinyin(i.name, {
      style: pinyin.STYLE_NORMAL,
      mode: pinyin.MODE_SURNAME,
    }).join(""),
  }));
  console.log("list11", list);
  const tmp = [...list];
  const res: any[] = tmp.sort((a, b) => {
    return a?.py_name?.localeCompare(b?.py_name);
  });
  return res;
};

// 分组总分排序
const sortGroupListByTotalScore = (list: any[]) => {
  const res = list.sort((a, b) => {
    return b.addScore + b.subtractScore - (a.addScore + a.subtractScore);
  });
  console.log("res", res);
  console.log("list", list);

  return res;
};

// 学生加分项排序
const sortGroupListByPraiseScore = (list: any[]) => {
  list.forEach((item, index) => {
    item.lesson_evaluate_total_score.forEach((val: any) => {
      if (val.norm_type === 1) {
        list[index].add = val.total_score;
      } else {
        list[index].reduce = val.total_score;
      }
    });
  });
  const res = list.sort((a, b) => b.add - a.add);
  console.log("res", res);
  console.log("list", list);

  return res;
};

// 学生扣分项排序
const sortGroupListByToBeImprov = (list: any[]) => {
  list.forEach((item, index) => {
    item.lesson_evaluate_total_score.forEach((val: any) => {
      if (val.norm_type === 1) {
        list[index].add = val.total_score;
      } else {
        list[index].reduce = val.total_score;
      }
    });
  });
  const res = list.sort((a, b) => Math.abs(b.reduce) - Math.abs(a.reduce));
  console.log("res", res);
  console.log("list", list);

  return res;
};

export const delayLoading = (time: number, content: string): (() => void) => {
  let hidden: any;
  const key = setTimeout(() => {
    hidden = message.loading(content, 60);
  }, time);
  const clearTime = () => {
    if (hidden) {
      hidden();
      hidden = undefined;
    }
    clearTimeout(key);
  };
  return clearTime;
};
