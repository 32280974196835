
import { computed, defineComponent, ref } from "vue";

import WebIframe from "@/components/WebIframe.vue";
import { createTokenByAuthCode, getOAuthLoginUrl } from "@/services/zdyLogin";
import { ElMessage } from "element-plus";

export default defineComponent({
  props: {
    disable: Boolean,
    handleLoginIn: Function,
  },
  components: { WebIframe },
  emits: ["update:disable"],
  setup(props, ctx) {
    (window as any).ZDY = (code: string) => {
      iframeBackVisible.value = false;
      setTimeout(() => {
        onWebIframeClose(code);
      }, 1500);
    };
    const zdyVisible = ref(false);
    const iframeBackVisible = ref(true);
    const zdyUrl = ref("");
    const isLogin = computed(() => props.disable);
    const goToZdyLoginView = () => {
      if (isLogin.value) {
        return;
      }
      return new Promise((resolve, reject) => {
        const env = process.env.RELEASE_ENV ? process.env.RELEASE_ENV : "devp";
        const callBackUrl = `https://${
          env === "prod" ? "" : env
        }multi-platform.teaching.evideo.tech/iframeTemp.html`;
        // const callBackUrl = "http://localhost:7071/iframeTemp.html";
        getOAuthLoginUrl({ callback_url: callBackUrl })
          .then((res: any) => {
            zdyVisible.value = true;
            iframeBackVisible.value = true;
            zdyUrl.value = res?.data?.oauth_login_url;
            resolve("");
          })
          .catch((err) => {
            console.error(err);
            reject("");
          });
      });
    };
    const onWebIframeClose = (code?: string) => {
      return new Promise((resolve, reject) => {
        console.log("onWebIframeClose:", code);
        zdyVisible.value = false;
        iframeBackVisible.value = true;
        if (code) {
          ctx.emit("update:disable", true);
          createTokenByAuthCode({ code })
            .then((res: any) => {
              const { access_token } = res?.teacher || {};
              props.handleLoginIn &&
                props.handleLoginIn(access_token).finally(() => {
                  ctx.emit("update:disable", false);
                  resolve("");
                });
            })
            .catch((err) => {
              console.error(err);
              ElMessage.error({
                type: "error",
                message: "登录失败",
                offset: 200,
              });
              ctx.emit("update:disable", false);
              reject("");
            });
        }
      });
    };
    return { goToZdyLoginView, isLogin, iframeBackVisible, zdyVisible, zdyUrl };
  },
});
