
import { computed, defineComponent } from "vue";
export default defineComponent({
  props: {
    visible: Boolean,
    type: Number,
    message: String,

    changeSchoolBtnVisible: {
      type: Boolean,
      default: true,
    },
    outBtnClick: Function,
    schoolBtnClick: Function,
  },
  emits: ["update:visible"],
  setup(props, ctx) {
    const isAuthorized = computed(() => props.visible);
    const authResultType = computed(() => props.type);
    const authResultMessage = computed(() => props.message);
    const schoolBtnVisible = computed(() => props.changeSchoolBtnVisible);

    const goToLogin = () => {
      props.outBtnClick && props.outBtnClick();
      ctx.emit("update:visible", false);
    };

    const closeAuthorized = () => {
      props.schoolBtnClick && props.schoolBtnClick();
      ctx.emit("update:visible", false);
    };

    return {
      isAuthorized,
      authResultType,
      authResultMessage,
      schoolBtnVisible,
      goToLogin,
      closeAuthorized,
    };
  },
});
