import { useStore } from "vuex";

import logger from "@evideo/logger";
import { useRouter } from "vue-router";
import { MutationNames } from "@/store";
import {
  checkPcTeachAuthorization,
  getCurrentActiveLesson,
  getSchoolPcSoftwareUpgradeInfo,
  getTeacherInfo,
  switchTeacherTokenSchool,
  uploadData,
} from "@/services";
import CryptoJS from "crypto-js";
import _ from "lodash";
import useCache from "./useCache";

interface SchoolInfo {
  schoolId: number;
  schoolName: string;
  schoolLogo: string;
}

interface School {
  id: number;
  name: string;
  logo: string;
}

interface TeacherInfo {
  id: number;
  nick_name: string;
}

interface SubjectInfo {
  subject: string;
  subject_name: string;
  relation_teaching_system: string;
}

interface TeacherInfoParams {
  schoolId?: number;
  schoolName?: string;
  checkSubject?: boolean;
  saveInfo?: boolean;
}

interface LoginInParams {
  token: string;
  schoolId?: number;
  schoolName?: string;
  schoolLogo?: string;
  loginType?: { type: string; name: string };
}

export default (afterLoginFunc?: () => void) => {
  const store = useStore();
  const router = useRouter();
  const { checkLocalResourceIncrementalUpdate } = useCache();

  const ErrorType = {
    NO_RELATE_SCHOOL: "no_relate_school",
    NO_RELATE_SUBJECT: "no_relate_subject",
    UNAUTHORIZED: "unauthorized",
    OTHER: "other",
    EXPIRED: "expired",
    PERSONAL_PRODUCTION_EXPIRED: "personal_production_expired",
  };

  // 十六位十六进制数作为密钥
  const SECRET_KEY = CryptoJS.enc.Utf8.parse("qFdZeGAm6UIhy6sl");
  // 十六位十六进制数作为密钥偏移量
  const SECRET_IV = CryptoJS.enc.Utf8.parse("lc9bpOIRClSsZVUo");
  // 用户id aes加密
  const encryptID = (Id: string) => {
    const dataHex = CryptoJS.enc.Utf8.parse(Id);
    const encrypted = CryptoJS.AES.encrypt(dataHex, SECRET_KEY, {
      iv: SECRET_IV,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
    const encryptionID = encrypted.ciphertext.toString(CryptoJS.enc.Base64);
    store.commit(MutationNames.CHANGE_PCUSERIDAES, encryptionID);
    return encrypted.ciphertext.toString();
  };

  /** 判断前往学科、还是前往首页
   * @params subjects 学科数组
   */
  const jumpToHome = (subjects: Subject[], schoolId?: number) => {
    if (!subjects || !schoolId) {
      return;
    }
    if (subjects.length > 1) {
      router.replace("/chooseCourse");
      return;
    }
    if (subjects.length === 1) {
      const { relation_teaching_system } = subjects[0];
      afterLogin(schoolId);
      router.replace(
        relation_teaching_system === "music" ? "/home" : "/artHome"
      );
      return;
    }
  };

  /** 保存老师信息 */
  const setTeacherInfo = (data: TeacherInfo) => {
    const { nick_name, id } = data || {};
    store.commit(MutationNames.CHANGE_NICKNAME, nick_name);
    store.commit(MutationNames.CHANGE_TEACHER_ID, id);
    // store.commit(MutationNames.CHANGE_FIRSTLOADHOME, true);

    const loginId = "teacher_" + data.id;
    logger.info("loginId:", loginId);
    encryptID(loginId);
  };

  /** 保存学校信息 */
  const storeSchoolInfo = (schoolInfo: SchoolInfo) => {
    const { schoolId, schoolName, schoolLogo } = schoolInfo || {};
    localStorage.setItem(
      "selectedSchool",
      JSON.stringify({
        schoolId,
        schoolName,
        schoolLogo,
      })
    );
    store.commit(MutationNames.CHANGE_SCHOOL_ID, schoolId);
    store.commit(MutationNames.CHANGE_SCHOOLNAME, schoolName);
    store.commit(MutationNames.CHANGE_SCHOOL_LOGO, schoolLogo);
  };

  /** 保存学科信息 */
  const storeSubjectInfo = (subjectInfo: SubjectInfo[]) => {
    localStorage.setItem("subjectSelectedRecord", JSON.stringify(subjectInfo));
    store.commit(MutationNames.STORE_SUBJECTDATA, subjectInfo);
    let lastSelectedSubject = JSON.parse(
      localStorage.getItem("subjectDataSelected") || "{}"
    );
    if (lastSelectedSubject) {
      const isExitSubject = subjectInfo.find(
        (subject: SubjectInfo) =>
          subject.subject === lastSelectedSubject.subject
      );
      if (!isExitSubject) {
        lastSelectedSubject = subjectInfo[0];
      }
    }
    localStorage.setItem(
      "subjectDataSelected",
      JSON.stringify(lastSelectedSubject)
    );
  };
  /** 保存登录方式 */
  const storeLoginType = (
    loginType: { type: string; name: string } | undefined,
    isSaveLocal = false
  ) => {
    logger.info(
      "保存选中登录方式selectedLoginType",
      isSaveLocal,
      JSON.stringify(loginType)
    );
    store.commit(MutationNames.CHANGE_LOGINTYPE, loginType?.type);
    if (isSaveLocal) {
      localStorage.setItem("selectedLoginType", JSON.stringify(loginType));
    }
  };

  const getMac = () => {
    if (!window.require) {
      return;
    }
    logger.info("versionmac", store.state.version);

    const { ipcRenderer } = window.require("electron");
    // const mac = ipcRenderer.sendSync("getMac") || "0:0:0:0";
    let mac = ipcRenderer.sendSync("getMac");
    const localMac = localStorage.getItem("mac");
    if (mac) {
      localStorage.setItem("mac", mac);
    } else if (localMac) {
      mac = localMac;
    } else {
      mac = "0:0:0:0";
    }
    logger.info("mac", mac.toUpperCase().replace(/:/g, ""));
    logger.info("local mac", localMac);
    store.commit(MutationNames.CHANGE_MAC, mac.toUpperCase().replace(/:/g, ""));
    logger.info("store.mac", store.state.mac);
  };

  const afterLogin = (schoolId: number | undefined) => {
    if (!schoolId) {
      return;
    }
    reportData(schoolId);
    afterLoginFunc && afterLoginFunc();
    // 清空答题游戏locaLStorage
    localStorage.setItem("answerGame", JSON.stringify({}));
    // pc端 检查该账号是否有可升级的安装包
    if (window.require) {
      if (store.state.hardwareModel) {
        getSchoolPcSoftwareUpgradeInfo({
          hardware_model: store.state.hardwareModel,
          // type:9代表教学软件升级10测评软件升级
          type: 9,
        }).then((res: any) => {
          if (res.file_url !== "") {
            const fileUrl = res.file_url;
            const { ipcRenderer } = window.require("electron");
            logger.info("向electron发送信息newVersion", fileUrl);
            ipcRenderer.sendToHost("newVersion", fileUrl, res.description); // 向原生发送升级信息
          }
        });
      }
    }

    // 本地资源增量更新检查
    if (_.gte(store.state.version, "1.4.3")) {
      logger.info("开始进行本地资源增量更新检查");
      checkLocalResourceIncrementalUpdate();
    } else {
      logger.info("版本低不进行本地资源增量更新检查");
    }
  };

  // 信息上报
  const reportData = (schoolId: number) => {
    let mac = store.state.mac;
    if (mac === "0:0:0:0") {
      mac = mac + "-" + schoolId;
      store.commit(MutationNames.CHANGE_MAC, mac);
    }
    uploadData({
      chip_id: mac,
      school_id: schoolId,
      mac: mac,
      hardware_model: store.state.hardwareModel,
      software: "pc_teach",
      software_version: store.state.version,
      firmware: "windows",
      use_type: "11",
      screen_orientation: "0",
    });
  };

  /** 学校鉴权 */
  const checkAuthorization = (schoolId: number | undefined) => {
    return new Promise((resolve, reject) => {
      if (!schoolId) {
        reject("");
        return;
      }
      const device = navigator.userAgent;
      const isAndroid =
        device.indexOf("Android") > -1 || device.indexOf("Adr") > -1;
      if (isAndroid) {
        logger.info("当前是安卓环境，不需要学校鉴权");
        return;
      }

      const isCompare = _.gte(store.state.version, "1.2.0");
      if (isCompare >= 0) {
        getMac();
      }
      let mac = store.state.mac;
      if (mac === "0:0:0:0") {
        mac = mac + "-" + store.state.schoolId;
      }
      const teacherInfo = store.state.teacherInfo;
      console.log("99999", teacherInfo);

      if (teacherInfo?.schools?.length === 1) {
        const currentSchool = teacherInfo.schools[0];
        if (
          currentSchool.is_personal_production &&
          currentSchool.expired_status
        ) {
          reject({ type: ErrorType.PERSONAL_PRODUCTION_EXPIRED });
        }
        if (currentSchool.expired_status) {
          reject({ type: ErrorType.EXPIRED });
        }
      }

      checkPcTeachAuthorization({
        school_id: schoolId,
        chip_id: mac,
      })
        .then((authorizationData: any) => {
          const { enable } = authorizationData || {};
          if (!enable) {
            reject({
              type: ErrorType.UNAUTHORIZED,
              result: authorizationData,
            });
          } else {
            resolve(schoolId);
          }
        })
        .catch((err: any) => reject(err));
    });
  };

  /** 重新绑定学校，更换token */
  const switchSchool = (schoolId: number | undefined) => {
    return new Promise<number | undefined>((resolve, reject) => {
      if (!schoolId) {
        reject("");
        return;
      }
      switchTeacherTokenSchool({ school_id: schoolId })
        .then((switchSchoolData: any) => {
          const { access_token } = switchSchoolData || {};
          localStorage.setItem("token", access_token);
          store.commit(MutationNames.CHANGE_TOKEN, access_token);
          if (window.require) {
            const { ipcRenderer } = window.require("electron");
            logger.info(
              "向electron发送信息token after change school",
              access_token
            );
            // get-token 必须在版本大于等于1.3.0的版本
            const isCompare = _.gte(store.state.version, "1.3.0");
            if (isCompare >= 0) {
              logger.info("开始向electron发送信息token", access_token);
              ipcRenderer.sendToHost("get-token", access_token); // 向原生发送token信息
            }
          }
          resolve(schoolId);
        })
        .catch((err: any) => {
          reject({ type: ErrorType.OTHER, result: err });
        });
    });
  };

  /**
   * 获取老师信息
   * @params schoolId 学校id
   * @params schoolName 学校名称
   * @params checkSubject 检查任职学科（默认false）
   */
  const getTeacherMe = (teacherInfoParams: TeacherInfoParams) => {
    const {
      schoolId,
      schoolName,
      checkSubject = false,
      saveInfo = false,
    } = teacherInfoParams || {};
    return new Promise<{
      schoolId: number | undefined;
      school_id: number | undefined;
      subjects: SubjectInfo[];
      schools: School[];
      nick_name: string;
      name: string;
    }>((resolve, reject) => {
      getTeacherInfo({})
        .then((teacherInfo: any) => {
          const {
            nick_name,
            id,
            schools,
            subject_data,
            school_id,
            school_logo,
            school_name,
            name,
          } = teacherInfo || {};

          store.commit(MutationNames.CHANGE_TEACHER_INFO, teacherInfo);

          setTeacherInfo({ id, nick_name });

          // 检查老师是否关联学校
          let currentSchool:
            | { id: number; name: string; logo: string }
            | undefined = undefined;
          if (schoolId) {
            if (schools && schools?.length > 0) {
              currentSchool = schools.find(
                (school: { id: number; name: string; logo: string }) =>
                  school.id === schoolId
              );
            }
            if (!currentSchool) {
              reject({
                type: ErrorType.NO_RELATE_SCHOOL,
                result: { teacherName: name, schoolName },
              });
              return;
            }
          }
          // 检查老师是否关联学科
          if (checkSubject) {
            if (!subject_data || subject_data?.length === 0) {
              reject({
                type: ErrorType.NO_RELATE_SUBJECT,
                result: { teacherName: name },
              });
              return;
            }
          }
          // 保存老师关联学校、学科信息
          if (saveInfo) {
            storeSchoolInfo({
              schoolId: school_id,
              schoolName: school_name,
              schoolLogo: school_logo,
            });
            storeSubjectInfo(subject_data);
            store.commit(MutationNames.CHANGE_ISLOGIN, true);
            getCurrentActiveLesson({}).then((data: any) => {
              if (data.current_active_lesson !== null) {
                store.commit(
                  MutationNames.CHANGE_LESSONRECORDID,
                  data.current_active_lesson.id
                );
                store.commit(
                  MutationNames.CHANGE_LESSONCLASSID,
                  data.current_active_lesson.class_id
                );
                store.commit(
                  MutationNames.CHANGE_SCHOOLCLASSID,
                  data.current_active_lesson.class_id
                );
                store.commit(MutationNames.CHANGE_LESSONSTART, false);
              } else {
                store.commit(MutationNames.CHANGE_SCHOOLCLASSID, "");
                store.commit(MutationNames.CHANGE_LESSONRECORDID, null);
                store.commit(MutationNames.CHANGE_LESSONCLASSID, null);
              }
            });
          }
          resolve({
            schoolId,
            school_id: school_id,
            subjects: subject_data,
            schools,
            nick_name,
            name,
          });
        })
        .catch((err: any) => reject({ type: ErrorType.OTHER, result: err }));
    });
  };

  /** 前往登录页面 可添加路由参数 */
  const goToLogin = (routerQuery: Partial<SchoolInfo>) => {
    router.replace({
      path: "/login",
      query: routerQuery,
    });
  };
  /** 账号登出 */
  const loginOut = () => {
    localStorage.setItem("token", "");
    store.commit(MutationNames.CHANGE_ISLOGIN, false);

    store.commit(MutationNames.CHANGE_TOKEN, "");

    store.commit(MutationNames.CHANGE_GRADENUMBER, "");
    store.commit(MutationNames.CHANGE_CLASSNUMBER, "");
    store.commit(MutationNames.CHANGE_CLASSNAME, "");
    store.commit(MutationNames.CHANGE_GRADENAME, "");
    store.commit(MutationNames.CHANGE_SCHOOLCLASSID, "");

    store.commit(MutationNames.CHANGE_NICKNAME, "");
    store.commit(MutationNames.CHANGE_TEACHER_ID, "");
    // store.commit(MutationNames.CHANGE_FIRSTLOADHOME, false);
    store.commit(MutationNames.CHANGE_PCUSERIDAES, "");

    store.commit(MutationNames.CHANGE_LESSONRECORDID, null);
    store.commit(MutationNames.CHANGE_LESSONCLASSID, null);
  };

  const beforeSignIn = (loginInData: LoginInParams) => {
    const { token, loginType } = loginInData || {};
    localStorage.setItem("token", token);

    store.commit(MutationNames.CHANGE_TOKEN, token);
    if (window.require) {
      const { ipcRenderer } = window.require("electron");
      logger.info("向electron发送信息token after change school", token);
      // get-token 必须在版本大于等于1.3.0的版本
      const isCompare = _.gte(store.state.version, "1.3.0");
      if (isCompare >= 0) {
        logger.info("开始向electron发送信息token", token);
        ipcRenderer.sendToHost("get-token", token); // 向原生发送token信息
      }
    }
    storeLoginType(loginType, true);
  };

  /**
   *  账号登入
   * @params token 必填
   * @params loginType 登录方式 必填
   * @params schoolId 学校id 选填
   * @params schoolName 学校id 选填*/
  const loginIn = (loginInData: LoginInParams) => {
    return new Promise((resolve, reject) => {
      const { token, schoolId, loginType, schoolName } = loginInData || {};
      logger.info("正在登录中:", schoolId, loginType, token);
      if (!token) {
        reject();
        return;
      }

      beforeSignIn({ token });

      getTeacherMe({ schoolId, schoolName })
        .then(({ schoolId }) => switchSchool(schoolId))
        .then(checkAuthorization)
        .then(() => getTeacherMe({ checkSubject: true, saveInfo: true }))
        .then(({ schoolId }) => afterLogin(schoolId))
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  };

  return {
    goToLogin,
    loginOut,
    ErrorType,
    jumpToHome,
    loginIn,
    getMac,
    getTeacherMe,
    switchSchool,
    checkAuthorization,
    afterLogin,
    beforeSignIn,
    storeLoginType,
    storeSchoolInfo,
  };
};
