import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives, normalizeStyle as _normalizeStyle, Teleport as _Teleport, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, Fragment as _Fragment, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-27b15224")
const _hoisted_1 = { class: "base-modal-title" }
const _hoisted_2 = { class: "base-modal-close-icon" }
_popScopeId()

import {
  PropType,
  VueElement,
  computed,
  CSSProperties,
} from "vue";

type GetContainerInterface = () => VueElement | HTMLElement;


export default _defineComponent({
  props: {
  // 弹窗是否可见
  visible: {
    type: Boolean as PropType<boolean>,
    defalut: false,
  },
  // 点击蒙层关闭
  maskClosable: {
    type: Boolean as PropType<boolean>,
    default: true,
  },
  // 标题
  title: {
    type: String as PropType<string>,
    default: "",
  },
  // 挂在的节点
  getContainer: {
    type: Function as PropType<GetContainerInterface>,
    default: () => document.body,
  },
  // 弹窗样式
  bodyStyle: {
    type: Object as PropType<CSSProperties>,
    default: undefined,
  },
  // 显示关闭按钮
  showClose: {
    type: Boolean as PropType<boolean>,
    default: true,
  },
  // 标题样式
  headerStyle: {
    type: Object as PropType<CSSProperties>,
    default: () => ({}),
  },
  // 显示header背景
  showHeaderBackground: {
    type: Boolean as PropType<boolean>,
    default: true,
  },
  // 显示蒙层
  showMask: {
    type: Boolean as PropType<boolean>,
    default: true,
  },
},
  emits: ["update:visible", "onClose"] as unknown as undefined,
  setup(__props, { emit }: { emit: ({
  (event: "update:visible", newVisible: boolean): void;
  (event: "onClose"): void;
}), expose: any, slots: any, attrs: any }) {

const props = __props




const defaultHeaderStyle = {
  backgroundColor: "#fff8da",
};

const visibleValue = computed(() => props.visible);
const headerStyleValue = computed(() => props.headerStyle);

const handleClose = () => {
  emit("update:visible", false);
  emit("onClose");
};

const handleClickMask = () => {
  if (props.maskClosable) {
    handleClose();
  }
};

const getDomContainer = () => {
  if (props.getContainer && props.getContainer()) {
    return props.getContainer();
  }
  return document.body;
};

return (_ctx: any,_cache: any) => {
  const _component_Close = _resolveComponent("Close")!
  const _component_el_icon = _resolveComponent("el-icon")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_unref(visibleValue))
      ? (_openBlock(), _createBlock(_Teleport, {
          key: 0,
          to: getDomContainer()
        }, [
          _createElementVNode("div", {
            class: "base-modal-container",
            style: _normalizeStyle(__props.bodyStyle ? __props.bodyStyle : {})
          }, [
            _createElementVNode("div", {
              class: "base-modal-header-wrapper",
              style: _normalizeStyle(
          __props.showHeaderBackground
            ? { ...defaultHeaderStyle, ..._unref(headerStyleValue) }
            : _unref(headerStyleValue)
        )
            }, [
              _createElementVNode("div", _hoisted_1, [
                _renderSlot(_ctx.$slots, "title", {}, () => [
                  _createTextVNode(_toDisplayString(__props.title), 1)
                ])
              ]),
              _withDirectives(_createElementVNode("div", _hoisted_2, [
                _createElementVNode("div", { onClick: handleClose }, [
                  _createVNode(_component_el_icon, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_Close)
                    ]),
                    _: 1
                  })
                ])
              ], 512), [
                [_vShow, __props.showClose]
              ])
            ], 4),
            _renderSlot(_ctx.$slots, "default")
          ], 4)
        ], 8, ["to"]))
      : _createCommentVNode("", true),
    (__props.showMask && _unref(visibleValue))
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          onClick: handleClickMask,
          class: "base-modal-mask"
        }))
      : _createCommentVNode("", true)
  ], 64))
}
}

})