import { request } from "@/utils/request";
import servers from "@/config/servers";

export async function getTeacherBenefits(params?: any) {
  return request(
    "GET",
    "/personalProduction/getTeacherBenefits",
    servers.user,
    params
  );
}

export async function getProductInfo(production_code: string) {
  return request("GET", `/personalProduction/getPackages`, servers.user, {
    production_code,
  });
}

export async function createPrepareOrder(package_code: string) {
  return request(
    "POST",
    `/personalProduction/createPrepareOrder`,
    servers.user,
    {
      package_code,
    }
  );
}

export async function getWechatPayQRCode(prepare_order_no: string) {
  return request(
    "GET",
    `/personalProduction/getWechatPayQRCode`,
    servers.user,
    {
      prepare_order_no,
    }
  );
}

export async function queryPrepareOrderStatus(prepare_order_no: string) {
  return request(
    "GET",
    `/personalProduction/queryPrepareOrderStatus`,
    servers.user,
    {
      prepare_order_no,
    }
  );
}
