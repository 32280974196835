import { request } from "@/utils/request";
import servers from "@/config/servers";

export async function getOAuthLoginUrl(params?: any) {
  return request("GET", "/zdy/getOAuthLoginUrl", servers.common, params);
}

export async function createTokenByAuthCode(params?: any) {
  return request("POST", "/zdy/createTokenByAuthCode", servers.common, params);
}
