import { deleteMicroAppMap, getMicroAppMap } from "@/main";
import store, { MutationNames } from "@/store/index";
// import Login from "@/views/login/Login.vue";
// import LoginNext from "@/views/loginNext/Login.vue";
import LoginNextNext from "@/views/loginNextNext/index.vue";
import logger from "@evideo/logger";
import { ElMessage } from "element-plus";
import { RouteRecordRaw, createRouter, createWebHistory } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    meta: {
      keepAlive: true,
    },
    name: "initializtionLogin",
    // component: Login,
    component: LoginNextNext,
  },
  {
    path: "/test",
    name: "Test",
    component: () =>
      import("../views/test/Test.vue").catch((err) => {
        logger.info("importError", err);
      }),
  },
  {
    path: "/blank",
    name: "Blank",
    component: () =>
      import("../views/blank/Blank.vue").catch((err) => {
        logger.info("importError", err);
      }),
  },
  {
    path: "/preLoad",
    name: "PreLoad",
    component: () =>
      import("../views/preLoad/PreLoad.vue").catch((err) => {
        logger.info("importError", err);
      }),
  },
  {
    path: "/login",
    name: "Login",
    component: () =>
      // import("../views/login/Login.vue").catch((err) => {
      //   logger.info("importError", err);
      // }),

      // import("../views/loginNext/Login.vue").catch((err) => {
      //   logger.info("importError", err);
      // }),
      import("../views/loginNextNext/index.vue").catch((err) => {
        logger.info("importError", err);
      }),
  },
  {
    path: "/chooseSchool",
    name: "ChooseSchool",
    component: () =>
      import("../views/loginNextNext/ChooseSchool.vue").catch((err) => {
        logger.info("importError", err);
      }),
  },
  {
    path: "/chooseCourse",
    name: "ChooseCourse",
    component: () =>
      // import("../views/login/ChooseCourse.vue").catch((err) => {
      //   logger.info("importError", err);
      // }),
      // import("../views/loginNext/ChooseCourse.vue").catch((err) => {
      //   logger.info("importError", err);
      // }),
      import("../views/loginNextNext/ChooseCourse.vue").catch((err) => {
        logger.info("importError", err);
      }),
  },
  {
    path: "/schoolList",
    name: "SchoolList",
    component: () =>
      import("../views/loginNextNext/SchoolList.vue").catch((err) => {
        logger.info("importError", err);
      }),
  },
  {
    path: "/random",
    name: "Random",
    component: () =>
      import("../views/random/Random.vue").catch((err) => {
        logger.info("importError", err);
      }),
  },
  {
    path: "/home",
    name: "MusicHome",
    component: () =>
      import("../views/musicHome/Home.vue").catch((err) => {
        logger.info("importError", err);
      }),
    beforeEnter: (to, from, next) => {
      const relationSystem = JSON.parse(
        localStorage.getItem("subjectDataSelected") || "{}"
      ).relation_teaching_system;
      if (relationSystem && relationSystem === "fine_art") {
        next({ name: "ArtHome" });
        return;
      }
      next();
    },
  },
  {
    path: "/artHome",
    name: "ArtHome",
    component: () =>
      import("../views/artHome/Home.vue").catch((err) => {
        logger.info("importError", err);
      }),
  },
  {
    path: "/musicKnowLedgeHome",
    name: "MusicKnowLedgeHome",
    component: () =>
      import("../views/musicKnowLedgeHome/MusicKnowLedgeHome.vue").catch(
        (err) => {
          logger.info("importError", err);
        }
      ),
  },
  {
    path: "/instruments",
    name: "Instruments",
    component: () =>
      import("../views/instruments/Instruments.vue").catch((err) => {
        logger.info("importError", err);
      }),
  },
  // {
  //   path: "/instruments/instrumentsDetail",
  //   name: "InstrumentsDetail",
  //   component: () =>
  //     import("../views/instruments/InstrumentsDetail.vue").catch((err) => {
  //       logger.info("importError", err);
  //     }),
  // },
  {
    path: "/musician",
    name: "Musician",
    component: () =>
      import("../views/musician/Musician.vue").catch((err) => {
        logger.info("importError", err);
      }),
  },
  // {
  //   path: "/musician/musicianDetail",
  //   name: "MusicianDetail",
  //   component: () =>
  //     import("../views/musician/MusicianDetail.vue").catch((err) => {
  //       logger.info("importError", err);
  //     }),
  // },
  {
    path: "/theory",
    name: "Theory",
    component: () =>
      import("../views/theory/Theory.vue").catch((err) => {
        logger.info("importError", err);
      }),
  },
  {
    path: "/teachingCenter",
    name: "TeachingCenter",
    component: () =>
      import("../views/teachingCenter/TeachingCenter.vue").catch((err) => {
        logger.info("importError", err);
      }),
  },
  {
    path: "/teachMaterial",
    name: "TeachMaterial",
    meta: {
      keepAlive: true,
    },
    component: () =>
      import("../views/teachMaterial/TeachMaterial.vue").catch((err) => {
        logger.info("importError", err);
      }),
  },
  {
    path: "/teachMaterialCartoon",
    name: "TeachMaterialCartoon",
    meta: {
      keepAlive: true,
    },
    component: () =>
      import("../views/teachMaterial/TeachMaterialCartoon.vue").catch((err) => {
        logger.info("importError", err);
      }),
  },
  {
    path: "/teachMaterialCartoon1",
    name: "TeachMaterialCartoon1",
    meta: {
      keepAlive: true,
    },
    component: () =>
      import(
        "../views/courseware/teachMaterialCartoon/TeachMaterialCartoon.vue"
      ).catch((err) => {
        logger.info("importError", err);
      }),
  },
  {
    path: "/materialPpt",
    name: "MaterialPpt",
    component: () =>
      import("../views/courseware/materialPpt/MaterialPpt.vue").catch((err) => {
        logger.info("importError", err);
      }),
  },
  {
    path: "/courseWare",
    name: "CourseWare",
    meta: {
      keepAlive: true,
    },
    component: () =>
      import("../views/myCourseWare/index.vue").catch((err) => {
        logger.info("importError", err);
      }),
  },
  {
    path: "/teachCourseWareCartoon",
    name: "TeachCourseWareCartoon",
    meta: {
      keepAlive: true,
    },
    component: () =>
      import(
        "../views/myCourseWare/teachCourseWareCartoon/TeachCourseWareCartoon.vue"
      ).catch((err) => {
        logger.info("importError", err);
      }),
  },
  {
    path: "/courseWarePpt",
    name: "CourseWarePpt",
    component: () =>
      import("../components/CourseWarePpt.vue").catch((err) => {
        logger.info("importError", err);
      }),
  },
  {
    path: "/coursewareCenter",
    name: "CoursewareCenter",
    meta: {
      keepAlive: true,
    },
    component: () =>
      import("../views/courseWareCenter/CourseWareCenter.vue").catch((err) => {
        logger.info("importError", err);
      }),
  },
  {
    path: "/coursewareCenterCartoon",
    name: "CoursewareCenterCartoon",
    meta: {
      keepAlive: true,
    },
    component: () =>
      import("../views/courseWareCenter/CourseWareCenterCartoon.vue").catch(
        (err) => {
          logger.info("importError", err);
        }
      ),
  },
  {
    path: "/musicRhythm",
    name: "MusicRhythm",
    // meta: {
    //   keepAlive: true,
    // },
    component: () => import("../views/musicRhythm/index.vue"),
  },
  {
    path: "/musicRhythm/detail/:id",
    name: "MusicRhythmDetail",
    meta: {
      keepAlive: true,
    },
    component: () => import("../views/musicRhythm/Detail.vue"),
  },
  {
    path: "/pptview",
    name: "pptview",
    component: () =>
      import("../views/ppt/index.vue").catch((err) => {
        logger.info("importError", err);
      }),
  },
  {
    path: "/gameCenter",
    name: "gameCenter",
    component: () =>
      import("../views/gameCenter/index.vue").catch((err) => {
        logger.info("importError", err);
      }),
  },
  {
    path: "/gameCenter/pacePracticeGame",
    name: "pacePracticeGame",
    component: () =>
      import("../views/gameCenter/pacePracticeGame/PacePracticeGame.vue").catch(
        (err) => {
          logger.info("importError", err);
        }
      ),
  },
  {
    path: "/gameCenter/auditionGame",
    name: "auditionGame",
    component: () =>
      import("../views/gameCenter/auditionGame/AuditionGame.vue").catch(
        (err) => {
          logger.info("importError", err);
        }
      ),
  },
  {
    path: "/gameCenter/answerGame",
    name: "answerGame",
    component: () =>
      import("../views/gameCenter/answerGame/AnswerGame.vue").catch((err) => {
        logger.info("importError", err);
      }),
  },
  {
    path: "/appreciateHome",
    name: "AppreciateHome",
    component: () =>
      import("../views/appreciate/appreciateHome.vue").catch((err) => {
        logger.info("importError", err);
      }),
  },
  {
    path: "/appreciationResources",
    name: "AppreciationResources",
    meta: {
      keepAlive: true,
    },
    component: () =>
      import("../views/appreciate/AppreciationResources.vue").catch((err) => {
        logger.info("importError", err);
      }),
  },
  {
    path: "/dingtalk/callback",
    name: "DingtalkCallback",
    meta: {
      keepAlive: true,
    },
    component: () =>
      import("../views/login/DingtalkCallback.vue").catch((err) => {
        logger.info("importError", err);
      }),
  },
  {
    path: "/coursewareLibrary",
    name: "CoursewareLibrary",
    meta: {
      keepAlive: true,
    },
    component: () => import("../views/coursewareLibrary/index.vue"),
  },
  {
    path: "/fastClassWork",
    name: "FastClassWork",
    component: () =>
      import("../views/fastClassWork/index.vue").catch((err) => {
        logger.info("importError", err);
      }),
  },
  {
    path: "/fastClassWork/review",
    name: "FastClassWorkReview",
    component: () =>
      import("../views/fastClassWork/review.vue").catch((err) => {
        logger.info("importError", err);
      }),
  },
  {
    path: "/cbCenter",
    name: "cbCenter",
    component: () =>
      import("../views/cb/index.vue").catch((err) => {
        logger.info("importError", err);
      }),
  },
];
// 保存原来的push函数
// const routerPush = createRouter.prototype.push;
// // 重写push函数
// createRouter.prototype.push = function push(location) {
//   // 这个if语句在跳转相同路径的时候，在路径末尾添加新参数（一些随机数字）
//   // 用来触发watch
//   if (typeof location == "string") {
//     let Separator = "&";
//     if (location.indexOf("?") == -1) {
//       Separator = "?";
//     }
//     location = location + Separator + "random=" + Math.random();
//   }

//   // 这个语句用来解决报错
//   // 调用原来的push函数，并捕获异常
//   return routerPush.call(this, location).catch((error) => error);
// };
// 快捷跳转状态重置
const resetMinimizeState = () => {
  store.commit(MutationNames.CHANGE_MINIMIZEBTNSTATE, "false");
  store.commit(MutationNames.CHANGE_MINIMIZEURL, "");
  store.commit(MutationNames.CHANGE_MINIMIZEPPTINFO, "");
  store.commit(MutationNames.CHANGE_PPTTREEID, "");
  store.commit(MutationNames.CHANGE_MORERESOURCEDATA, "");
  store.commit(MutationNames.CHANGE_MORERESOURCECLICKDATA, "");
  store.commit(MutationNames.CHANGE_MOREPPTINFO, "");
  store.commit(MutationNames.CHANGE_MOREPPTSHOW, false);
  localStorage.removeItem("token");
  if (window?.require) {
    const { ipcRenderer } = window.require("electron");
    ipcRenderer.sendToHost("remove-token");
  }
};
const router = createRouter({
  history: createWebHistory(),
  routes,
});
const originalPush = router.push;
logger.info("originalPush", originalPush);
router.push = function push(location: any) {
  logger.info("originalPushlocation", location);
  return originalPush
    .call(this, location)
    .catch((err: any) => logger.info("error", err));
};
router.beforeEach(async (to, from, next) => {
  logger.info("路由守卫，路由要跳转了", to, from, next);
  const microAppMap = getMicroAppMap();
  logger.info("路由map", microAppMap);
  const app = microAppMap.get("teachPptPC");
  logger.info("路由app", app);
  if (to.fullPath === "/login") {
    resetMinimizeState();
  }

  if (app) {
    if (app.preUnmount) {
      logger.info("我是路由守卫要卸载了ppt");
      await app.preUnmount();
    }
    logger.info("appgetStatus", app.getStatus());

    // if (app.getStatus() === "MOUNTED") {
    logger.info("进来开始卸载ppt");
    app.unmount();
    await app.unmountPromise; // 等待卸载完成
    deleteMicroAppMap("teachPptPC");
    // }
  }
  if (store.state.onlineState) {
    logger.info("网络正常");
    next();
  } else {
    ElMessage.warning("网络异常，请稍后！");
  }
});

export default router;
