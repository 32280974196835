import servers from "./servers";

export default {
  [servers.user]: "https://preuser-api.ecoach.evideo.tech",
  [servers.device]: "https://preapi.ecoach.evideo.tech",
  [servers.platform]: "https://preplatform-api.ecoach.evideo.tech",
  [servers.pptist]: "https://preppt.ecoach.evideo.tech",
  [servers.ecoach]: "https://premusic-score.ecoach.evideo.tech",
  [servers.resource]: "https://preresource-center.ecoach.evideo.tech",
  [servers.ec]: "https://preec.ecoach.evideo.tech/stat",
  [servers.qrcode]: "https://pregateway.ecoach.evideo.tech/api/common-service/",
  [servers.kefuQrcode]:
    "https://pregateway.ecoach.evideo.tech/api/common-service/",
  [servers.authorization]: "https://preapi.ecoach.evideo.tech",
  [servers.musicGame]: "https://preinteractive-teach.ecoach.evideo.tech",
  [servers.common]: "https://pregateway.ecoach.evideo.tech/api/common-service/",
  [servers.tools]: "https://preh5-tools.ecoach.evideo.tech",
  [servers.exam]: "https://preexam-center-service.ecoach.evideo.tech",
  [servers.localResource]: "http://127.0.0.1",
  [servers.dingtalkCallback]:
    "https://premulti-platform.teaching.evideo.tech/login",
  [servers.school]: "https://preschool.ecoach.evideo.tech",
  [servers.deviceControlService]: "http://127.0.0.1",
  [servers.cloud]: "https://precloud-pre-render-service.ecoach.evideo.tech",
  [servers.app]: "https://premulti-platform.teaching.evideo.tech",
  [servers.api]: "https://preapi.ecoach.evideo.tech/",
};
