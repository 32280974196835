import servers from "./servers";

export default {
  [servers.user]: "https://devpuser-api.ecoach.evideo.tech",
  [servers.device]: "https://devpapi.ecoach.evideo.tech",
  [servers.platform]: "https://devpplatform-api.ecoach.evideo.tech",
  [servers.pptist]: "https://devpppt.ecoach.evideo.tech",
  [servers.ecoach]: "https://devpmusic-score.ecoach.evideo.tech",
  [servers.resource]: "https://devpresource-center.ecoach.evideo.tech",
  [servers.ec]: "https://devpec.ecoach.evideo.tech/stat",
  [servers.qrcode]:
    "https://devpgateway.ecoach.evideo.tech/api/common-service/",
  [servers.kefuQrcode]:
    "https://devpgateway.ecoach.evideo.tech/api/common-service/",
  [servers.authorization]: "https://devpapi.ecoach.evideo.tech",
  [servers.musicGame]: "https://devpinteractive-teach.ecoach.evideo.tech",
  [servers.common]:
    "https://devpgateway.ecoach.evideo.tech/api/common-service/",
  [servers.tools]: "https://devph5-tools.ecoach.evideo.tech",
  [servers.localResource]: "http://127.0.0.1",
  [servers.exam]: "https://devpexam-center-service.ecoach.evideo.tech",
  [servers.dingtalkCallback]:
    "https://devpmulti-platform.teaching.evideo.tech/login",
  [servers.school]: "https://devpschool.ecoach.evideo.tech",
  [servers.deviceControlService]: "http://127.0.0.1",
  [servers.cloud]: "https://devpcloud-pre-render-service.ecoach.evideo.tech",
  [servers.app]: "https://devpmulti-platform.teaching.evideo.tech",
  [servers.api]: "https://devpapi.ecoach.evideo.tech/",
};
