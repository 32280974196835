import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-49fe7c4a")
const _hoisted_1 = { class: "appraise-box-wrapper" }
const _hoisted_2 = { class: "box-img" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "box-count" }
const _hoisted_5 = { class: "box-info" }
_popScopeId()

import Point from "@/components/classroomInteraction/components/base/PointDisplay.vue";
import { ref } from "vue";


export default _defineComponent({
  props: {
  appraiseInfo: {
    type: Object,
    default: () => ({}),
  },
  pointInfo: {
    type: Object,
    default: () => ({}),
  },
},
  setup(__props) {

const props = __props
const pointStyle = {
  width: "41px",
  height: "26px",
};



// 兼容本地图片地址的url和网络地址的url
const getUrlInfo = (url: string): any => {
  const reg = /http[s]{0,1}:/gi;
  // 网络地址，直接用
  if (reg.test(url)) {
    return url;
  }
  const img = require("@/assets/img/classroomInteraction/praiseAppraise/" +
    url);
  return img;
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        class: "box-img-content",
        src: getUrlInfo(props.appraiseInfo.img),
        alt: ""
      }, null, 8, _hoisted_3)
    ]),
    _createElementVNode("span", _hoisted_4, [
      _createVNode(Point, {
        "point-info": __props.appraiseInfo,
        pointStyle: pointStyle
      }, null, 8, ["point-info"])
    ]),
    _createElementVNode("div", _hoisted_5, _toDisplayString(props.appraiseInfo.title), 1)
  ]))
}
}

})