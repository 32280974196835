import moment from "moment";

import { dataEmbeddingPoint } from "@/services";
import store from "@/store/index";

interface StatData {
  uid: string;
  ct: string;
  ci: string;
  mac: string;
  sv: string;
  fv: string;
  pt: string;
  pageid: string;
  moduleid: string;
  ca: string;
  eventid: string;
  eventattr: any;
}

interface StatInData {
  pageid?: string;
  moduleid?: string;
  ca?: string;
  eventid?: string;
  eventattr?: any;
}

const emptyStatConfig: StatData = {
  uid: "",
  ct: "",
  ci: "",
  mac: "",
  sv: "",
  fv: "",
  pt: "",
  pageid: "",
  moduleid: "",
  ca: "",
  eventid: "",
  eventattr: "",
};

const baseStatConfig: StatData = {
  ...emptyStatConfig,
};

const resetStatConfig = () => {
  Object.assign(baseStatConfig, emptyStatConfig);
};

const setStatConfig = (otherBaseConfig: any = {}) => {
  resetStatConfig();
  Object.assign(baseStatConfig, {
    uid: store.state.pcUserIdAES,
    ct: "96",
    ci: store.state.mac,
    mac: store.state.mac,
    sv: store.state.version,
    fv: store.state.hardwareModel,
    pt: "pc",
    ...otherBaseConfig,
  });
  console.log("baseStatConfig:", baseStatConfig);
};

const registerElectronListenStatReport = () => {
  if (window.require) {
    const { ipcRenderer } = window.require("electron");
    console.log("注册electron监听");
    ipcRenderer.on("stat-data-report", (event: string, message: any) => {
      console.log("监听到来自electron需要数据埋点事件", event, message);
      if (checkExitEvent(message?.eventid)) {
        reportStat(message);
      }
    });
  }
};

const reportStat = (data: StatInData) => {
  return new Promise((resolve, reject) => {
    setStatConfig();
    const ca = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
    // pc数据上报
    const reportData: StatData = {
      ...baseStatConfig,
      ca,
      ...data,
    };
    console.log("reportData:", reportData);
    const body = encodeURIComponent(JSON.stringify(reportData));
    dataEmbeddingPoint(body)
      .then(() => resolve(""))
      .catch(() => reject(""));
  });
};

export const StatEvent = {
  DRAWING_PENCIL_OPEN: "drawingPencil_open",
  CLASS_COMMUNICATION_OPEN: "classCommunication_open",
  CLASS_COMMUNICATION_STUDENT_EVALUATION:
    "classCommunication_studentEvaluation",
  ROLL_CALL_OPEN: "rollCall_open",
  METRONOME_OPEN: "metronome_open",
  NET_CHECK_OPEN: "netCheck_open",

  TEACHER_LOGIN: "teacher_login",
};

const checkExitEvent = (event: string) => {
  return Object.values(StatEvent).includes(event);
};

export { setStatConfig, reportStat, registerElectronListenStatReport };
