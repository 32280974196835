// electron相关的交互事件接口
import store, { MutationNames } from "@/store/index";
import router from "./router";
import { useRoute } from "vue-router";
import { Ref, createVNode, render } from "vue";
import Random from "@/views/random/Random.vue";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { eventCenter, usePageTableContext } from "@evideo/frontend-utils";
// import { eventCenter } from "@evideo/frontend-utils";
import { ElMessage } from "element-plus";
// import { getMicroAppMap, deleteMicroAppMap } from "@/main";
import moment from "moment";
import { dataEmbeddingPoint } from "@/services";
import pageTableContextMap from "@/utils/page-table-context-map";
import logger from "@evideo/logger";

const { deleteContextInfo } = usePageTableContext();
// const route = useRoute();

logger.info("router", router);
// setTimeout(() => {
//   logger.info("route", useRoute());
// }, 200);

const EVENT_NAMES = {
  REGISTED_EVENTS: "registed-events",
  START_FLASH_PLAYER: "start-flash-player",
  RANDOM_STATE: "random-state",
  IS_ONLINE_STATE: "is-online-state",
  GO_TO_LOGIN: "go-to-login",
  PC_MINIMIZE: "pc-minimize",
  GO_TO_SHORT_CUT: "goToShortCut",
  CLASS_COMMUNCATION: "class-communication",
  CANCEL_COMMUNICATION: "cancel-Communication",
  UPDATE_PAD_CONTROL_STATUS: "update-pad-control-status",
};
const compareVersion = (v1: any, v2: any) => {
  // -1|v2大于v1；0|相同；1|v2小于v1
  v1 = v1.split(".");
  v2 = v2.split(".");
  const len = Math.max(v1.length, v2.length);

  while (v1.length < len) {
    v1.push("0");
  }
  while (v2.length < len) {
    v2.push("0");
  }

  for (let i = 0; i < len; i++) {
    const num1 = parseInt(v1[i]);
    const num2 = parseInt(v2[i]);

    if (num1 > num2) {
      return 1;
    } else if (num1 < num2) {
      return -1;
    }
  }

  return 0;
};

export const checkElectronIpcRenderer = () => {
  const { ipcRenderer } = window?.require("electron");
  if (!ipcRenderer) {
    return false;
  }
  return true;
};

export const registerH5EventList = () => {
  if (window && window.require) {
    const { ipcRenderer } = window.require("electron");

    if (!ipcRenderer) {
      return;
    }
    ipcRenderer.on(EVENT_NAMES.REGISTED_EVENTS, (events: any, args: any) => {
      try {
        const list = args;
        logger.info("接受到事件列表", list);
        if (list) {
          store.commit(
            MutationNames.CHANGE_CARRIER_APPLICATION_REGISTED_EVENTS,
            list
          );
        }
      } catch (error) {
        logger.info("error", error);
      }
    });
  }
};

export const startFlashPlayer = (courseWare: any) => {
  const { ipcRenderer } = window?.require("electron");
  try {
    ipcRenderer.sendToHost(EVENT_NAMES.START_FLASH_PLAYER, courseWare);
  } catch (error) {
    logger.info(error);
    throw error;
  }
};
// 接收课堂互动
export const classCommunication = (status: Ref) => {
  if (window && window.require) {
    const { ipcRenderer } = window.require("electron");
    if (!ipcRenderer) {
      return;
    }
    ipcRenderer.on(EVENT_NAMES.CLASS_COMMUNCATION, (events: any, args: any) => {
      try {
        const state = args;
        console.log(state);
        status.value = state;
      } catch (error) {
        logger.info(error);
        throw error;
      }
    });
  }
};
// 当点击X时将窗口状态传递给electron
export const cancelCommunication = (status: any) => {
  const { ipcRenderer } = window?.require("electron");
  try {
    ipcRenderer.sendToHost(EVENT_NAMES.CANCEL_COMMUNICATION, status);
  } catch (error) {
    logger.info(error);
    throw error;
  }
};

export const randomRouter = () => {
  if (window && window.require) {
    const { ipcRenderer } = window.require("electron");

    if (!ipcRenderer) {
      return;
    }
    ipcRenderer.on(EVENT_NAMES.RANDOM_STATE, (events: any, args: any) => {
      try {
        const state = args;
        // const uid = router.currentRoute.value.params.uid;
        const pathname = window.location.pathname;
        logger.info("pathname", pathname);

        if (state) {
          const body = document.querySelector("body");
          const divRandom = document.createElement("div");
          divRandom.id = "newRandom";
          const randomVNode = createVNode(Random);
          if (body && body.append) {
            render(randomVNode, divRandom);
            body.append(divRandom);
          }
          // router.push({
          //   path: "/random",
          // });
        } else {
          const randomSetIntervalId = store.state.randomSetIntervalId;
          const getNewRandom = document.getElementById("newRandom");
          if (getNewRandom) {
            clearInterval(randomSetIntervalId);
            getNewRandom.remove();
          }

          // history.back();
        }
      } catch (error) {
        logger.info("errortest", error);
      }
    });
  }
};
let reloadLock = false;
export const getIsOnlineState = () => {
  const id = Date.now();
  if (window && window.require) {
    const { ipcRenderer } = window.require("electron");

    if (!ipcRenderer) {
      return;
    }
    ipcRenderer.on(EVENT_NAMES.IS_ONLINE_STATE, (events: any, args: any) => {
      try {
        // get-token 必须在版本大于等于1.3.0的版本

        const iscompare = compareVersion(store.state.version, "1.3.0");
        if (iscompare >= 0) {
          const state = args;
          const fullPath = router.currentRoute.value.fullPath;
          const query = router.currentRoute.value.query;
          const currentTime = new Date().getTime();
          store.commit(MutationNames.CHANGE_ONLINESTATE, state.isOnlineState);
          // isRequestError true|没有发送请求所以不用reload false|有发送请求，请求失败所以需要刷新页面
          if (!args.isRequestError && state.isOnlineState) {
            if (!reloadLock) {
              reloadLock = true;
              router.replace({
                path: fullPath,
                query: {
                  ...query,
                  _: currentTime,
                },
                force: true,
              });
              setTimeout(() => {
                reloadLock = false;
              }, 2000);
            }
          }
        } else {
          const state = args;
          logger.info("state", state);
          logger.info("events", events);

          store.commit(MutationNames.CHANGE_ONLINESTATE, state);
        }
      } catch (error) {
        logger.info("errortest", error);
      }
    });
  }
};
export const goToLogin = () => {
  if (window && window.require) {
    const { ipcRenderer } = window.require("electron");

    if (!ipcRenderer) {
      return;
    }
    ipcRenderer.on(EVENT_NAMES.GO_TO_LOGIN, (events: any, args: any) => {
      try {
        const state = args;
        logger.info("GO_TO_LOGIN", state, events);
        // 重制上下课状态
        store.commit(MutationNames.CHANGE_LESSONSTART, true);
        store.commit(MutationNames.CHANGE_LESSONCLASSID, 0);
        store.commit(MutationNames.CHANGE_GRADENUMBER, "");
        store.commit(MutationNames.CHANGE_CLASSNUMBER, "");
        router.push({
          path: "/login",
        });
      } catch (error) {
        logger.info("errortest", error);
      }
    });
  }
};
export const sendDiary = (data: any, type: string) => {
  const typeList = store.state.diaryTypeList;
  typeList.forEach((item: string) => {
    if (item === type) {
      const { ipcRenderer } = window.require("electron");
      ipcRenderer.sendToHost("sendWebviewDiary", data, type);
    } else {
      logger.info(item, type);
    }
  });
};

export const registerH5Minimize = () => {
  if (window && window.require) {
    const { ipcRenderer } = window.require("electron");

    if (!ipcRenderer) {
      return;
    }
    ipcRenderer.on(EVENT_NAMES.PC_MINIMIZE, (events: any, args: any) => {
      try {
        const list = args;
        // eventCenter.send("PPT_GET_STATUS", {}, (res: any) => {
        //   const CurrentRoute = router.currentRoute.value.fullPath;
        //   logger.info("CurrentRoute", CurrentRoute);
        //   logger.info("最小化res", res);
        // });
        // 显示课件按钮
        store.commit(MutationNames.CHANGE_MINIMIZEBTNSTATE, true);
        logger.info(
          "store.state.minimizeBtnState",
          store.state.minimizeBtnState
        );
      } catch (error) {
        logger.info("最小化更新事件列表数据失败");
      }
    });
  }
};
const embeddingPointShortCut = () => {
  const ca = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
  const data = {
    uid: store.state.pcUserIdAES,
    ct: "96",
    ci: store.state.mac,
    mac: store.state.mac,
    sv: store.state.version,
    fv: store.state.hardwareModel,
    ca: ca,
    pt: "pc",
    eventid: "ppt_minimize",
    pageid: "ppt_page",
    moduleid: "ppt",
    eventattr: "",
  };
  const body = encodeURIComponent(JSON.stringify(data));
  dataEmbeddingPoint(body);
};
export const goToShortCut = () => {
  const route = useRoute();

  logger.info("route", route);
  if (window && window.require) {
    const { ipcRenderer } = window.require("electron");

    if (!ipcRenderer) {
      return;
    }
    ipcRenderer.on(
      EVENT_NAMES.GO_TO_SHORT_CUT,
      async (events: any, args: any) => {
        try {
          // 调用最小化数据埋点
          embeddingPointShortCut();
          const path = args;
          logger.info("最小化接受到事件列表", path);
          logger.info("最小化接受到事件列表route.path", route.path);
          if (
            route.path == "/" ||
            route.path == "/login" ||
            route.path == "/preLoad" ||
            route.path == "/chooseSchool"
          ) {
            ElMessage.warning({
              offset: 200,
              message: "请先登录!",
              type: "warning",
            });
          } else if (route.path == "/chooseCourse") {
            ElMessage.warning({
              offset: 200,
              message: "请先选择学科!",
              type: "warning",
            });
          } else if (route.path == "/schoolList") {
            ElMessage.warning({
              offset: 200,
              message: "请先选择学校!",
              type: "warning",
            });
          } else {
            const CurrentRoute = router.currentRoute.value.fullPath;
            const CurrentRouteUrl = router.currentRoute.value.path;
            logger.info("CurrentRoute", router.currentRoute.value);
            if (
              CurrentRouteUrl === "/materialPpt" ||
              CurrentRouteUrl === "/courseWarePpt"
            ) {
              store.commit(
                MutationNames.CHANGE_MINIMIZEBTNSTATE,
                CurrentRouteUrl
              );

              eventCenter.send("PPT_GET_STATUS", {}, (res: any) => {
                store.commit(MutationNames.CHANGE_MINIMIZEPPTINFO, res);
              });
              store.commit(MutationNames.CHANGE_MINIMIZEURL, CurrentRoute);
              // deleteContextInfo(pageTableContextMap.MINE_COURSEWARE);
              // deleteContextInfo(pageTableContextMap.COURSEWARE_CENTER);
              // deleteContextInfo(pageTableContextMap.COURSEWARE_CENTER_CARTOON);
              // deleteContextInfo(pageTableContextMap.MATERIAL_LIST);
              // deleteContextInfo(pageTableContextMap.MATERIAL_LIST_CARTOON);
            }
            // if (CurrentRouteUrl === "/courseWarePpt") {
            //   logger.info("课件广场pptu最小化");

            //   eventCenter.send("PPT_GET_STATUS", {}, (res: any) => {
            //     logger.info("课件广场pptu最小化res", res);
            //     store.commit(MutationNames.CHANGE_MINIMIZEPPTINFO, res);
            //   });
            // }
            // const microAppMap = getMicroAppMap();
            // logger.info("现在elecs", microAppMap);
            // const app = microAppMap.get("teachPptPC");
            // logger.info("现在elecsapp", app);
            setTimeout(async () => {
              // if (app) {
              //   if (app.preUnmount) {
              //     logger.info("现在elecs卸载");
              //     await app.preUnmount();
              //   }
              //   app.unmount();
              //   await app.unmountPromise; // 等待卸载完成
              //   deleteMicroAppMap("teachPptPC");
              //   logger.info("现在elecs卸载");
              // }
              if (path == "teachMaterial") {
                router.push({
                  path,
                  query: { teachMaterialType: "official" },
                });
              } else if (path == "characteristicClassroom") {
                router.push({
                  path: "teachMaterial",
                  query: { teachMaterialType: "characteristicClassroom" },
                });
              } else if (
                path === "home" &&
                (router.currentRoute.value?.name === "CoursewareLibrary" ||
                  router.currentRoute.value?.name === "CourseWare")
              ) {
                const routerUrl = "/" + path;
                router.push({
                  path: routerUrl,
                  query: { home: "true" },
                });
              } else {
                const routerUrl = "/" + path;
                router.push({
                  path: routerUrl,
                });
              }
            }, 0);

            // router.push({
            //   path: "home",
            // });

            // eventCenter.send("PPT_GET_STATUS", {}, (res: any) => {
            //   const CurrentRoute = router.currentRoute.value.fullPath;
            //   const CurrentRouteUrl = router.currentRoute.value.path;
            //   logger.info("CurrentRoute", router.currentRoute.value);
            //   logger.info("最小化res", res);
            //   store.commit(
            //     MutationNames.CHANGE_MINIMIZEBTNSTATE,
            //     CurrentRouteUrl
            //   );
            //   store.commit(MutationNames.CHANGE_MINIMIZEURL, CurrentRoute);
            //   store.commit(MutationNames.CHANGE_MINIMIZEPPTINFO, res);
            // });
          }
        } catch (error) {
          logger.info("error", error);
        }
      }
    );
  }
};

export { EVENT_NAMES };
