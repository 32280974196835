import _ from "lodash";
import { useStore } from "vuex";
import { computed, ref } from "vue";

import {
  getCacheFile,
  queryAllIncrementalResource,
  updateIncrementalResource,
  uploadCacheFile,
  uploadCacheFileFormData,
} from "@/services/localResource";
import logger from "@evideo/logger";
import LocalResourceExchange from "@evideo/local-resource-exchange";
import emitter, { EmitterEvents } from "@/utils/eventEmitter";
import {
  cloudResourceMap,
  localResourceIncrementalExtraOptions,
} from "@/utils/localResourceParse";
import { compareLocalResourceUpdate } from "@/services";

const CacheStatus = {
  NONE: "none",
  SUCCESS: "success",
  DOWNLOADING: "downloading",
};

interface LocalCacheFile {
  id: number;
  type?: string;
  cacheStatus: string;
  cacheProgress?: number | undefined; // 1.4.3以下版本不显示进度
  cacheUrl?: string;
  originUrl?: string;
}

interface ResourceHightVersion {
  cloud_id: number;
  type: string;
  url: string;
  name: string;
  mode: string;
  version_time: string;
  source: string;
  data?: any;
}

interface ResourceLowVersion {
  url: string;
  version_time: string;
  md5: string;
  id: number;
  name: string;
  mode: string;
}

interface FormParams {
  cloud_id: number;
  mode?: string;
  name: string;
  type: string;
  source: string;
  version_time: string;
  resource_file?: string; // PPT类型资源
  resource_file_url?: string; // 图片、音频、视频、FLASH 资源
}

export const synchronousTypes = ["SING_PLAY"]; // 同步资源版本时间的类型白名单

const LocalResourceTypeMap: any = {
  sheet_song: "sing_play", // 云端保存曲谱类型为sheet_song,解析库、本地资源曲谱类型为sing_play
  SING_PLAY: "SHEET_SONG",
};

const unIncrementalType = ["MUSIC_RHYTHM"]; // 不支持增量更新类型
/** *
 * 1.4.3 版本以上 -> 支持资源解析；（PPT 、图片、音频、视频、FLASH、节奏律动）
 * 1.5.6 版本以上 -> 支持歌曲课件解析
 */
export default () => {
  const store = useStore();
  const maxNum = 10;
  const isRunning = ref(false);
  const canUseCache = computed(() => store.state.localResourceState);
  const canUseHightLocalResource = _.gte(store.state.version, "1.4.3");

  const cacheQueue: ResourceHightVersion[] | ResourceLowVersion[] = [];

  // 根据课件类型，判断是否支持缓存解析下载
  const checkTypeCanUseCache = (type: string) => {
    let canUse = false;
    switch (type) {
      case "ppt":
        canUse = true;
        break;
      case "image":
        canUse = true;
        break;
      case "video":
        canUse = true;
        break;
      case "audio":
        canUse = true;
        break;
      case "swf":
        canUse = true;
        break;
      case "sheet_song":
        if (_.gte(store.state.version, "1.5.6")) {
          canUse = true;
        } else {
          canUse = false;
        }
        break;
      default:
        canUse = false;
        break;
    }
    return canUse;
  };
  // 检查资源类型是否支持增量更新
  const checkCanIncrementalType = (type: string) => {
    if (!type) {
      return false;
    }
    if (unIncrementalType.includes(type.toUpperCase())) {
      return false;
    } else {
      return true;
    }
  };

  // 过滤本地重复资源
  const filterUpdateLocalResource = (res: any[]) => {
    const localRes: any[] = [];
    res.forEach((_res: any) => {
      const { cloud_id, source, version_time, type } = _res || {};
      if (type && cloudResourceMap[type]) {
        const baseRes = {
          source: source,
          version_time: version_time,
          type: type, // 用于云端判断的资源类型
          resource_type: type, // 保留本地的资源类型
        };
        if (cloud_id) {
          const oldRes = localRes.find(
            (_localRes: any) => _localRes.cloud_id === cloud_id
          );
          // id、source、type一致视为同一资源
          if (!(oldRes && oldRes.type === type && oldRes.source === source)) {
            localRes.push({ ...baseRes, cloud_id });
          }
        } else {
          localRes.push(baseRes);
        }
      }
    });
    return localRes;
  };

  // 分页获取本地资源、对比更新，触发增量更新
  const perPageUpdateLocalResource = (
    currentPage = 1,
    totalPage = 1,
    maxPer = 100
  ) => {
    queryAllIncrementalResource({
      per_page: maxPer,
      page: currentPage,
    }).then((res: any) => {
      const { items } = res?.data || {};
      console.log("需要更新的本地资源：", items.length);
      if (items && items.length > 0) {
        // 去除重复id的资源
        const localRes = filterUpdateLocalResource(items);
        if (localRes.length > 0) {
          // type转化云端对应的type
          const _localRes = _.cloneDeep(localRes);
          _localRes.map((lr: any) => {
            lr.type = cloudResourceMap[lr.type];
            return lr;
          });
          // 访问云端接口，得到需要更新的资源详细数据
          compareLocalResourceUpdate({ resources: _localRes }).then(
            (cloudRes: any) => {
              const { resources } = cloudRes?.data || {};
              // console.log("云端最新资源：", resources);
              if (resources) {
                const _resource = _.cloneDeep(resources);
                // 还原解析库需要的type类型
                _resource.map((_r: any) => {
                  _r.type = _r.resource_type;
                  return _r;
                });
                const updateRes =
                  _resource.filter(
                    (_res: any) =>
                      _res?.update &&
                      _res?.detail &&
                      checkCanIncrementalType(_res.type)
                  ) || [];
                // 解析库解析需要增量更新数据
                LocalResourceExchange.incrementalResource(
                  updateRes,
                  localResourceIncrementalExtraOptions
                )
                  .then((resultUpdateRes: any) => {
                    if (!resultUpdateRes?.length) {
                      return;
                    }
                    // 批量进行资源增量更新
                    updateIncrementalResource({ list: resultUpdateRes })
                      .then(() => {
                        logger.info(
                          "批量更新增量资源成功",
                          currentPage,
                          totalPage
                        );
                        if (currentPage !== totalPage) {
                          perPageUpdateLocalResource(
                            currentPage + 1,
                            totalPage,
                            maxPer
                          );
                        }
                      })
                      .catch((e: any) => {
                        logger.error(`批量更新增量资源失败${e}`);
                      });
                  })
                  .catch((e: any) => {
                    logger.error(`增量更新资源解析数据失败${e}`);
                  });
              }
            }
          );
        }
      }
    });
  };

  // 检查本地资源更新
  const checkLocalResourceIncrementalUpdate = () => {
    if (!store.state.localResourceState) {
      logger.info("本地缓存服务异常，不检查本地资源更新");
      return;
    }
    logger.info("开始检查本地资源是否需要更新");
    const maxPer = 100;
    // 获取所有增量更新数据
    queryAllIncrementalResource({ per_page: maxPer }).then((res: any) => {
      const { last_page, current_page } = res?.data?.meta || {};
      perPageUpdateLocalResource(current_page, last_page, maxPer);
    });
  };

  // 获取本地已缓存文件
  const checkCacheFile = async (data: any) => {
    if (!canUseCache.value) {
      logger.info("本地缓存服务异常");
      return;
    }
    const cacheFiles: LocalCacheFile[] = [];
    try {
      const cacheData = ((await getCacheFile(data)) as any)?.data;
      if (!cacheData) {
        return;
      }
      const _cacheData = Array.isArray(cacheData) ? cacheData : [cacheData];
      if (_cacheData && _cacheData.length > 0) {
        if (canUseHightLocalResource) {
          _cacheData.forEach((localFile: any) => {
            const { type, file_info, progress, cloud_id } = localFile || {};
            cacheFiles.push({
              id: cloud_id,
              type,
              cacheStatus: file_info?.status.includes("loading")
                ? CacheStatus.DOWNLOADING
                : file_info?.status,
              cacheProgress: progress?.total_file_num
                ? Math.floor(
                    progress.complete_file_num / progress.total_file_num
                  )
                : 0,
              cacheUrl: file_info?.file_url,
            });
          });
        } else {
          _cacheData.forEach((localFile: any) => {
            const { file_info, id, params } = localFile || {};
            if (file_info) {
              cacheFiles.push({
                id,
                originUrl: JSON.parse(params)?.url,
                cacheStatus: file_info?.status,
                cacheUrl: file_info?.file_url,
              });
            }
          });
        }
      }
      return cacheFiles;
    } catch (e) {
      console.error("getCacheFile error:", e);
    }
  };

  const startCache = () => {
    if (!canUseCache.value) {
      logger.info("本地缓存服务异常");
      return;
    }
    isRunning.value = true;
    parseDownloadLocalResource();
  };

  const endCache = () => {
    isRunning.value = false;
    cacheQueue.length = 0;
  };

  const addCacheFile = (
    resource: ResourceHightVersion | ResourceLowVersion
  ) => {
    if (!canUseCache.value) {
      logger.info("本地缓存服务异常");
      return;
    }
    console.log("addCacheFile:", cacheQueue.length, maxNum);
    if (cacheQueue.length >= maxNum) {
      emitter.emit(EmitterEvents.ON_CACHE_FULL, resource);
      return;
    }
    if (canUseHightLocalResource) {
      (cacheQueue as ResourceHightVersion[]).push(
        resource as ResourceHightVersion
      );
    } else {
      (cacheQueue as ResourceLowVersion[]).push(resource as ResourceLowVersion);
    }
    if (!isRunning.value) {
      startCache();
    }
  };

  const downloadLocalResource = (
    formData: FormData,
    currentResource: ResourceHightVersion
  ) => {
    return new Promise((resolve, reject) => {
      uploadCacheFileFormData(formData)
        .then(() => {
          console.log("高版本——创建缓存资源下载成功");
          resolve("");
        })
        .catch((e) => {
          console.error("高版本——资源缓存下载失败", e);
          emitter.emit(EmitterEvents.ON_CACHE_ERROR, currentResource);
          reject(e);
        });
    });
  };

  // 解析和下载
  const parseDownloadLocalResource = () => {
    if (cacheQueue.length === 0) {
      logger.info("当前无缓存解析任务，停止缓存解析服务");
      endCache();
      return;
    }
    if (canUseHightLocalResource) {
      (window as any).requestIdleCallback(() => {
        const currentResource = cacheQueue[0] as ResourceHightVersion;
        console.log(
          "开始缓存资源解析下载",
          currentResource.type,
          currentResource.cloud_id,
          currentResource.name
        );
        const { cloud_id, type, name, source, version_time, url, mode } =
          currentResource as ResourceHightVersion;

        const formData = new FormData();
        formData.append("cloud_id", cloud_id + "");
        formData.append("name", name);
        formData.append("mode", mode);
        formData.append("type", type?.toUpperCase());
        formData.append("source", source);
        formData.append("version_time", version_time);

        if (
          currentResource.type === "ppt" ||
          currentResource.type === "sing_play"
        ) {
          LocalResourceExchange.resourceParse(currentResource)
            .then((res: any) => {
              formData.append("resource_file", res);
              downloadLocalResource(formData, currentResource)
                .then(() => {
                  emitter.emit(
                    EmitterEvents.ON_CACHE_DOWNLOAD_SUCCESS,
                    currentResource
                  );
                })
                .finally(() => {
                  cacheQueue.shift();
                  parseDownloadLocalResource();
                });
            })
            .catch((e) => {
              console.error("资源缓存解析失败", e);
              emitter.emit(EmitterEvents.ON_CACHE_ERROR, currentResource);
              cacheQueue.shift();
              parseDownloadLocalResource();
            });
        } else {
          formData.append("resource_file_url", url);
          downloadLocalResource(formData, currentResource).finally(() => {
            cacheQueue.shift();
            parseDownloadLocalResource();
          });
        }
      });
    } else {
      const currentResource = cacheQueue[0] as ResourceLowVersion;
      uploadCacheFile(currentResource)
        .then(() => {
          console.log("低版本——创建缓存资源下载成功");
        })
        .catch((e) => {
          console.error("低版本——资源缓存下载失败", e);
          emitter.emit(EmitterEvents.ON_CACHE_ERROR, currentResource);
        })
        .finally(() => {
          cacheQueue.shift();
          parseDownloadLocalResource();
        });
    }
  };

  return {
    CacheStatus,
    canUseCache,
    canUseHightLocalResource,
    LocalResourceTypeMap,
    synchronousTypes,
    checkTypeCanUseCache,
    startCache,
    endCache,
    addCacheFile,
    checkCacheFile,
    checkLocalResourceIncrementalUpdate,
  };
};
