import { request } from "@/utils/request";
import servers from "@/config/servers";

export async function getSongLists(params: any) {
  return request("GET", "/official/sheet-songs", servers.resource, params);
}

// 获取曲目
export async function getAllSongs(params: any) {
  return request(
    "GET",
    "/official/sheet-song/simple-infos",
    servers.resource,
    params
  );
}

export async function getClassStudents(params: any) {
  return request("GET", "/teacher/classStudents", servers.user, params);
}

// 学校开设学科列表
export async function getSchoolSubjects() {
  return request("GET", "/school/subjects", servers.user);
}

// 获取学科素养列表
export async function getCompetencyNorms(params: any) {
  return request(
    "GET",
    `/school/subjects/${params.id}/competencyNorms`,
    servers.user,
    params
  );
}

// 创建作业
export async function submitFastClassWork(params: any) {
  return request(
    "POST",
    "/teacher/fast-classwork/create",
    servers.exam,
    params
  );
}

// 作业详情
export async function getFastClassWorkDetail(id: number) {
  return request("GET", `/teacher/fast-classwork/info/${id}`, servers.exam);
}

// 编辑作业
export async function editFastClassWork(id: number, params: any) {
  return request(
    "POST",
    `/teacher/fast-classwork/${id}/edit`,
    servers.exam,
    params
  );
}

// 删除作业
export async function deleteFastClassWork(id: number) {
  return request(
    "DELETE",
    `/teacher/fast-classwork/${id}/delete`,
    servers.exam
  );
}

// 教师批量上传作答记录和评分
export async function uploadBatchFastClassWork(params: any) {
  return request(
    "POST",
    "/teacher/fast-classwork/record/upload",
    servers.exam,
    params
  );
}
// 教师获取作业作答记录
export async function getFastClassWorkRecords(params: any) {
  return request(
    "GET",
    "/teacher/fast-classwork/records",
    servers.exam,
    params
  );
}

// 教师更新学生作品
export async function updateFastClassWork(params: any) {
  return request(
    "POST",
    "/teacher/fast-classwork/record/update",
    servers.exam,
    params
  );
}

// 教师获取学生作品详情
export async function getFastClassWorkRecord(params: any) {
  return request(
    "GET",
    `/teacher/fast-classwork/record/info/${params.id}`,
    servers.exam,
    params
  );
}

// 教师评阅学生作品
export async function markFastClassWorkRecord(params: any) {
  return request(
    "POST",
    "/teacher/fast-classwork/assess/mark",
    servers.exam,
    params
  );
}

// 教师对作业完成评阅
export async function finishFastClassWork(params: any) {
  return request(
    "POST",
    `/teacher/fast-classwork/${params.id}/finish-mark`,
    servers.exam,
    params
  );
}

// 教师添加学生作品
export async function appendFastClassWorkRecord(params: any) {
  return request(
    "POST",
    `/teacher/fast-classwork/record/append-result`,
    servers.exam,
    params
  );
}

// 获取当前学校学期
export async function getCurrentActiveSemester() {
  return request(
    "GET",
    "/school/semesters/currentActiveSemesterData",
    servers.user
  );
}
// 获取学校学期列表
export async function getSemesterList(params: any) {
  return request("GET", "/school/semesters", servers.user, params);
}

// 删除评论
export async function deleteAssess(params: any) {
  return request(
    "DELETE",
    `/teacher/fast-classwork/assess/${params.id}`,
    servers.exam
  );
}

// 获取作业报告
export async function getFastClassWorkStatistics(id: number) {
  return request(
    "GET",
    `/teacher/fast-classwork/${id}/statistics`,
    servers.exam
  );
}

// 获取作业报告排名
export async function getFastClassWorkRank(id: number) {
  return request("GET", `/teacher/fast-classwork/${id}/rank`, servers.exam);
}
