import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["open"]

import { ref, onMounted, onBeforeUnmount, onBeforeMount } from "vue";
import { eventCenter } from "@evideo/frontend-utils";
import config from "@/config/app.config";
import servers from "@/config/servers";
import { loadMicroApp } from "qiankun";
import { useStore } from "vuex";


export default {
  setup(__props) {

const store = useStore();
const toolsAppRootRef2 = ref();
const h5ToolsApp2 = ref(undefined);
const h5ToolsAppStatus = ref(0); // 0 关闭 1 打开

/**
 * 启动学生弹窗工具
 */
const startSelectStudentComponent = () => {
  if (h5ToolsAppStatus.value === 1) {
    eventCenter.send("select-student-close");
  } else {
    eventCenter.send("select-student-open", {
      num: 1,
      token: localStorage.getItem("token"),
      isLimited: false,
      classId: store.state.className,
      defaultStudentIds: [],
      disableClose: false,
      disableRandom: true,
    });
  }
  h5ToolsAppStatus.value = h5ToolsAppStatus.value === 1 ? 0 : 1;
};

const env = JSON.stringify(process.env.RELEASE_ENV);
let entryUrl = `${config[servers.tools]}/`;
if (env === `"devp"` && localStorage.getItem("toolsAppUrl") !== null) {
  entryUrl = localStorage.getItem("toolsAppUrl");
}
const initToolsMicroApp = async () => {
  try {
    if (h5ToolsApp2.value) {
      if (h5ToolsApp2.value.preUnmount) {
        await h5ToolsApp2.value.preUnmount();
      }
      await h5ToolsApp2.value.unmount();
      await h5ToolsApp2.value.unmountPromise;
      h5ToolsApp2.value = undefined;
    }
    setTimeout(async () => {
      h5ToolsApp2.value = await loadMicroApp(
        {
          name: "tools2",
          entry: entryUrl,
          container: "#tools-app-root2",
          props: {
            args: {
              token: store.state.token,
              appName: "tools2",
              runtime: {},
            },
          },
        },
        {
          sandbox: { experimentalStyleIsolation: true },
        }
      );
    }, 1000);

    eventCenter.sub("h5-tools-init-complete", () => {
      console.log("h5Tools启动成功");
      initSelectStudentComponent();
    });
  } catch (e) {
    console.error("h5Tools启动失败", e);
    console.error(e);
  }
};

const initSelectStudentComponent = () => {
  eventCenter.send("load-select-student-component", {
    teacherToken: localStorage.getItem("token"),
  });
  eventCenter.sub("select-student-modal-close-complete", () => {
    console.log("teactWebview关闭学生弹窗");
    h5ToolsAppStatus.value = 0;
    eventCenter.send(`ppt-select-student-modal-close`);
  });
  eventCenter.sub("select-student-modal-select", ({ selectedList }) => {
    // console.log("学生已选择", selectedList);
    eventCenter.send(`ppt-selected-students`, selectedList);
  });
  eventCenter.sub("to-home-start-student-modal-select", () => {
    // console.log("启动学生选择弹窗");
    startSelectStudentComponent();
  });
};

onMounted(() => {
  eventCenter.sub("ppt-init-students-modal", async () => {
    await initToolsMicroApp();
    h5ToolsAppStatus.value = 0;
    console.log("ppt通知初始化学生弹窗");
  });
  eventCenter.sub("ppt-open-students-modal", () => {
    startSelectStudentComponent();
    console.log("ppt通知打开学生弹窗");
  });
});

onBeforeUnmount(async () => {
  if (h5ToolsApp2.value) {
    if (h5ToolsApp2.value.preUnmount) {
      await h5ToolsApp2.value.preUnmount();
    }
    await h5ToolsApp2.value.unmount();
    await h5ToolsApp2.value.unmountPromise;
    h5ToolsApp2.value = undefined;
  }
});
onBeforeMount(async () => {
  if (h5ToolsApp2.value) {
    if (h5ToolsApp2.value.preUnmount) {
      await h5ToolsApp2.value.preUnmount();
    }
    await h5ToolsApp2.value.unmount();
    await h5ToolsApp2.value.unmountPromise;
    h5ToolsApp2.value = undefined;
  }
});

return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("div", {
    open: h5ToolsAppStatus.value === 1,
    id: "tools-app-root2",
    ref: toolsAppRootRef2,
    class: "tools-app-root2 transition2"
  }, null, 8, _hoisted_1))
}
}

}