import mitt, { Emitter } from "mitt";

export const enum EmitterEvents {
  ADD_WORK_TASK = "ADD_WORK_TASK",
  ON_WORK_TASK_PROGRESS = "ON_WORK_TASK_PROGRESS",
  ON_TASK_PROGRESS = "ON_TASK_PROGRESS",
  ON_TASK_CLICK = "ON_TASK_CLICK",
  ON_TASK_STOP = "ON_TASK_STOP",

  ON_CACHE_ERROR = "ON_CACHE_ERROR",
  ON_CACHE_FULL = "ON_CACHE_FULL",
  ON_CACHE_DOWNLOAD_SUCCESS = "ON_CACHE_DOWNLOAD_SUCCESS",
}

export interface Job {
  id: string;
  name: string;
  progress: number;
  status: "pending" | "start" | "doing" | "done" | "failed";
  extra?: any;
}

export type Events = {
  [EmitterEvents.ADD_WORK_TASK]: Job[];
  [EmitterEvents.ON_WORK_TASK_PROGRESS]: Job;
  [EmitterEvents.ON_TASK_PROGRESS]: Job;
  [EmitterEvents.ON_TASK_CLICK]: Job;
  [EmitterEvents.ON_TASK_STOP]: void;

  [EmitterEvents.ON_CACHE_ERROR]: any;
  [EmitterEvents.ON_CACHE_FULL]: any;
  [EmitterEvents.ON_CACHE_DOWNLOAD_SUCCESS]: any;
};

const emitter: Emitter<Events> = mitt<Events>();

export default emitter;
