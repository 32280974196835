import config from "@/config/app.config";
import servers from "@/config/servers";
import { ElMessage } from "element-plus";

// 本地资源不解析的类型
const localResourceUnParseType = ["ppt_question"];

const localResourceExtraOptions = {
  noParseAttributes: {
    sing_play: ["source", "type", "status", "cacheStatus", "cacheProgress"],
  },
  unVirtualAttributes: {
    ppt_enjoy_song: ["picture_url"],
  },
  unParseType: localResourceUnParseType,
};

const localResourceIncrementalExtraOptions = {
  noParseAttributes: {},
  unVirtualAttributes: {
    PPT_ENJOY_SONG: ["picture_url"],
  },
  unParseType: localResourceUnParseType,
};

const localResourceConfig = {
  setToken: () => {
    return localStorage.getItem("token");
  },
  path: {
    sing_play: config[servers.resource] + "/",
    enjoy_song: config[servers.resource] + "/",
    ppt_music_rhythm: config[servers.resource] + "/",
    question: config[servers.exam] + "/",
    music_rhythm: config[servers.user] + "/teacher/userMelodyFiles/",
  },
};

const cloudResourceMap: any = {
  IMAGE: "image",
  VIDEO: "video",
  AUDIO: "audio",
  MUSIC_RHYTHM: "melody",
  SWF: "swf",
  PPT_IMAGE: "image",
  PPT_AUDIO: "audio",
  PPT_VIDEO: "video",
  PPT_MUSIC_RHYTHM: "melody",
  PPT_SING_PLAY: "sheet_song",
  PPT_ENJOY_SONG: "enjoy_song",
  SING_PLAY: "sheet_song",
};

const maxTask = 10;

const checkCurrentCacheDownloading = () => {
  const currentCacheDownloadingNum = +(
    localStorage.getItem("cacheDownloadNum") || 0
  );
  if (currentCacheDownloadingNum + 1 > maxTask) {
    ElMessage.warning({
      offset: 200,
      message: "当前下载繁忙，请稍后",
      type: "warning",
    });
    return false;
  } else {
    localStorage.setItem(
      "cacheDownloadNum",
      +(localStorage.getItem("cacheDownloadNum") || 0) + 1 + ""
    );
    return true;
  }
};

const subCurrentCacheDownloadNum = () => {
  localStorage.setItem(
    "cacheDownloadNum",
    +(localStorage.getItem("cacheDownloadNum") || 1) - 1 + ""
  );
};

export {
  localResourceConfig,
  localResourceExtraOptions,
  cloudResourceMap,
  localResourceIncrementalExtraOptions,
  checkCurrentCacheDownloading,
  subCurrentCacheDownloadNum,
};
