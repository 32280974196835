import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeStyle as _normalizeStyle, createBlock as _createBlock, createElementVNode as _createElementVNode, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-b8f2a532")
const _hoisted_1 = { class: "list-box" }
const _hoisted_2 = {
  class: "stu-card-box",
  style: {"padding-left":"0.5rem"}
}
const _hoisted_3 = {
  key: 0,
  class: "no-find-stu"
}
const _hoisted_4 = {
  key: 1,
  class: "no-find-stu"
}
const _hoisted_5 = {
  key: 0,
  style: {"height":"5rem"}
}
_popScopeId()

import {
  ref,
  inject,
  watch,
  toRaw,
  onMounted,
  computed,
} from "vue";
import StudentCard from "../base/StudentCard.vue";
import { ALL_STUDENT_ID, ALL_STUDENT_ICON, OrderValueEnum } from "../../config";
import { ClassroomInterationNS } from "../../types";
import logger from "@evideo/logger";
import { addStudentEvaluateRecord } from "@/services/classroomInteraction";
import EffectMask from "../base/EffectMask.vue";
import RandomModal from "./randomModal.vue";
import { ElMessage } from "element-plus";
import { delayLoading } from "../../utils";
import { reportStat } from "@/utils/statDataReport";
import { useStore } from "vuex";

type ModeTypeKey = "SINGLE" | "MULTI" | "RANDOM";
type ModeTypeValue = "single" | "multi" | "random";


export default _defineComponent({
  setup(__props, { expose }) {

const store = useStore();

let CIBodyRef: any = ref();
CIBodyRef = inject("CIBodyRef");

const NormTypeEnum = {
  TO_BE_IMPROV: 0,
  PRAISE: 1,
};

// 当前列表组件的状态
const ModeTypeEnum: { [key in ModeTypeKey]: ModeTypeValue } = {
  // 单人评分模式
  SINGLE: "single",
  // 多选评分
  MULTI: "multi",
  // 随机评分
  RANDOM: "random",
};

// 是否是多选模式
const isMultiSelect = inject<any>("multiSelectAction");

const studentList = inject<any>("studentList");
// 学生列表数据
const list = ref<any>([]);

// 全班得分信息
const totalClassScore = inject<any>("totalClassScore");
// 多选学生的id集合
const selectKey = ref(new Set<number | string>());

const lessonId = inject<any>("lessonId");
const curSubject = inject<any>("curSubject");

const maskRef = ref();
const maskInfo = ref<ClassroomInterationNS.MaskInfoType>();

// 是否显示分数
const showScore = ref<boolean>(true);

// 当前的模式
const curMode = ref<ModeTypeValue>(ModeTypeEnum.SINGLE);

// 随机弹窗
const randomModalVisible = ref<boolean>();

// 多选 存储前两人的名字的信息
const multiNameTwo = ref<any[]>([]);

// 搜索框内容
const searchContent = inject<any>("searchContent");

const sortType = inject<any>("orderStudent");
const orderFunc = inject<ClassroomInterationNS.SortListByOrderTypeFunc>(
  "sortListByOrderType"
);

const handleShowMask =
  inject<(config: ClassroomInterationNS.MaskInfoType) => void>(
    "handleShowMask"
  );

// 展示全局评价弹窗
const handleShowAppraiseModal =
  inject<ClassroomInterationNS.handleShowAppraiseModalProvider>(
    "handleShowAppraiseModal"
  );

// 更新学生信息列表
const handleUpdateStudentList = inject<() => Promise<void>>(
  "handleUpdateStudentList"
);

const handleAppraiseModalClose: any = inject("handleCloseAppraiseModal");

// 改变可选勾
const changeIsAllSelected = inject<any>(
  "changeIsAllSelected",
  () => null,
  false
);

// 更新全班分数
const updateTotalScore = (
  list: any[],
  totalScore: { norm_type: number; total_score: number }[]
) => {
  if (!totalClassScore) {
    return list;
  }
  const allClassScore: { add?: { value: number }; reduce?: { value: number } } =
    {};
  totalScore.forEach((i) => {
    if (i.norm_type === NormTypeEnum.PRAISE) {
      allClassScore.add = { value: Math.abs(i.total_score) };
    } else {
      allClassScore.reduce = { value: Math.abs(i.total_score) };
    }
  });
  // 兼容已经插入过的情况
  if (list[0]?.id === ALL_STUDENT_ID) {
    list[0].score = allClassScore;
  } else {
    list.unshift({
      id: ALL_STUDENT_ID,
      name: "全班",
      avatar: ALL_STUDENT_ICON,
      score: allClassScore,
    });
  }
  return list;
};

// 生成需要渲染的数据，并根据排序值及搜索值进行处理
const generateList = (
  students: any[],
  sort: number = OrderValueEnum.FIRST_LETTER,
  keyword = ""
) => {
  // 分数处理
  let array = students.map((stu: any) => {
    const score: { add?: { value: number }; reduce?: { value: number } } = {};
    stu.lesson_evaluate_total_score.forEach((lessScore: any) => {
      if (lessScore.norm_type === NormTypeEnum.PRAISE) {
        score.add = { value: Math.abs(lessScore.total_score) };
      } else {
        score.reduce = { value: Math.abs(lessScore.total_score) };
      }
    });
    return {
      name: stu.name,
      py_name: stu.py_name,
      id: stu.id,
      avatar: stu.avatar || getRandomAvatar(),
      score,
    };
  });
  // 排序模式处理
  if (orderFunc) {
    array = orderFunc(array, sort, false);
  }
  if (keyword && keyword.length > 0) {
    array = array.filter(
      (i) => i.py_name?.indexOf(keyword) > -1 || i.name?.indexOf(keyword) > -1
    );
  } else {
    // 非排序模式，增加全班在最前
    array = updateTotalScore(array, toRaw(totalClassScore.value) || []);
  }
  return array;
};

// 生成随机头像
const getRandomAvatar = () => {
  return `stu_avatar_${Math.floor(Math.random() * (10 + 1)) + 1}.jpg`;
};

// 多选点击事件
const handleMultiSelect = (item: { name: string; id: number | string }) => {
  const { id } = item;
  // 多选兼容全班的情况
  if (id === ALL_STUDENT_ID) {
    if (selectKey.value.size === list.value.length - 1) {
      selectKey.value.clear();
    } else {
      const newSet = new Set<number | string>(
        list.value
          .filter((i: { id: string }) => i.id !== ALL_STUDENT_ID)
          .map((i: { id: any }) => i.id)
      );
      selectKey.value = newSet;
      multiNameTwo.value.length = 0;
      list.value
        .filter((i: { id: string }) => i.id !== ALL_STUDENT_ID)
        .map((item: { name: string; id: number | string }) => {
          multiNameTwo.value.push(item.name);
        });
    }
    return;
  }
  if (!selectKey.value.has(id)) {
    selectKey.value.add(id);
    multiNameTwo.value.push(item.name);
  } else {
    selectKey.value.delete(id);
    multiNameTwo.value.splice(multiNameTwo.value.indexOf(item.name), 1);
  }
};

// 学生是否全选
const isStudentAllSelected = computed(() => {
  if (selectKey.value.size === list.value.length - 1) {
    return true;
  } else {
    return false;
  }
});

// 全选点击事件
const handleSelectAllStudentBtn = () => {
  if (isStudentAllSelected.value) {
    selectKey.value.clear();
  } else {
    list.value.map((first: any) => {
      if (first.id === ALL_STUDENT_ID) {
        return;
      }
      selectKey.value.add(first.id);
    });
  }
};

// 取消多选
const handleMultiSelectCancel = () => {
  // 这个位置最好不要直接修改参数
  isMultiSelect.value = false;
};

// 多选选择 结束 (发送点评按钮)
const handleMultiSelectStuOk = () => {
  if (selectKey.value.size == 0) {
    ElMessage.warning("请选择点评学生");
    return;
  }
  const info = {
    msg: `这${selectKey.value.size}名同学`,
    autoClose: false,
  };

  const onOk = async (val: any) => await handleMultiEvaluationSelectOk(val);
  if (handleShowAppraiseModal) {
    handleShowAppraiseModal(info, onOk);
  }
  // todo 调用弹窗， 选择评价，并进行接口请求处理
};

// 单个评分调用
const handleSingleEvaluationSelectOk = async (
  stuInfo: any,
  selectValue: any
) => {
  if (!lessonId || !lessonId.value) {
    return;
  }
  const params: any = {
    lesson_id: lessonId.value,
    norm_code: selectValue.norm_code,
    subject: curSubject.value || "music",
    students: [],
  };
  // 兼容全班按钮
  if (stuInfo.id === ALL_STUDENT_ID) {
    list.value.forEach((item: any) => {
      if (item.id !== ALL_STUDENT_ID) {
        params.students.push({
          student_id: item.id,
          score: parseInt(selectValue.single_opr_score),
        });
      }
    });
  } else {
    params.students = [
      {
        student_id: stuInfo.id,
        score: parseInt(selectValue.single_opr_score),
      },
    ];
  }
  let clear;
  try {
    clear = delayLoading(1000, "设置评分中，请稍后");
    await addStudentEvaluateRecord(params);
    // 数据埋点
    reportStat({
      moduleid: "tools",
      pageid: "classCommunication",
      eventid: "classCommunication_studentEvaluation",
      eventattr: {
        teacher_id: store.state.teacherId,
        result: params.students,
      },
    });
    clear();
    handleAppraiseModalClose();
    // todo showMask
    if (handleShowMask) {
      const config = {
        isPraise: parseInt(selectValue.single_opr_score) > 0,
        msg: selectValue.title,
        point: Math.abs(parseInt(selectValue.single_opr_score)),
        avatar: stuInfo.avatar,
        name: stuInfo.name,
      };
      handleShowMask(config);
      if (handleUpdateStudentList) {
        handleUpdateStudentList();
      }
    }
  } catch (err) {
    if (clear) {
      clear();
    }
    logger.error("【课堂互动】:学生评分失败", err);
  }
};

// 多选评分调用
const handleMultiEvaluationSelectOk = async (selectValue: any) => {
  if (!lessonId || !lessonId.value) {
    return;
  }
  const params: any = {
    lesson_id: lessonId.value,
    norm_code: selectValue.norm_code,
    subject: curSubject.value || "music",
    students: [],
  };
  // 多选ID放入
  for (let stuID of selectKey.value) {
    params.students.push({
      student_id: stuID,
      score: parseInt(selectValue.single_opr_score),
    });
  }
  let clearFunc = undefined;
  try {
    clearFunc = delayLoading(1000, "设置评分中，请稍后");
    await addStudentEvaluateRecord(params);
    // 数据埋点
    reportStat({
      moduleid: "tools",
      pageid: "classCommunication",
      eventid: "classCommunication_studentEvaluation",
      eventattr: {
        teacher_id: store.state.teacherId,
        result: params.students,
      },
    });
    clearFunc();
    let mask_isPraise: boolean = parseInt(selectValue.single_opr_score) > 0;

    let mask_avatar: string = mask_isPraise ? "mul_yes.png" : "mul_no.png";
    let mask_name = "";
    let tempTwoList = multiNameTwo.value.slice(0, 2);
    // 单个名字长度超过四个字时单独显示
    for (let temp of tempTwoList) {
      // console.log(temp);
      if (temp.length > 4) {
        mask_name = temp;
        break;
      } else {
        mask_name = multiNameTwo.value.slice(0, 2).join();
      }
    }
    // alert(mask_name.length);
    let mask_last_name =
      multiNameTwo.value.length == list.value.length - 1
        ? `全班${selectKey.value.size}人`
        : `${mask_name}等${selectKey.value.size}人`;
    handleAppraiseModalClose();
    if (handleShowMask) {
      const config = {
        isPraise: mask_isPraise,
        msg: selectValue.title,
        point: Math.abs(parseInt(selectValue.single_opr_score)),
        avatar: mask_avatar,
        name: mask_last_name,
        noBorder: true,
      };
      handleShowMask(config);
    }
    if (handleUpdateStudentList) {
      handleUpdateStudentList();
      // 一次多选评分完成 取消多选状态
      handleMultiSelectCancel();
      multiNameTwo.value.length = 0;
    }
  } catch (err) {
    if (clearFunc) {
      clearFunc();
      clearFunc = undefined;
    }
    logger.error("【课堂互动】:学生评分失败", err);
  }
};

// 单选点击事件
const handleSingleSelect = (item: any) => {
  const info = {
    msg: item.name,
    autoClose: false,
  };
  const onOk = async (val: any) =>
    await handleSingleEvaluationSelectOk(item, val);
  if (handleShowAppraiseModal) {
    handleShowAppraiseModal(info, onOk);
  }
};

// 学生卡片点击
const handleClick = (item: any) => {
  switch (curMode.value) {
    case ModeTypeEnum.SINGLE:
      if (item.id === ALL_STUDENT_ID) {
        return;
      }
      handleSingleSelect(item);
      break;
    case ModeTypeEnum.MULTI:
      handleMultiSelect(item);
      break;
  }
};

// 随机评分部分
const handleRandom = () => {
  randomModalVisible.value = true;
};

// 搜索方法
const handleSearch = (keyword: string) => {
  list.value = generateList(studentList.value, sortType.value, keyword);
};

onMounted(() => {
  if (handleUpdateStudentList) {
    handleUpdateStudentList();
  }
});

expose({
  onRandom: handleRandom,
  onSearch: handleSearch,
  onMultiSelectStuOK: handleMultiSelectStuOk,
  onMultiSelectCancel: handleMultiSelectCancel,
  Allselect: handleSelectAllStudentBtn,
});

// 数据源变换
watch(
  () => studentList.value,
  (newValue) => {
    if (newValue) {
      list.value = generateList(
        newValue || [],
        sortType.value,
        searchContent.value
      );
    }
  }
);

// 总分信息变化
watch(
  () => totalClassScore.value,
  (newValue) => {
    if (newValue) {
      list.value = generateList(
        studentList.value,
        sortType.value,
        searchContent.value
      );
    }
  }
);

// 排序模式变化
watch(
  () => sortType.value,
  (val) => {
    if (val !== undefined && orderFunc) {
      list.value = generateList(studentList.value, val, searchContent.value);
    }
  }
);

watch(
  () => searchContent.value,
  (val) => {
    if (val !== undefined && val === "") {
      list.value = generateList(studentList.value, sortType.value, val);
    }
  }
);

// 监听单选多选变化
watch(
  () => isMultiSelect.value,
  (val) => {
    if (val === true) {
      curMode.value = ModeTypeEnum.MULTI;
    } else if (val === false) {
      curMode.value = ModeTypeEnum.SINGLE;
      selectKey.value = new Set();
    }
  }
);

// 监听是否全选变化
watch(isStudentAllSelected, (old) => {
  changeIsAllSelected?.(old);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(EffectMask, {
        ref: maskRef,
        maskInfo: maskInfo.value,
        mountRef: _unref(CIBodyRef)
      }, null, 8, ["maskInfo", "mountRef"]),
      _createElementVNode("div", _hoisted_2, [
        (list.value.length === 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, "未找到相关学生"))
          : _createCommentVNode("", true),
        (_unref(studentList).length == 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, " 该班级还未配置任何学生 "))
          : (_openBlock(true), _createElementBlock(_Fragment, { key: 2 }, _renderList(list.value, (item) => {
              return (_openBlock(), _createBlock(StudentCard, {
                style: _normalizeStyle({ marginRight: '1.5rem' }),
                key: item.id,
                info: item,
                "show-star": 
            curMode.value === ModeTypeEnum.SINGLE &&
            showScore.value &&
            item.id !== _unref(ALL_STUDENT_ID)
          ,
                showScore: 
            curMode.value === ModeTypeEnum.SINGLE &&
            (showScore.value || item.id === _unref(ALL_STUDENT_ID))
          ,
                isSelect: curMode.value === ModeTypeEnum.MULTI && selectKey.value.has(item.id),
                onClick: () => handleClick(item)
              }, null, 8, ["style", "info", "show-star", "showScore", "isSelect", "onClick"]))
            }), 128))
      ]),
      (_unref(isMultiSelect))
        ? (_openBlock(), _createElementBlock("div", _hoisted_5))
        : _createCommentVNode("", true),
      (randomModalVisible.value)
        ? (_openBlock(), _createBlock(RandomModal, {
            key: 1,
            list: list.value.filter((i) => i.id !== _unref(ALL_STUDENT_ID)),
            visible: randomModalVisible.value,
            "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => (randomModalVisible.value = $event)),
            "lesson-id": _unref(lessonId),
            "cur-subject": _unref(curSubject)
          }, null, 8, ["list", "visible", "lesson-id", "cur-subject"]))
        : _createCommentVNode("", true)
    ])
  ]))
}
}

})