type AppraiseConfigType = {
  img: string;
  title: string;
  addPoint?: number;
  dockPoint?: number;
  norm_code: string;
  id: number;
  single_opr_score: number;
};

type AppraiseInfo = {
  msg: string;
};

const AppraiseModeEnum = {
  TO_BE_IMPROV: 0,
  PRAISE: 1,
};

export { AppraiseConfigType, AppraiseInfo, AppraiseModeEnum };
