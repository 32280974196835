export default {
  user: "user", // 移动端和 wps 端
  device: "device", // 设备端，包括：学生主机、教师主机、信息机、减压亭等
  platform: "platform", // 云端后台，即音乐教学平台
  pptist: "pptist", // ppt
  ecoach: "ecoach", // ecoach
  resource: "resource", // resource
  ec: "ec", // 数据埋点
  qrcode: "qrcode", // 微信二维码
  kefuQrcode: "kefuQrcode", // 客服微信二维码
  authorization: "authorization", //  pc端授权功能
  musicGame: "musicGame",
  common: "common",
  tools: "tools",
  localResource: "localResource",
  exam: "exam",
  dingtalkCallback: "dingtalkCallback",
  school: "school",
  deviceControlService: "deviceControlService",
  cloud: "cloud",
  app: "app",
  api: "api",
};
