import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store, { MutationNames } from "./store";
import ElementPlus from "element-plus";
import zhCn from "element-plus/es/locale/lang/zh-cn";
import "element-plus/dist/index.css";
import { loadMicroApp, initGlobalState, prefetchApps } from "qiankun";
// import "./jsBridge";
import JsBridge from "./jsBridge";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { eventCenter, event } from "@evideo/frontend-utils";
import servers from "@/config/servers";
import config from "@/config/app.config";
import Message from "ant-design-vue/lib/message";
import "ant-design-vue/lib/message/style";
import * as ELIcons from "@element-plus/icons-vue";
import mavonEditor from "mavon-editor";
import "mavon-editor/dist/css/index.css";
import "@/assets/iconfont/iconfont.css";
import logger from "@evideo/logger";
import SocketIO from "./plugins";

const eventData = {
  type: "",
  data: null,
};

// const testeventCenter = window.eventCenter;
// const { registerH5GetToken } = JsBridge();
// registerH5GetToken();
// 应用间通信事件初始化
const actions = initGlobalState({ eventData });

const loadSheetLib = () => {
  (window as any).initSheetMusicPromise = new Promise((resolve, reject) => {
    logger.info("webview开始加载sheetLib");
    (window as any)
      ?.initSheetLib()
      .then(() => {
        logger.info("webview加载sheetLib成功");
        resolve("");
      })
      .catch((err: any) => {
        logger.error("webview加载sheetLib失败", err?.message);
        reject(err);
      });
  });
};

loadSheetLib();

if (window.require) {
  const { ipcRenderer } = window.require("electron");
  // ipcRenderer.sendToHost("print"); // 向原生发送信息
  ipcRenderer.on("go-to-login", (event: any, message: any) => {
    // 接收electron原生返回的信息

    logger.info("我接收到的electron发过来的信息", event, message);
    try {
      const state = message;
      logger.info("进来了退出state", state);
      logger.info("events", message);
      // 清除截图定时器
      clearInterval(store.state.screenshotTimer);
      store.commit(MutationNames.CHANGE_SCREENSHOTTIMER, "");
      // 重制上下课状态
      store.commit(MutationNames.CHANGE_LESSONSTART, true);
      store.commit(MutationNames.CHANGE_LESSONCLASSID, 0);
      store.commit(MutationNames.CHANGE_GRADENUMBER, "");
      store.commit(MutationNames.CHANGE_CLASSNUMBER, "");
      store.commit(MutationNames.CHANGE_SCHOOL_ID, undefined);
      logger.info("store.state.lessonStart", store.state.lessonStart);
      router.push({
        path: "/login",
      });
    } catch (error) {
      logger.info("error", error);
    }
  });
  // ipcRenderer.on("is-online-state", (events: any, args: any) => {
  //   try {
  //     logger.info("接收到electron发送的网络变化的");

  //     const state = args;
  //     logger.info("state", state);
  //     logger.info("events", events);
  //     const fullPath = router.currentRoute.value.fullPath;
  //     const query = router.currentRoute.value.query;

  //     const currentTime = new Date().getTime();
  //     logger.info("fullPath", fullPath);
  //     logger.info("query", query);

  //     store.commit(MutationNames.CHANGE_ONLINESTATE, state.isOnlineState);
  //     // isRequestError true|没有发送请求所以不用reload false|有发送请求，请求失败所以需要刷新页面
  //     if (!args.isRequestError) {
  //       logger.info("刷新页面了");
  //       // router.push({
  //       //   path: fullPath,
  //       //   query: {
  //       //     ...query,
  //       //     _: currentTime,
  //       //   },
  //       //   force: true,
  //       // });
  //       // location.reload();
  //       // history.go(0);
  //     }
  //   } catch (error) {
  //     logger.info("errortest", error);
  //   }
  // });
}
eventCenter.registerSend(actions.setGlobalState);

actions.onGlobalStateChange((state: any, prev: any) => {
  logger.info("【主应用】", state, prev);

  eventCenter.registerPubTrigger(state);
});

const microAppMap = new Map();
export const getMicroAppMap = () => {
  return microAppMap;
};
export const deleteMicroAppMap = (key: any) => {
  microAppMap.delete(key);
};
export const setMicroAppMap = (key: any, value: any) => {
  microAppMap.set(key, value);
};
// (window as any).eventCenter.sub("gyg1", (data: any) => {
//   logger.info("data", data);
// });
// 监听打开ppt;
eventCenter.sub(event.COMMON_LOAD_MICRO_APP, async (res: any, e: any) => {
  logger.info("收到消息", res);
  const microApp = await loadMicroApp(
    {
      name: res.app,
      entry: res.entry,
      container: res.el,
      props: { args: res.args },
    },
    {
      sandbox: { experimentalStyleIsolation: true },
      single: false,
      ...res.configuration,
    }
  );
  microAppMap.set(res.app, microApp);
  logger.info("map ========> ", microAppMap);
  await microApp.mountPromise;
  e.reply(true);
});
// 移除应用监听
eventCenter.sub(event.COMMON_UNLOAD_MICRO_APP, async (res: any, e: any) => {
  logger.info("移除应用", res);
  logger.info("移除应用", e);
  const app = microAppMap.get(res.app);
  if (app) {
    if (app.preUnmount) {
      logger.info("我要卸载了pre");
      await app.preUnmount();
    }
    app.unmount();
    await app.unmountPromise; // 等待卸载完成
    microAppMap.delete(res.app);
    e.reply(true);
    return;
  }
  e.reply(false);
});
// eventCenter.sub("changeWindow", (res: any) => {
//   logger.info("main监听", res);
// });

// eventCenter.sub("abc", (res: any, event: { reply: (arg0: number) => void }) => {
//   // res:获取事件a的参数
//   logger.info("收到", res);
//   // 回复
//   event.reply(999);
// });
// eventCenter.send("a", "888", (res: any) => {
//   logger.info("发送", res);
// });
// logger.info("【主应用window】", localStorage.getItem("entryUrl"));

const env = JSON.stringify(process.env.RELEASE_ENV);
// let entryUrl = "//devpppt.ecoach.evideo.tech";
// let entryUrl = "//192.168.74.175:8080";
// if (env === `"devp"` && localStorage.getItem("entryUrl") !== null) {
//   entryUrl = localStorage.entryUrl;
// }
// apps为需要用到的子应用
// const apps = [
//   {
//     name: "pptist", // 唯一名称
//     entry: entryUrl, // 入口文件地址
//     // entry: "//devpppt.ecoach.evideo.tech", // 入口文件地址
//     container: "#micro-app-app1", // 要展示子应用的容器元素id
//     activeRule: "/pptist", // 子应用对应的路由前缀
//   },
// ];
// 注册子应用
// registerMicroApps(apps, {});config[server]
const entryPptUrl = config[servers.pptist];
const entryEcoachUrl = config[servers.ecoach];
prefetchApps([
  {
    name: "pptist",
    entry: entryPptUrl,
  },
]);

const app = createApp(App);

app.use(SocketIO, {
  options: {
    transport: ["websocket"],
    autoConnect: false,
  },
  connection: "ws://127.0.0.1:8099/",
});
app.use(ElementPlus, {
  locale: zhCn,
});
app.use(store);
app.use(router);
app.use(JsBridge);
app.use(mavonEditor);
app.use(Message as any);
// Object.keys(ELIcons).forEach((key) => {
//   app.component(key, ELIcons[key as keyof typeof ELIcons]);
// });
for (const name in ELIcons) {
  app.component(name, (ELIcons as any)[name]);
}
app.mount("#app");
