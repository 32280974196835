// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/img/classroomInteraction/maskEffect/yes.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("@/assets/img/classroomInteraction/maskEffect/no.png");
var ___CSS_LOADER_URL_IMPORT_2___ = require("@/assets/img/classroomInteraction/maskEffect/yes_name.png");
var ___CSS_LOADER_URL_IMPORT_3___ = require("@/assets/img/classroomInteraction/maskEffect/no_name.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
exports.push([module.id, ".mask-entry[data-v-c87d66b6]{position:relative}.mask-container[data-v-c87d66b6]{display:flex;flex-direction:column;align-items:center;position:absolute;top:0;left:0;bottom:0;right:0;margin:auto;width:100%;background-color:#fff;z-index:99}.mask-container .mask-main[data-v-c87d66b6]{width:492px;height:270px;margin:0 auto;margin-top:118px}.mask-container .mask-main .mask-avatar[data-v-c87d66b6]{position:relative;width:176px;height:177px;margin:0 auto;margin-top:26px}.mask-container .mask-main .mask-avatar img[data-v-c87d66b6]{width:200px;height:195px}.mask-container .mask-main .mask-avatar .imgborder[data-v-c87d66b6]{width:182px;height:182px;border-radius:50%;border:6px solid #ffe4ba}.mask-container .mask-main .name-box[data-v-c87d66b6]{width:304px;height:120px;margin:0 auto;margin-top:5px;overflow:hidden}.mask-container .mask-main .name-box .name-msg[data-v-c87d66b6]{margin-top:25px;min-width:114px;height:38px;text-align:center;font-size:1.9rem;line-height:38px;color:#fff}.mask-container .mask-main .name-box .msgLong[data-v-c87d66b6]{font-size:1.5rem}.mask-container .praise[data-v-c87d66b6]{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}.mask-container .no-praise[data-v-c87d66b6]{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")}.mask-container .praise-name[data-v-c87d66b6]{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ")}.mask-container .no-praise-name[data-v-c87d66b6]{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ")}.mask-container .mask-info[data-v-c87d66b6]{display:flex;min-width:202px;height:36px;margin-top:60px}.mask-container .mask-info .mask-msg[data-v-c87d66b6]{display:inline-block;min-width:131px;height:31px;font-size:33px;line-height:31px;margin-right:14px;color:#4f4e57}", ""]);
// Exports
module.exports = exports;
