import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, withScopeId as _withScopeId } from "vue"

import { computed } from "vue";
interface ControlSwitchProps {
  value: boolean;
  color?: string;
}


export default _defineComponent({
  props: {
    value: { type: Boolean, required: true, default: false },
    color: { type: String, required: false, default: "#FE6040" }
  } as unknown as undefined,
  emits: ["onChange"] as unknown as undefined,
  setup(__props: {
  value: boolean;
  color?: string;
}, { emit }: { emit: ({
  (event: "onChange", newVal: boolean): void;
}), expose: any, slots: any, attrs: any }) {

const props = __props




const value = computed(() => props.value);

const color = computed(() => props.color);

const closeColor = "#6C5321";

const handleChange = () => {
  emit("onChange", !value.value);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "switch-swrapper",
    style: _normalizeStyle({
      border: `3px solid ${_unref(value) ? _unref(color) : closeColor}`,
      backgroundColor: '#fff',
    }),
    onClick: handleChange
  }, [
    _createElementVNode("div", {
      class: "slider",
      style: _normalizeStyle({
        left: `${_unref(value) ? '25px' : 0}`,
        backgroundColor: `${_unref(value) ? _unref(color) : closeColor}`,
      })
    }, null, 4)
  ], 4))
}
}

})