export const SocketEventMap = {
  // 缓存已满事件
  CACHE_FULL: "CACHE_STORAGE_FULL",
  CAN_USE_SERVICE: "CAN_USE_SERVICE",
  BATCH_DELETE_RESULT: "BATCH_DELETE_RESULT",
  CHANGE_PATH_RESULT: "CHANGE_PATH_RESULT",
  BATCH_DELETE_FAILED_RESULT: "CLEAN_ERROR_DOWNLOAD_FILE_RESULT",
  DOWNLOAD_STATUS: "DOWNLOAD_STATUS",
  DOWNLOAD_PROGRESS: "DOWNLOAD_PROGRESS",
  STATIC_PROXY_CHECK_VERSION_ERROR: "STATIC_PROXY_CHECK_VERSION_ERROR",
};
