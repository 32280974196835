export const grades = [
  undefined,
  "一年级",
  "二年级",
  "三年级",
  "四年级",
  "五年级",
  "六年级",
  "七年级",
  "八年级",
  "九年级",
  "高一",
  "高二",
  "高三",
  "大一",
  "大二",
  "大三",
  "大四",
];
