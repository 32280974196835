// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/img/login/account-icon.jpg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("@/assets/img/login/wechat-icon.png");
var ___CSS_LOADER_URL_IMPORT_2___ = require("@/assets/img/login/dingtalk-icon.png");
var ___CSS_LOADER_URL_IMPORT_3___ = require("@/assets/img/login/zdy-icon.jpg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
exports.push([module.id, ".login-card-popover{width:185px;background-color:#fff;box-shadow:0 3px 9px 0 rgba(0,0,0,.22);border-radius:8px}.login-card-popover .login-type-item{display:flex;align-items:center;cursor:pointer;font-size:24px;width:100%;height:56px;margin-left:5px}.login-card-popover .login-type-icon{width:32px;height:32px;margin-right:10px}.login-card-popover .login-type-name{text-align:center;color:#3a3a3a;font-size:24px;overflow:hidden}.login-card-popover .login-type-icon-account{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat 50%;background-size:100% 100%}.login-card-popover .login-type-icon-weChat{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") no-repeat 50%;background-size:100% 100%}.login-card-popover .login-type-icon-dingDing{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") no-repeat 50%;background-size:100% 100%}.login-card-popover .login-type-icon-zdy{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") no-repeat 50%;background-size:100% 100%}", ""]);
// Exports
module.exports = exports;
