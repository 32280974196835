
import _ from "lodash";
import { onMounted, onBeforeUnmount, ref, computed, watch } from "vue";
import JsBridge from "./jsBridge";
import { useRouter, useRoute } from "vue-router";
import {
  registerH5EventList as registerElectronH5EventList,
  randomRouter,
  classCommunication,
  getIsOnlineState,
  registerH5Minimize,
  goToShortCut,
  EVENT_NAMES,
} from "@/electronService";
import { getCurrentActiveLesson, addLessonProcessData } from "@/services";
import { useStore } from "vuex";
import { MutationNames } from "@/store/index";
import { ElMessage } from "element-plus";
import logger from "@evideo/logger";
import moment from "moment";
import { dataEmbeddingPoint } from "@/services";
import {
  changeLocalResourcePath,
  localResourceHealth,
  changeServerEnv,
  localResourceHealthWithRetry,
  setResourceSynchronousTypes,
} from "@/services/localResource";
import { loadMicroApp, initGlobalState, MicroAppStateActions } from "qiankun";

import config from "@/config/app.config";
import servers from "@/config/servers";
import { eventCenter, event } from "@evideo/frontend-utils";
import clearCache from "@/components/cache/clearCache";
import { inject } from "vue";
import H5Tools from "@/components/h5Tools";
import { SocketEventMap } from "@/utils/socket-event.ts";
import LocalResourceExchange from "@evideo/local-resource-exchange";
import {
  localResourceConfig,
  localResourceExtraOptions,
} from "@/utils/localResourceParse";
import ClassroomInteraction from "@/components/classroomInteraction";
import { getKefuWechatQRCode } from "@/services";
import {
  setStatConfig,
  registerElectronListenStatReport,
} from "@/utils/statDataReport";
import PadControlPanel from "@/components/padControlPanel";
import { synchronousTypes } from "./hooks/useCache";

export default {
  name: "App",
  components: {
    clearCache,
    H5Tools,
    ClassroomInteraction,
    PadControlPanel,
  },
  setup() {
    // 启动h5工具app
    const toolsAppRootRef = ref();
    const socket = inject("socket");

    const store = useStore();
    const reconnectNum = ref(0);
    const canUseCacheFailedBtn = ref(true);

    const modalPadControlErrState = ref(false);

    // 课堂评价弹窗ref
    const classroomInteractionRef = ref();
    // 课堂互动的弹窗可见
    const classInteraction = ref(false);

    socket.on("connect", (res) => {
      reconnectNum.value = 0;
      console.log("#connection: ", res);
    });
    socket.on("connected", (res) => {
      console.log("#connected: ", res);
    });
    socket.on("disconnect", async (res) => {
      console.log("#disconnect: ", res);
      // 做一次健康检察
      try {
        await localResourceHealthWithRetry();
      } catch (err) {
        logger.error(err);
        if (reconnectNum.value > 5) {
          modalCacheErrState.value = true;
          store.commit(MutationNames.CHANGE_MODALCACHEERRSTATE, true);
          socket.close();
        }
      }
    });
    socket.on("connect_error", async (res) => {
      console.log("#connect_error: ", res);
      // 做一次健康检察
      try {
        await localResourceHealthWithRetry();
      } catch (err) {
        logger.error(err);
        if (reconnectNum.value > 5) {
          modalCacheErrState.value = true;
          store.commit(MutationNames.CHANGE_MODALCACHEERRSTATE, true);
          socket.close();
        }
      }
    });

    socket.on("TEST", (res) => {
      console.log("#message11: ", res);
    });

    // 内存是否已经满了
    socket.on(SocketEventMap.CACHE_FULL, (res) => {
      console.log("socket event cache full ===> ", res);
      cacheModalState.value = true;
    });

    // 服务是否可用
    socket.on(SocketEventMap.CAN_USE_SERVICE, async (res) => {
      console.log("can use service", res);
      const { ipcRenderer } = window.require("electron");
      const localEnv = ipcRenderer.sendSync("getLocalEnv");
      logger.info("缓存服务获取环境localEnv", localEnv);
      await changeServerEnv({ env: localEnv });

      const { data } = res;
      const { can_use } = data;
      if (!can_use) {
        ElMessage.info({
          message: "本地缓存文件迁移中，迁移过程中缓存功能不可用!",
        });
      } else {
        if (_.gte(store.state.version, "1.5.6")) {
          setResourceSynchronousTypes({ list: synchronousTypes });
        }
      }
      store.commit(MutationNames.CHANGE_LOCALRESOURCESTATE, can_use);
      watch(
        () => store.state.modalCacheErrState,
        (newVal) => {
          if (newVal === false) {
            modalCacheErrState.value = false;
          } else {
            modalCacheErrState.value = true;
          }
        }
      );
    });

    socket.on(SocketEventMap.CHANGE_PATH_RESULT, (res) => {
      console.log("#CHANGE_PATH_RESULT", res);
      const { data } = res;
      const { result } = data;
      if (result === "success") {
        ElMessage.info({
          message: "修改缓存路径成功,请重启软件后使用",
        });
      } else {
        ElMessage.error({
          message: "修改缓存路径失败,请重启软件重试",
        });
      }
    });

    logger.setNamespace("webview");
    // const { ipcRenderer } = window.require("electron");
    let minimizeBtnState = ref(false);
    // 缓存已满弹框
    let cacheModalState = ref(false);
    // 清理资源列表弹框
    let cacheModalClearCacheState = ref(false);
    // 缓存服务异常弹框
    let modalCacheErrState = ref(false);
    let isAndroid = ref(true);
    const device = navigator.userAgent;
    isAndroid.value =
      device.indexOf("Android") > -1 || device.indexOf("Adr") > -1;
    const router = useRouter();
    const route = useRoute();
    let textarea = ref("");
    const routerKey = computed(() => {
      return router.currentRoute.value.fullPath;
    });
    const padCtrlVisible = ref(false);
    const padCtrlRef = ref();

    watch(
      () => store.state.minimizeBtnState,
      (newVal) => {
        if (newVal === "false") {
          minimizeBtnState.value = false;
        } else {
          minimizeBtnState.value = true;
        }
      }
    );
    // 自动拍照截图定时器
    let timer;
    const {
      registerH5GetURL,
      registeLoadPpt,
      registerH5EventList,
      registerGetGoBackUrl,
    } = JsBridge();

    const checkLocalResourceServiceHealth = async () => {
      try {
        await localResourceHealthWithRetry();
        return true;
      } catch (e) {
        return false;
      }
    };

    // 开始socket连接
    const startSocketConnect = (port) => {
      let num = 0;
      const interval = setInterval(async () => {
        num++;
        console.log("缓存健康检查");
        const data = await checkLocalResourceServiceHealth();
        logger.info("health check ===> ", data);
        if (data) {
          window.clearInterval(interval);
          socket.io.uri = `${config["localResource"]}:${port}`;
          socket.connect();
          return;
        }
        console.log("chongshi", num);
        if (num > 60) {
          // 当重联60次之后清除定时器，并给出提示
          window.clearInterval(interval);
          ElMessage.info({
            message:
              "本地缓存服务启动失败，缓存功能暂不可用，如需使用请联系相关管理人员。",
          });
        }
      }, 5000);
    };

    // 获取本地缓存服务器端口号
    const getLocalResourcePortNum = () => {
      if (window.require) {
        const { ipcRenderer } = window.require("electron");
        ipcRenderer.sendToHost("getLocalResourcePortNum");
      }
    };

    const compareVersion = (v1, v2) => {
      // -1|v2大于v1；0|相同；1|v2小于v1
      v1 = v1.split(".");
      v2 = v2.split(".");
      const len = Math.max(v1.length, v2.length);

      while (v1.length < len) {
        v1.push("0");
      }
      while (v2.length < len) {
        v2.push("0");
      }

      for (let i = 0; i < len; i++) {
        const num1 = parseInt(v1[i]);
        const num2 = parseInt(v2[i]);

        if (num1 > num2) {
          return 1;
        } else if (num1 < num2) {
          return -1;
        }
      }

      return 0;
    };
    const clearTimer = (clearTimerValue) => {
      clearInterval(clearTimerValue);
      store.commit(MutationNames.CHANGE_SCREENSHOTTIMER, "");
    };
    const handleAddLessonProcessData = async (event, message) => {
      // 接收electron原生返回的信息
      logger.info("我接收到的electron发过来的信息picOssUrl", event, message);
      // 上传图片前判断是否下课
      logger.info("!store.state.lessonStart", !store.state.lessonStart);
      if (!store.state.lessonStart) {
        addLessonProcessData(
          {
            lesson_record_id: store.state.lessonRecordId,
            data_url: message,
            data_type: "image",
          },
          false
        ).then((data) => {
          logger.info(data);
        });
      }
    };

    /**
     * 设备控制服务socket连接异常时
     */
    const handlePadControlModuleError = () => {
      setTimeout(() => {
        padCtrlVisible.value = false;
        modalPadControlErrState.value = true;
        if (window && window.require && window.require("electron")) {
          const { ipcRenderer } = window.require("electron");
          ipcRenderer.sendToHost(
            EVENT_NAMES.UPDATE_PAD_CONTROL_STATUS,
            JSON.stringify({
              connect: false,
            })
          );
        }
      }, 5000);
    };

    const colseAppByPadControlErr = () => {
      const { ipcRenderer } = window.require("electron");
      logger.info("向electron发送信息设备控制服务异常关闭软件");
      ipcRenderer.sendToHost("colseApp");
    };

    const continueToUseByPadControlErr = () => {
      // 关闭异常提示弹框
      modalPadControlErrState.value = false;
      logger.info("设备控制服务异常后继续使用");
    };

    // 获取端口号重试次数
    let getPortNum = 0;
    if (window.require) {
      const { ipcRenderer } = window.require("electron");
      ipcRenderer.on("picOssUrl", handleAddLessonProcessData);
      ipcRenderer.on("goToCourseWareCenter", (event, message) => {
        // 接收electron原生返回的信息
        logger.info("我接收到的electron发过来的信息", event, message);
        router.push("/coursewareCenter");
      });
      ipcRenderer.on("goToCourseWare", (event, message) => {
        // 接收electron原生返回的信息
        logger.info("我接收到的electron发过来的信息", event, message);
        router.push("/courseWare");
      });
      ipcRenderer.on("minimizeModuleName", (event, message) => {
        // 接收electron原生返回的信息
        logger.info("我接收到的electron发过来的信息", event, message);
        store.commit(MutationNames.CHANGE_MINIMIZEMODULENAME, message);
      });
      ipcRenderer.on("getLocalResourcePortNumCallBack", (event, message) => {
        // 接收electron原生返回的信息
        logger.info(
          "我接收到的electron发过来的缓存服务端口号信息",
          event,
          message
        );
        if (message === undefined) {
          logger.info(
            "electron返回的端口号为undefined" + "重试次数" + getPortNum
          );
          setTimeout(async () => {
            getPortNum++;
            if (getPortNum > 6) {
              // 当重试6次之后清除定时器，并给出提示
              logger.info("已经重试6次，获取端口号失败");
              ElMessage.info({
                message:
                  "本地缓存服务启动失败，缓存功能暂不可用，如需使用请联系相关管理人员。",
              });
            } else {
              logger.info("准备再次发送端口请求，重试次数：" + getPortNum);
              getLocalResourcePortNum();
            }
          }, 5000);
        } else {
          // 端口号获取成功之后清除定时器，并给出提示
          logger.info(
            "electron返回的端口号为" + message + "重试次数" + getPortNum
          );
          store.commit(MutationNames.CHANGE_LOCALRESOURCEPORT, message);
          startSocketConnect(message);
        }
      });
      ipcRenderer.on("change-local-resource-path", async (event, message) => {
        console.log("收到回调 =====> ", message);
        if (message.length < 1 || message[0] == "") {
          ElMessage.error({
            message: "请选择正确的文件路径",
          });
          return;
        }
        const path = message[0];
        try {
          await changeLocalResourcePath({ path: path });
          ElMessage.info({
            message: "迁移数据操作需耗费一定时间，请耐心等待",
          });
          store.commit(MutationNames.CHANGE_LOCALRESOURCESTATE, false);
          btnCacheModalClose();

          if (
            route.path == "/" ||
            route.path == "/login" ||
            route.path == "/preLoad"
          ) {
            logger.info("缓存服务关闭弹窗返回login");
            router.push({
              path: "/login",
            });
          } else {
            logger.info("缓存服务关闭弹窗返回home");
            router.push("/home");
          }
        } catch (e) {
          console.error("失败了", e);

          ElMessage.error({
            message: e.message || "迁移失败，请重试",
          });
        }
      });
      ipcRenderer.on("goSubjectHome", () => {
        const subjectDataSelected = store.getters.getSelectSubject();
        if (subjectDataSelected.relation_teaching_system === "music") {
          router.push({
            path: "/home",
          });
        } else if (
          subjectDataSelected.relation_teaching_system === "fine_art"
        ) {
          router.push({
            path: "/artHome",
          });
        }
      });
      const startDeviceControlServiceConnect = (port) => {
        console.log("====> ", port);
        if (padCtrlRef.value && padCtrlRef.value.startConnect) {
          logger.info("开始连接设备控制服务的socket.io");
          padCtrlRef.value
            .startConnect(port)
            .then((res) => {
              console.log("连接服务, res ===> ", res);
              if (res === true) {
                logger.info("设备控制服务连接成功");
              } else {
                logger.info("设备控制服务连接失败");
              }

              if (
                new Set(store.state.registedEvents || []).has(
                  EVENT_NAMES.UPDATE_PAD_CONTROL_STATUS
                )
              ) {
                ipcRenderer.sendToHost(
                  EVENT_NAMES.UPDATE_PAD_CONTROL_STATUS,
                  JSON.stringify({
                    connect: res,
                  })
                );
              }
            })
            .catch((err) => {
              logger.error("设备控制服务连接失败", err);
            });
        }
      };

      // 设备控制服务状态信息
      ipcRenderer.on(
        "device-control-service-start-sesult",
        (event, message) => {
          console.log(
            "device-control-service-start-sesult",
            event,
            message.port
          );
          if (message && message.port !== undefined) {
            store.commit(
              MutationNames.CHANGE_DEVICE_CONTROL_PORT,
              message.port
            );
            startDeviceControlServiceConnect(message.port);
          } else {
            ElMessage.warning({
              message: "学习平板控制控制功能暂不可用，如需使用请重启软件重试。",
            });
            return;
          }
        }
      );

      // 有相同服务的时候处理
      ipcRenderer.on("device-control-service-has-same", () => {
        if (padCtrlRef.value && padCtrlRef.value.stopConnect) {
          padCtrlRef.value.stopConnect();
        }
      });

      ipcRenderer.on("show-pad-control", () => {
        if (classInteraction.value === true && padCtrlVisible.value === false) {
          classroomInteractionRef.value.closeModal();
        }
        padCtrlVisible.value = !padCtrlVisible.value;
      });
    }

    watch(
      () => classInteraction.value,
      (newVal) => {
        if (newVal === true && padCtrlVisible.value === true) {
          padCtrlVisible.value = false;
        }
      }
    );
    // if (window.require) {
    //   const { ipcRenderer } = window.require("electron");
    //   ipcRenderer.on("goToShortCut", (event, message) => {
    //     // 接收electron原生返回的信息
    //     goToShortCut(message);
    //   });
    //   // ipcRenderer.on("picOssUrl", handleAddLessonProcessData);
    // }
    // 快捷返回
    // const goToShortCut = (path) => {
    //   if (route.path == "/" || route.path == "/login") {
    //     ElMessage.warning({
    //       offset: 200,
    //       message: "请先登录!",
    //       type: "warning",
    //     });
    //   } else {
    //     if (path == "teachMaterial") {
    //       router.push({
    //         path,
    //         query: { teachMaterialType: "official" },
    //       });
    //     } else if (path == "characteristicClassroom") {
    //       router.push({
    //         path: "teachMaterial",
    //         query: { teachMaterialType: "characteristicClassroom" },
    //       });
    //     } else {
    //       router.push({
    //         path,
    //       });
    //     }
    //   }
    // };
    // 关闭缓存弹窗
    const btnCacheModalClose = () => {
      cacheModalState.value = false;
    };

    const btnTrialClass = () => {
      const { ipcRenderer } = window.require("electron");
      logger.info("向electron发送信息newVersion");
      ipcRenderer.sendToHost("openDialog", "localResource");
    };
    // 缓存服务异常关闭软件
    const btnColseApp = () => {
      const { ipcRenderer } = window.require("electron");
      logger.info("向electron发送信息缓存服务异常关闭软件");
      ipcRenderer.sendToHost("colseApp");
    };
    // 缓存服务异常返回首页
    const btnGoToHome = () => {
      // 关闭异常提示弹框
      modalCacheErrState.value = false;
      store.commit(MutationNames.CHANGE_MODALCACHEERRSTATE, false);
      // 缓存服务不可用
      store.commit(MutationNames.CHANGE_LOCALRESOURCESTATE, false);
      if (
        route.path == "/" ||
        route.path == "/login" ||
        route.path == "/preLoad"
      ) {
        logger.info("缓存服务异常返回登录页");
        router.push({
          path: "/login",
        });
      } else {
        logger.info("缓存服务异常返回首页");
        router.push({
          path: "/home",
        });
      }
    };
    const openFile = () => {
      document.getElementById("open").click();
    };

    const changeFile = (e) => {
      console.log("e", e);
      const fu = document.getElementById("open");
      console.log("fu", fu);
      if (fu == null) {
        return;
      }
      // textarea.value = fu.files[0].name;
      console.log(fu.files[0].name);
      console.log(fu.files);
      console.log(document.getElementById("open").value);
    };
    // 发送截图消息
    const sendStartCamera = () => {
      getCurrentActiveLesson({}).then((data) => {
        // 给electron发送消息前先判断是否在上课中
        logger.info(data);
        if (data.current_active_lesson !== null) {
          if (window.require) {
            const { ipcRenderer } = window.require("electron");
            logger.info(
              "向electron发送信息摄像头rtsp地址",
              store.state.monitorCameraIp
            );
            // start-camera 必须在版本大于等于1.3.0的版本
            const iscompare = compareVersion(store.state.version, "1.3.0");
            if (iscompare >= 0) {
              ipcRenderer.sendToHost(
                "start-camera",
                store.state.monitorCameraIp,
                store.state.lessonRecordId
              ); // 向原生发送token信息
            }
          }
        } else {
          logger.info("clearTimer");
          clearTimer(timer);
        }
      });
    };
    // 设置截图的轮询
    const setScreenshotTimer = () => {
      logger.info("setScreenshotTimer");
      if (store.state.screenshotTimer === "") {
        sendStartCamera();
        timer = setInterval(() => {
          sendStartCamera();
          // 10分钟一次
          // 方便测试改为1分钟发布的时候要调整回10分钟
        }, 1000 * 60 * 10);
      }

      store.commit(MutationNames.CHANGE_SCREENSHOTTIMER, timer);
    };
    // 清理本地资源
    const btnClearLocalFile = () => {
      cacheModalClearCacheState.value = true;
    };
    // 关闭资源列表弹框
    const btnCacheModalListClose = () => {
      cacheModalClearCacheState.value = false;
      if (
        route.path == "/" ||
        route.path == "/login" ||
        route.path == "/preLoad"
      ) {
        logger.info("缓存服务清除资源完成返回登录页");
        router.push({
          path: "/login",
        });
      } else {
        logger.info("缓存服务清除资源完成返回首页");
        router.push({
          path: "/home",
        });
      }
    };
    watch(
      () => store.state.lessonStart,
      (newVal) => {
        if (!newVal) {
          // 开始上课开始轮询
          setScreenshotTimer();
        } else {
          store.commit(MutationNames.CHANGE_SCREENSHOTTIMER, "");
        }
      }
    );

    const fetchWechatCode = () => {
      return new Promise((resolve, reject) => {
        getKefuWechatQRCode()
          .then((res) => {
            const qrcode_url = res.data.qrcode_url;
            store.commit(
              MutationNames.CHANGE_PCREQUESTEMPTYQRCODEURL,
              qrcode_url
            );
            resolve("");
          })
          .catch((err) => {
            logger.error("getKefuWechatQRCode error:", err);
            reject(err);
          });
      });
    };

    onMounted(async () => {
      fetchWechatCode();
      setStatConfig();
      registerElectronListenStatReport();
      socket.on("message", (data) => {
        console.log("ws服务端发送的信息", data);
      });
      logger.info("minimizeBtnState1", store.state.minimizeBtnState);
      if (store.state.minimizeBtnState === "false") {
        minimizeBtnState.value = false;
      } else {
        minimizeBtnState.value = true;
      }
      if (isAndroid.value) {
        const timer = setInterval(() => {
          if (window.WebViewJavascriptBridge !== undefined) {
            logger.info(
              "注册H5GetURL成功",
              document.readyState,
              window.WebViewJavascriptBridge,
              window
            );
            window.clearInterval(timer);
            registerH5GetURL();
            registeLoadPpt();
            registerH5EventList();
            registerGetGoBackUrl();
          }
        }, 500);
      } else {
        registerElectronH5EventList();
        randomRouter();
        classCommunication(classInteraction);
        getIsOnlineState();
        registerH5Minimize();
        goToShortCut();
        getLocalResourcePortNum();
      }

      // 本地缓存资源解析库初始化
      LocalResourceExchange.init(
        localResourceConfig,
        localResourceExtraOptions
      );
      localStorage.setItem("cacheDownloadNum", 0);
      // try {
      //   await window.initSheetLib();
      // } catch (e) {
      //   logger.warn("initSheetLib error", e.message);
      // }
    });
    // 数据埋点还原ppt
    const embeddingPointReduction = () => {
      const ca = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
      const data = {
        uid: store.state.pcUserIdAES,
        ct: "96",
        ci: store.state.mac,
        mac: store.state.mac,
        sv: store.state.version,
        fv: store.state.hardwareModel,
        ca: ca,
        pt: "pc",
        eventid: "ppt_reduction",
        pageid: "ppt_page",
        moduleid: "ppt",
        eventattr: "",
      };
      const body = encodeURIComponent(JSON.stringify(data));
      dataEmbeddingPoint(body);
    };
    const changeReduction = () => {
      minimizeBtnState.value = false;
      // 数据埋点还原ppt
      embeddingPointReduction();
      logger.info("minimizeBtnState", store.state.minimizeBtnState);
      logger.info("minimizeUrl", store.state.minimizeUrl);
      logger.info("minimizePPTInfo", store.state.minimizePPTInfo);
      const queryNum = store.state.minimizeUrl.indexOf("?") + 1;
      const queryTxt = store.state.minimizeUrl.substring(queryNum);
      logger.info("query", queryNum);
      logger.info("queryTxt", queryTxt);
      const queryArr = queryTxt.split("&");
      let query = {};
      queryArr.forEach((item) => {
        const itemArr = item.split("=");
        query[itemArr[0]] = decodeURI(itemArr[1]);
      });
      router.push({
        path: store.state.minimizeBtnState,
        query: query,
      });
    };
    // const tt = () => {
    //   cacheModalClearCacheState.value = true;
    // };

    const toggleClassInteraction = () => {
      classInteraction.value = !classInteraction.value;
    };
    const getStore = (key) => {
      const localStorageKey = ["groupPlanId"];
      if (localStorageKey.includes(key)) {
        store.state[key] = localStorage.getItem(key);
      }
      return store.state[key];
    };
    const setStore = (key, value) => {
      const localStorageKey = ["groupPlanId"];
      if (localStorageKey.includes(key)) {
        localStorage.setItem("groupPlanId", value);
      }
      store.state[key] = value;
      return store.state[key];
    };

    const getCurSubject = () => {
      const subjectData = JSON.parse(
        localStorage.getItem("subjectDataSelected")
      );
      if (subjectData) {
        return subjectData.subject;
      }
      return undefined;
    };

    return {
      // tt,
      routerKey,
      changeReduction,
      minimizeBtnState,
      cacheModalState,
      btnCacheModalClose,
      openFile,
      changeFile,
      textarea,
      cacheModalClearCacheState,
      btnClearLocalFile,
      btnCacheModalListClose,
      btnTrialClass,
      btnColseApp,
      modalCacheErrState,
      btnGoToHome,
      classInteraction,
      toggleClassInteraction,
      getStore,
      setStore,
      canUseCacheFailedBtn,
      getCurSubject,
      padCtrlVisible,
      padCtrlRef,
      modalPadControlErrState,
      handlePadControlModuleError,
      colseAppByPadControlErr,
      continueToUseByPadControlErr,
      classroomInteractionRef,
    };
  },
};
