export enum DeviceControlEvent {
  CONNECT = "connect",
  DISCONNECT = "disconnect",
  ERROR = "connect_error",
  UPLOAD_DEVICE_INFO = "S_UPLOAD_DEVICE_INFO",
}

export type DeviceListItem = {
  id: string;
  name: string;
  enabled: boolean;
  online: boolean;
};

export type DeviceInfosItem = {
  device_type: string;
  device_info: {
    name: string;
    enabled: boolean;
    online: boolean;
  };
  device_id: string;
};

export interface VocalBalance {
  vocal_delay: number; // 人声对齐延迟
  acc_volume: number; // 伴奏音量
  vocal_volume: number; // 人声音量
  device_self_setup: boolean; // 设备是否可自己调节
}

export const DEFAULT_VOCAL_BALANCE: VocalBalance = {
  vocal_delay: 100,
  acc_volume: 1,
  vocal_volume: 1,
  device_self_setup: false,
};
