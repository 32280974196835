// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/img/login/reload.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".dingtalk-login-container[data-v-a9b98bb6]{width:100%;height:100%;background-color:#fff}.login-qrcode[data-v-a9b98bb6]{text-align:center;width:50%;height:250px;margin:0 auto}.body[data-v-a9b98bb6]{width:100%;height:59vh;padding:24px}.qrcodeLoading[data-v-a9b98bb6]{height:250px;width:100%}.dingtalk-loading[data-v-a9b98bb6]{height:250px;width:250px;flex-direction:column}.dingtalk-loading[data-v-a9b98bb6],.dingtalk-loading-icon-container[data-v-a9b98bb6]{display:flex;justify-content:center;align-items:center}.dingtalk-loading-icon-container[data-v-a9b98bb6]{width:90px;height:90px;border-radius:90px;border:10px solid #f5d9d9;background-color:#f5d9d9;transform:rotateY(180deg)}.dingtalk-loading-icon[data-v-a9b98bb6]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:100% 100%;width:70px;height:70px;display:block;-webkit-animation:goRotate-a9b98bb6 1s linear infinite;animation:goRotate-a9b98bb6 1s linear infinite}.dingtalk-loading-text[data-v-a9b98bb6]{text-align:center;font-weight:700;color:#b77b35;font-size:22px;margin-top:8px}@-webkit-keyframes goRotate-a9b98bb6{0%{transform:rotate(1turn)}to{transform:rotate(0deg)}}@keyframes goRotate-a9b98bb6{0%{transform:rotate(1turn)}to{transform:rotate(0deg)}}.dingtalk-error[data-v-a9b98bb6]{width:250px;height:250px;display:flex;flex-direction:column;justify-content:center;align-items:center}.dingtalk-error img[data-v-a9b98bb6]{width:90px;border-radius:100%;border:10px solid #f5d9d9;background-color:#f5d9d9;margin-bottom:10px;cursor:pointer}.dingtalk-error div[data-v-a9b98bb6]{text-align:center;font-weight:700;color:#b77b35;font-size:20px;margin-top:5px}", ""]);
// Exports
module.exports = exports;
