
import { useStore } from "vuex";
import {
  computed,
  defineComponent,
  onBeforeMount,
  onMounted,
  ref,
  provide,
} from "vue";
import logger from "@evideo/logger";
import _ from "lodash";
import { MutationNames } from "@/store";
import useLogin from "@/hooks/useLoginNextNext";
import { ElMessage } from "element-plus";
import SettingDialog from "./components/SettingDialog.vue";
import LoginCard from "./loginCard.vue";
import SoftwareControlBar from "@/components/softwareControlBar.vue";
import { loginTypeNameMap, LoginTypes } from "./dataMap";

export default defineComponent({
  components: { SoftwareControlBar, SettingDialog, LoginCard },
  setup() {
    const store = useStore();
    const canRenderLoginCard = ref(false);
    const classLoginBackground = ref("login-bg");
    const schoolLogo = ref("");

    const isML = ref(false);
    const version = ref("");
    const envState = ref("");
    const diaryLevel = ref("");
    const hardwareModel = ref("");

    provide("envState", envState);
    provide("diaryLevel", diaryLevel);

    const lastLoginType = ref("");

    const { getMac, loginOut } = useLogin();

    const dvIcePointState = ref(false);
    const macInfo = computed(() => store.state.mac);

    // 设置保存本地文件路径
    const btnOpenDialog = () => {
      if (!dvIcePointState.value) {
        ElMessage({
          offset: 200,
          message: "该操作为高风险操作，请谨慎使用",
          center: true,
        });
      }
      dvIcePointState.value = !dvIcePointState.value;
    };
    // 复制mac到黏贴板
    const copyMac = () => {
      let macTxt = document.getElementById("copyMac")!.innerText;
      const macInput = document.createElement("input");
      document.body.appendChild(macInput);
      macInput.setAttribute("value", macTxt);
      macInput.select();
      if (document.execCommand("copy")) {
        document.execCommand("copy");
        logger.info("复制成功");
      }
      document.body.removeChild(macInput);
    };

    const getLocalEnv = (data: any) => {
      logger.info("getLocalEnv", data);
      const { ipcRenderer } = window.require("electron");
      const isCompare = _.gte(data, "1.2.1");
      logger.info("getLocalEnv >= 1.2.1 isCompare", isCompare);
      if (isCompare) {
        const localEnv = ipcRenderer.sendSync("getLocalEnv");
        logger.info("localEnv", localEnv);
        envState.value = localEnv;
        logger.info("envState.value", envState.value);
      }
    };

    const getLocalDiaryType = (data: any) => {
      const { ipcRenderer } = window.require("electron");
      const isCompare = _.gte(data, "1.3.4");
      if (isCompare) {
        logger.info("sendgetLocalDiaryType");
        const localDiaryType = ipcRenderer.sendSync("getLocalDiaryType");
        logger.info("localDiaryType", localDiaryType);
        diaryLevel.value = localDiaryType;
        logger.info("webview拿到的本地文件里的日志类型是===>", localDiaryType);
      }
    };

    const getVersion = () => {
      const { ipcRenderer } = window.require("electron");
      ipcRenderer.once("version", async (event: any, message: any) => {
        // 接收electron原生返回的信息
        logger.info("我接收到的electron发过来的信息version", event, message);
        version.value = message;
        (window as any).h5Version = message;
        store.commit(MutationNames.CHANGE_VERSION, message);
        getMac();
        await getLocalEnv(message);
        await getLocalDiaryType(message);
      });
    };

    const getHardwareModel = () => {
      const { ipcRenderer } = window.require("electron");
      ipcRenderer.once("hardwareModel", (event: any, message: any) => {
        // 接收electron原生返回的信息
        logger.info(
          "我接收到的electron发过来的信息hardwareModel",
          event,
          message
        );
        hardwareModel.value = message;
        store.commit(MutationNames.CHANGE_HARDWAREMODEL, message);
        (window as any).os = hardwareModel.value;
      });
    };
    const getSelClassInfo = () => {
      const { ipcRenderer } = window.require("electron");
      ipcRenderer.once("elSelClassInfo", (event: any, message: any) => {
        // 接收electron原生返回的信息
        logger.info(
          "我接收到的electron发过来的信息SelClassInfo",
          event,
          message
        );
        localStorage.setItem("selGrade", message.selGrade);
        localStorage.setItem("selClass", message.selClass);
      });
    };
    // 劢联定制版的样式及需求
    const mlCustom = () => {
      if (isML.value) {
        classLoginBackground.value = "dvMLBJ";
      }
    };

    const getLocalSchoolLogo = (loginType: string) => {
      const lastDingSchool = JSON.parse(
        localStorage.getItem("lastDingSchool") || "{}"
      );
      const lastSelectedSchool = JSON.parse(
        localStorage.getItem("selectedSchool") || "{}"
      );
      console.log(
        "lastSelectedSchool lastDingSchool getLocal",
        JSON.stringify(lastSelectedSchool),
        JSON.stringify(lastDingSchool)
      );
      if (loginType !== "dingDing" && loginType !== "dingtalk") {
        // 登录方式不为钉钉时，拿取其他方式上次登录学校记忆
        schoolLogo.value = lastSelectedSchool?.schoolLogo || "";
      } else {
        // 登录方式为钉钉登录时，拿取记忆的钉钉登录学校
        schoolLogo.value = lastDingSchool?.school_logo || "";
      }
    };

    const getLocalLoginType = () => {
      let loginType = JSON.parse(
        localStorage.getItem("selectedLoginType") || "{}"
      );
      // localStorage存放实际执行过登录的登录记忆；store中存放在登录流程中的记忆
      if (store.state.loginType) {
        let _loginType = loginTypeNameMap?.[store.state.loginType]
          ? loginTypeNameMap[store.state.loginType]
          : store.state.loginType;
        loginType = LoginTypes.find((type) => type.type === _loginType);

        // 若选择钉钉登录，进入选择钉钉学校后，不选择学校返回登录页面，则显示上次登录方式
        if (loginType.type === "dingDing") {
          const lastDingSchool = JSON.parse(
            localStorage.getItem("lastDingSchool") || "{}"
          );
          if (!lastDingSchool?.school_id) {
            loginType = JSON.parse(
              localStorage.getItem("selectedLoginType") || "{}"
            );
          }
        }
      }

      console.log("loginType getLocal", JSON.stringify(loginType));
      store.commit(MutationNames.CHANGE_LOGINTYPE, loginType?.type);

      if (loginType && loginType?.type) {
        lastLoginType.value = loginType.type;
      }
      getLocalSchoolLogo(lastLoginType.value);
    };

    onBeforeMount(async () => {
      canRenderLoginCard.value = false;
      isML.value = store.state.isML;
      logger.info("isML", store.state.isML);
      if (typeof window.require === "function") {
        await getVersion();
        await getHardwareModel();
        await getSelClassInfo();
      }
      getLocalLoginType();
      canRenderLoginCard.value = true;
    });

    onMounted(() => {
      loginOut();
      if (isML.value) {
        // 劢联定制版的样式及需求
        mlCustom();
      }
    });
    return {
      canRenderLoginCard,
      schoolLogo,
      isML,
      macInfo,
      classLoginBackground,
      btnOpenDialog,
      copyMac,
      getLocalSchoolLogo,
      version,
      dvIcePointState,
      lastLoginType,
    };
  },
});
